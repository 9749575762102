import http from "@/api/http";

export async function items() {
    let response = await http.get('/showcases');
    return response.data.showcases
}

export async function item(id) {
    let response = await http.get(`/showcases/${id}`);
    return response.data.user
}

export async function store(data) {
    let response = await http.post('/showcases/store', data);
    return response.data.user
}

export async function update(obj) {
    let response = await http.put(`/showcases/${obj.id}/update`, obj.user);
    return response.data.user
}

export async function actualize() {
    let response = await http.post(`/showcases/actualize`);
    return response.data
}

export async function remove(id) {
    let response = await http.delete(`/showcases/${id}/delete`);
    return response.data.user_id
}

actualize