<template>
    <table class="table">
        <thead>
        <tr>
          <th class="col-1">id</th>
          <th class="col-4">Дополнительное поле</th>
          <th class="col-2">Артикул</th>
          <th class="col-2">Цена</th>
        </tr>
        </thead>
        <tbody>
          <showcase-modification-row-table-component  :id="modification.id"
                                                      :name="modification.name"
                                                      :vendor="modification.code"
                                                      :price="modification.price"
                                                      :product-id="modification.product_id"
          ></showcase-modification-row-table-component>

        </tbody>
    </table>
</template>

<script>
import ShowcaseModificationRowTableComponent from "./ShowcaseModificationRowTableComponent";
export default {
    components: {ShowcaseModificationRowTableComponent},
    props: ['modification'],
}
</script>

<style scoped>

</style>
