<template>
<div>
  <div v-if="!vertical" style="width: 7.75rem;position: relative;">
    <div class="stars-horizontal">
      <div class="star star-border horizontal" v-for="n in 5"></div>
    </div>
    <div class="filled" :style="{ 'width': (100 / 5) * rating + '%' }">
      <div class="stars-horizontal">
        <div class="star star-solid horizontal" v-for="n in 5"></div>
      </div>
    </div>
  </div>

  <div v-else style="height: 7.75rem;position: relative;transform: rotateX(180deg);">
    <div class="stars-vertical">
      <div class="star star-border vertical" v-for="n in 5"></div>
    </div>
    <div class="filled" :style="{ 'height': (100 / 5) * rating + '%' }">
      <div class="stars-vertical">
        <div class="star star-solid vertical" v-for="n in 5" ></div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  props: ['rating', 'vertical']
}
</script>

<style scoped>

.stars-horizontal{
  width: 7.75rem;
  display:flex;
  justify-content: space-between;
}
.stars-vertical{
  height: 7.75rem;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
}
.star-border{
  background-image: url(/assets/img/rating/star-solid-grey.svg);
}
.star-solid{
  background-image: url(/assets/img/rating/star-solid.svg);
}
.star{
  height: 1.25rem;
  width: 1.25rem;
  background-repeat: no-repeat;
  background-size: cover;
}
.filled{
  overflow: hidden;
  position:absolute;
  top: 0;
  left: 0;
}
.horizontal{
}
.vertical{
  transform: rotateX(180deg);
}
@media (max-width: 991px) {

}
</style>
