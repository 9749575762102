<template>
  <img :src="imagesUrl + '/storage/' + file" alt="" height="88" width="88" :class="c" style="margin-right: 4px;">
  <div style="display: flex; flex-direction: column;justify-content: space-between;">
    <button @click="changeMainImage" type="button" class="icon btn-main"></button>
    <button @click="removeImage" type="button" class="icon delete-btn"></button>
    <button @click="setSample" type="button" class="icon btn-sample"></button>
  </div>
</template>

<script>
export default {
  props: ['id', 'file', 'productId', 'modificationId', 'mainImageId', 'sampleImageId'],
  computed: {
    imagesUrl(){
      return this.$store.getters['imagesUrl']
    },
    c(){
      return {
        'main': Number(this.mainImageId) === this.id,
        'sample': Number(this.sampleImageId) === this.id,
  }
    },
  },
  methods: {
    removeImage(){
      this.$store.dispatch('products/removeModificationImage', {product_id: this.productId, modification_id: this.modificationId, image_id: this.id})
    },
    changeMainImage(){
      this.$store.dispatch('products/changeMainImageModification', {product_id: this.productId, modification_id: this.modificationId, image_id: this.id})
    },
    setSample(){
      this.$store.dispatch('products/setSample', {product_id: this.productId, modification_id: this.modificationId, image_id: this.id})
    },
  }
}
</script>

<style scoped>
.main{
  border: 2px solid green;
}
.sample{
  border: 2px solid #df6aaa;
}
.icon{
  height: 24px;
  width: 24px;
  mask-size: cover;
  background-color: #718096;
}
.btn-main{
  mask-image: url(../assets/icon/home_FILL0_wght400_GRAD0_opsz48.svg);
}
.delete-btn{
  mask-image: url(../assets/icon/delete_FILL0_wght400_GRAD0_opsz48.svg);
}
.btn-sample{
  mask-image: url(../assets/icon/visibility_FILL0_wght400_GRAD0_opsz48.svg);
}
</style>