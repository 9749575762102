import http from "@/api/http";

export async function user() {
    return  await http.get('/user');
}

export async function lOgin() {
    let response = await http.get('/login');
    return response.data
}

export async function logout() {
    let response = await http.post(`/logout`);
    return response.data
}