<template>
<!--  <loader-component v-if="loaded"></loader-component>-->

  <div>
    <div class="row">
        <h3 style="font-size: 28px;">Витрина</h3>
    </div>
    <div class="row">
      <showcase-component :id="showcase.id"
                          :name="showcase.name"
                          :active="showcase.active"
      ></showcase-component>
    </div>

      <div class="mt-5">
        <h2 class="">Добавление товара в витрину</h2>

        <form class="" action="" method="post">

          <div class="col-3 mt-3 mb-3" style="display:flex;align-items: center;">
            <label for="isMod" style="margin-right: 6px;">Является модификацией</label>
            <input v-model="is_modification" id="isMod" type="checkbox" name="sku" style="width: 22px;height: 22px;">
          </div>

          <div class="col-3 mt-3 mb-3">
            <input v-model="vendor" type="text" class="form-control" id="inlineFormInputGroupManufacturerName" placeholder="Артикул">
          </div>

          <div class="col-2">
            <button @click="addInShowcase" type="button" class="btn btn-primary">Добавить</button>
          </div>
        </form>
      </div>

      <div class="row mt-5">
        <div class="col-10">
          <showcase-products-table-component :items="items"></showcase-products-table-component>
        </div>
      </div>
</div>
</template>

<script>
import ShowcaseComponent from "../../components/showcase/ShowcaseComponent";
import ShowcaseProductsTableComponent from "../../components/showcase/ShowcaseProductsTableComponent";
import LoaderComponent from "../../components/LoaderComponent";
export default {
    components: {LoaderComponent, ShowcaseProductsTableComponent, ShowcaseComponent},
    data(){
        return{
            is_modification: false,
            vendor: ''
        }
    },
    computed: {
      loaded(){
        return this.$store.getters.loaded
      },
      showcase(){
          return this.$store.getters['showcase/showcase'];
      },
      items(){
          return this.$store.getters['showcase/items'];
      }
    },
    methods: {
        addInShowcase() {
          if (this.vendor !== '') {
            let obj = {
              showcase_id: this.showcase.id,
              is_modification: this.is_modification,
              vendor: this.vendor
            };
            this.$store.dispatch('showcase/addInShowcase', obj)
            this.vendor = '';
            this.is_modification = false
          }
        }
    },
    async created() {
        await this.$store.dispatch('showcase/showcase', {showcase_id: this.$route.params.id})
    }
}
</script>

<style scoped>
h2{
    font-size: 20px;
}
</style>
