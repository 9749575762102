<template>
    <div class="col-4">
      <div>
        <table class="table caption-top table-bordered">
          <caption>Скидка</caption>
          <tbody>
          <tr>
            <th>id</th>
            <td>{{ id }}</td>
          </tr>
          <tr>
            <th>Наименование</th>
            <td>{{ name }}</td>
          </tr>
          <tr>
            <th>Тип</th>
            <td>{{ type }}</td>
          </tr>
          <tr>
            <th>Вид</th>
            <td>{{ subType }}</td>
          </tr>
          <tr>
            <th>Действует с</th>
            <td>{{ fromDate }}</td>
          </tr>
          <tr>
            <th>Действует</th>
            <td>{{ toDate }}</td>
          </tr>
          <tr>
            <th>Активна</th>
            <td>{{ active ? 'Да' : 'Нет' }}</td>
          </tr>
          <tr>
            <th>Опции</th>
            <td>

                <div v-if="subType === 'personal'" style="">
                  <p>Название: {{ options.name }}</p>
                  <div v-for="item in options.value" style="display:flex;justify-content: space-between;font-size: 14px;">
                    <p>{{ new Intl.NumberFormat('ru-RU').format(item.limit) }}</p>
                    <p>{{ item.percent }}%</p>
                  </div>
                </div>
              <div v-else>
                <p>Название: {{ options.name }}</p>
                <p>{{ type === 'fixed' ? 'Сумма' : 'Процент' }}: {{ options.value }}</p>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
        <div style="display:flex;">
          <button @click="activeToggle" class="btn btn-success" :class="{'btn-dark': active }" style="margin-right: 16px;" type="button">{{ active ? 'Деактивировать' : 'Активировать'}}</button>
          <router-link :to="{name: 'discountEdit', params: {id: this.$route.params.id}}" class="btn btn-secondary">
            <a>Редактировать</a>
          </router-link>
          <button @click="remove" class="btn btn-danger" style="margin-left: 16px;" type="button">Удалить</button>
        </div>
      </div>
        

    </div>
</template>

<script>
export default {
  props: ['id','name','type','subType', 'fromDate','toDate','active', 'options'],
  methods:{
      async remove(){
        await this.$store.dispatch('discount/remove', {discount_id: this.id});
        await this.$router.push({name: 'discounts'})
      },
    async activeToggle(){
      await this.$store.dispatch('discount/activeToggle', {discount_id: this.id});
    }
  }
}
</script>

<style scoped>

</style>
