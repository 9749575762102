import http from "@/api/http";

export async function items(filtration) {
    let response = await http.get('/products', {params: filtration});
    return response.data.products
}

export async function itemsYellow() {
    let response = await http.get('/products/yellow');
    return response.data.products
}

export async function item(id) {
    let response = await http.get(`/products/${id}`);
    return response.data
}

export async function store(data) {
    let response = await http.post('/products/store', data);
    return response.data
}

export async function update(id, data) {
    let response = await http.put(`/products/${id}/update`, data);
    return response.data
}
export async function remove(id){
    await http.delete(`/products/${id}/remove`);
}

export async function storeImage(id, data) {
    let response = await http.post(`/products/${id}/images/store`, data);
    return response.data.product
}

export async function destroyImage(id, data) {
    let response = await http.delete(`/products/${id}/images/destroy`, {data});
    return response.data.product
}

export async function modifications(product_id) {
    let response = await http.get(`/products/${product_id}/modifications`);
    return response.data.modifications
}

export async function modification(product_id, modification_id) {
    let response = await http.get(`/products/${product_id}/modifications/${modification_id}`);
    return response.data.modification
}

export async function storeModification(productId, data) {
    let response = await http.post(`/products/${productId}/modifications/store`, data);
    return response.data.modification
}

export async function updateModification(product_id, modification_id, data) {
    let response = await http.put(`/products/${product_id}/modifications/${modification_id}/update`, data);
    return response.data.modification
}

export async function removeModification(product_id, modification_id) {
    let response = await http.delete(`/products/${product_id}/modifications/${modification_id}/remove`);
    //return response.data.modification
}

export async function storeModificationImage(id, data) {
    let response = await http.post(`/products/${id}/images/store`, data);
    return response.data.product
}

export async function destroyModificationImage(id, data) {
    let response = await http.delete(`/products/${id}/images/destroy`, {data});
    return response.data.product
}

export async function offers() {
    let response = await http.get('/products/offers');
    return response.data.offers
}

export async function generateFeed() {
    let response = await http.get('/products/feed/generate');
    //return response.data.offers
}

export async function convert(data) {
    let response = await http.post('/products/images/convert', data);
    return response.data
}

export async function example(data) {
    let response = await http.post('/products/images/example', data);
    return response.data
}

export async function storePurchasable(id, data) {
    let response = await http.post(`/products/${id}/purchasable/store`, data);
    return response.data
}

export async function removePurchasable(id, data) {
    let response = await http.delete(`/products/${id}/purchasable/remove`, {data});
    return response.data
}

export async function up(id, data) {
    let response = await http.post(`/products/${id}/purchasable/move/up`, data);
    return response.data
}
export async function down(id, data) {
    let response = await http.post(`/products/${id}/purchasable/move/down`, data);
    return response.data
}
