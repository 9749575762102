<template>
  <div  class="" style="display:flex;background-color: rgba(0, 0, 0, 0.4);width: 100%;height: 100vh;position:fixed;top: 0;left: 0;justify-content: center;align-items: center">
    <div style="background-color: #fff;padding: 20px;">
      <button  @click="close" type="button" class="btn btn-secondary" >Закрыть</button>

      <div v-if="error" style="background-color: red;margin-top: 8px;border-radius: 5px">
        <p style="color:#fff;font-size: 14px;padding: 8px;margin-bottom: 0;">Произошла ошибка!</p>
      </div>

      <form action="" method="post" enctype="multipart/form-data">
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label">Тема сообщения</label>
          <input v-model="mail.subject" type="text" class="form-control" id="exampleFormControlInput1" placeholder="">
        </div>
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label">Текст сообщения</label>
          <textarea v-model="mail.text" class="form-control" placeholder=""></textarea>
        </div>
        <upload-file-component @images="setImages"></upload-file-component>

        <div class="col">
          <button @click="send" type="button" class="btn btn-primary">
            <span v-if="!spinner">Отправить</span>
            <spinner-component v-else></spinner-component>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import * as mailingApi from "@/api/mailing";
import SpinnerComponent from "./SpinnerComponent";
import UploadFileComponent from "../components/common/UploadFileComponent";
export default {
  props: ['email'],
  components: {UploadFileComponent, SpinnerComponent},
  data(){
    return {
      error: false,
      spinner: false,
      mail: {
        subject: '',
        text: '',
        email: '',
        images: []
      },
    }
  },
  methods: {
    setImages(images){
      this.mail.images = images
    },
    close(){
      this.mail.text = '';
      this.mail.subject = '';
      this.$emit('close-new-mail')
    },

    async send(){
      this.spinner = true;
      let form = new FormData();
      form.append('subject', this.mail.subject)
      form.append('text', this.mail.text)
      form.append('email', this.mail.email)
      this.mail.images.forEach(image => {
        form.append('file[]', image)
      })
      mailingApi.sendMail(form).then(response => {
        if (response.success){
          this.mail.text = '';
          this.mail.subject = '';
          this.mail.images = [];
          this.$emit('close-new-mail')
        }
      }).finally(() => {
        this.spinner = false;
        this.$store.commit('loaded', false, {root: true})
      })
    },
  },
  created() {
    this.mail.email = this.email
  }

}
</script>

<style scoped>

</style>