<template>
  <div class="container-fluid">
    <login></login>
  </div>
</template>

<script>
import Login from "../views/Login";
export default {
  components: {Login}
}
</script>

<style scoped>

</style>