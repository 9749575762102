<template>
    <div class="row">
        <h1>Витрины</h1>
    </div>
  <div v-if="showcase === undefined" style="width: 100%;height: 100vh;display:flex;justify-content: center;align-items: center;font-size: 22px;">Loading...</div>

  <form v-else class="" action="" method="post">
        <h6 class="">Редактирование витрины</h6>

<!--        <div v-if="error !== undefined" style="background-color: red;padding: 10px;">-->
<!--            <p style="color:#fff;font-size: 15px;">{{ error }}</p>-->
<!--        </div>-->

        <div class="col-3 mt-3 mb-3">
            <input v-model="name" type="text" name="name" class="form-control" id="inlineFormInputGroupManufacturerName" placeholder="Наименование">
        </div>

        <div class="col-3 mt-3 mb-3">
            <select class="form-select" aria-label="Default select example" v-model="active">
                <option value="1">Да</option>
                <option value="0">Нет </option>
            </select>
        </div>


        <div class="col-2">
            <button @click="update" type="button" class="btn btn-primary">Изменить</button>
        </div>
    </form>
</template>

<script>
export default {
    data(){
        return{
            name: '',
            active: '',
            error: undefined,
        }
    },

    computed:{
      showcase(){
        return this.$store.getters['showcase/showcase'];
      }
    },
    methods: {
      async update(){
        let obj = {
          showcase_id: this.showcase.id,
          name: this.name,
          active: this.active
        }
        await this.$store.dispatch('showcase/update', obj);
        await this.$router.push({name: 'showcase', params: {id: this.showcase.id}})
      }
    },

    async created() {
      await this.$store.dispatch('showcase/showcase', {showcase_id: this.$route.params.id})
        this.name =  this.showcase.name;
        this.active =  this.showcase.active;
    }
}
</script>

<style scoped>

</style>
