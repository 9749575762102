<template>
  <tr>
    <td>{{ productName }}</td>
    <th>{{ vendor }}</th>
    <th>
      <div style="display:flex;align-items: center">
        <div>
          <span v-if="discount > 0" class="me-3" style="font-weight: normal;text-decoration: line-through; color: #718096">{{ price }}</span>
          <span>{{ price - discount }}</span>
        </div>
        <button v-if="1" type="button" class="icon btn-edit ms-3" data-bs-toggle="modal" :data-bs-target="'#' + idPopup"></button>
      </div>
    </th>
<!--    <th>{{ discount }}</th>-->
    <th>
      <input-quantity-component :size="1.8" :current-quantity="quantity" @quantity="updateQuantity"></input-quantity-component>
    </th>
    <th>
      <div>
        <span v-if="discount > 0" class="me-3" style="font-weight: normal;text-decoration: line-through; color: #718096">{{ price * quantity }}</span>
        <span >{{ (price - discount) * quantity }}</span>
      </div>
    </th>
    <th>
      <button  @click="destroy" type="button" class="btn btn-danger">Удалить</button>
    </th>
  </tr>

  <overlay-component :title="'Рассчитать скидку позиции'" :id="idPopup">
      <discount-calculation-component :id="idPopup"  @apply="changePrice" :value="price"></discount-calculation-component>
  </overlay-component>

</template>

<script>
import InputQuantityComponent from "../../InputQuantityComponent";
import OverlayComponent from "../../OverlayComponent";
import DiscountCalculationComponent from "../../DiscountCalculationComponent";
export default {
  components: {DiscountCalculationComponent, OverlayComponent, InputQuantityComponent},
  props: ['productName', 'vendor', 'price', 'discount', 'quantity', 'id', 'orderId'],
  emits: ['apply'],
  data(){
    return {
      orderItem: {
        price: undefined,
        discount: undefined,
      },
    }
  },
  computed: {
    idPopup(){
      return 'calcOrderItem' + this.id
    }
  },
  methods: {
    destroy(){
      this.$store.dispatch('orders/destroyOrderItem', {id: this.id, order_id: this.orderId})
    },
    async updateQuantity(quantity){
      await this.$store.dispatch('orders/updateQuantity', {id: this.id, order_id: this.orderId, quantity: quantity})
    },
    async changePrice(obj){
      await this.$store.dispatch('orders/updatePriceOrderItem', {order_id: this.orderId, price: obj.newValue, id: this.id, is_self_price: obj.hasOwnProperty('type')})
    }
  },
  created() {
    // this.orderItem.price = this.price
    // this.orderItem.discount = this.discount
  }
}
</script>

<style scoped>
.icon{
  height: 20px;
  width: 20px;
  mask-size: contain;
  background-color: #718096;
}
.btn-edit{
  mask-image: url(../../../assets/icon/edit_FILL0_wght400_GRAD0_opsz48.svg);
}
</style>