<template>
  <loader-component v-if="loaded"></loader-component>
    <div v-else class="col">
        <h1>Seo</h1>
        <div class="col-auto mt-3">
            <router-link :to="{name: 'seoCreate'}">
                <a href="" class="btn btn-primary">Создать seo</a>
            </router-link>
        </div>

        <div class="row">
            <div class="">
              <table class="table">
                <thead>
                  <tr>
                    <th>№</th>
                    <th>id</th>
                    <th class="col-1">Уровень</th>
                    <th class="col-1">Категория</th>
                    <th class="">Бренд</th>
                    <th class="col-2">Заголовок</th>
                    <th class="col-4">Описание</th>
                    <th class="col-4">Ключевые слова</th>
                    <th class="">H1</th>
                    <th class="">Обновлено</th>
                    <th class="">С брендом</th>

                  </tr>
                </thead>
                <tbody>
                  <seo-row-table-component v-for="(item, i) in items"
                                           :n="i + 1"
                                           :id="item.id"
                                           :category="item.name ?? 'Пусто'"
                                           :brand="item.brand_name ?? 'Пусто'"
                                           :with-brand="item.for_brand"
                                           :title="item.title"
                                           :description="item.description"
                                           :keywords="item.keywords"
                                           :h1="item.h1"
                                           :updated-at="item.updated_at"
                                           :level="item.level"
                  ></seo-row-table-component>
                </tbody>
              </table>
            </div>
        </div>
    </div>
</template>

<script>
import SeoRowTableComponent from "@/components/admin/seo/SeoRowTable Component";
import LoaderComponent from "@/components/LoaderComponent";
export default {
    components: {LoaderComponent, SeoRowTableComponent},
    computed:{
        items(){
            return this.$store.getters['seo/items']
        },
        loaded(){
          return this.$store.getters.loaded
        },
    },
    async created() {
        await this.$store.dispatch('seo/items')
    }
}
</script>

<style scoped>

</style>
