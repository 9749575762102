<template>
  <loader-component v-show="loaded"></loader-component>
  <div class="col">
      <h1>Создание модификации для товара</h1>
      <hr>
      <div class="">
        <create-modification-component
            :product-id="product.id"
            :props="product.properties"
        ></create-modification-component>
      </div>
  </div>
</template>

<script>
import CreateModificationComponent from "../../../components/product/modification/CreateModificationComponent";
import LoaderComponent from "../../../components/LoaderComponent";
export default {
  components: {LoaderComponent, CreateModificationComponent},
  computed:{
    loaded(){
      return this.$store.getters.loaded
    },
    product(){
      return this.$store.getters['products/item']
    },
  },
  async created(){
    await this.$store.dispatch('products/item', {product_id: this.$route.params.product_id});
  }
}
</script>

<style scoped>

</style>
