<template>
  <tr>
    <td>
      <router-link :to="route">{{ name }}</router-link>
    </td>
    <th>{{ vendor }}</th>
    <th>
      <div>
        <span v-if="discount > 0"  style="color:grey;margin-right: 1rem;text-decoration: line-through;font-weight: 400;">{{ price }}</span>
        <span>{{ price - discount}}</span>
      </div>
    </th>
    <th>{{ quantity }}</th>
    <th>
      <span v-if="discount > 0"  style="color:grey;margin-right: 1rem;text-decoration: line-through;font-weight: 400;">{{ price * quantity }}</span>
      <span>{{ numb }}</span>
    </th>
  </tr>
</template>

<script>
export default {
  props: ['name', 'vendor', 'price','quantity', 'productId', 'modificationId', 'discount'],
  computed: {
    numb(){
      let n = (this.price - this.discount) * this.quantity
      return n.toFixed(2)
    },
    route(){
      let obj = {
        name: 'product',
        params: {
          product_id: this.productId
        }
      }
      if (this.modificationId !== null){
        obj.name = 'modification';
        obj.params.modification_id = this.modificationId
      }
      return obj;
    }
  }
}
</script>

<style scoped>

</style>