import * as appealsApi from '@/api/appeals'
import {storeMassage, toggleSolved} from "../../api/appeals";

export default {
    namespaced:  true,
    state: {
        items: [],
        item: undefined
    },

    getters: {
        items(state){
            return state.items
        },
        item(state){
            return state.item
        },
    },

    mutations: {
        items(state, items){
            state.items = items
        },
        item(state, item){
            state.item = item
        }
    },

    actions:{
        async items(store){
            let items = await appealsApi.items();
            store.commit('items', items)
            store.commit('loaded', false, {root: true})
        },
        async item(store, payload){
            let item = await appealsApi.item(payload.appeal_id);
            if (item){
                store.commit('item', item)
            }
            store.commit('loaded', false, {root: true})
        },
        async storeMessage(store, payload){
            let item = await appealsApi.storeMassage(payload.get('appeal_id'), payload);
            if (item) {
                store.commit('item', item)
            }
            store.commit('loaded', false, {root: true})
        },

        async deleteMessage(store, payload){
            let item = await appealsApi.deleteMassage(payload.id, payload);
            if (item) {
                store.commit('item', item)
            }
            store.commit('loaded', false, {root: true})
        },

        async editMessage(store, payload){
            let item = await appealsApi.editMassage(payload.id, payload);
            if (item) {
                store.commit('item', item)
            }
            store.commit('loaded', false, {root: true})
        },

        async toggleSolved(store, payload){
            let item = await appealsApi.toggleSolved(payload.appeal_id);
            if (item) {
                store.commit('item', item)
            }
            store.commit('loaded', false, {root: true})
        },
        async destroy(store, payload){
            let item = await appealsApi.destroy(payload);
            //store.commit('item', item)
            store.commit('loaded', false, {root: true})
        }
    }
}

