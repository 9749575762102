<template>
    <div class="row">
        <h1>Скидки</h1>
    </div>
  <div v-if="discount === undefined" style="width: 100%;height: 100vh;display:flex;justify-content: center;align-items: center;font-size: 22px;">Loading...</div>

  <form v-else class="" action="" method="post">
        <h6 class="">Добавление скидки</h6>

        <div v-if="error !== undefined" style="background-color: red;padding: 10px;">
            <p style="color:#fff;font-size: 15px;">{{ error }}</p>
        </div>

        <div class="col-3 mt-3 mb-3">
            <input v-model="name" type="text" name="name" class="form-control" id="inlineFormInputGroupManufacturerName" placeholder="Наименование">
        </div>
        <div class="col-3 mt-3 mb-3">
            <input v-model="percent" type="number" name="percent" class="form-control" placeholder="Процент">
        </div>
        <div class="col-3 mt-3 mb-3">
            <input v-model="fromDate" type="date" name="from_date" class="form-control" placeholder="С">
        </div>
        <div class="col-3 mt-3 mb-3">
            <input v-model="toDate" type="date" name="to_date" class="form-control" placeholder="По">
        </div>
        <div class="col-3 mt-3 mb-3">
            <select class="form-select" aria-label="Default select example" v-model="active">
                <option value="1">Да</option>
                <option value="0">Нет </option>
            </select>
        </div>


        <div class="col-2">
            <button @click="update" type="button" class="btn btn-primary">Изменить</button>
        </div>
    </form>
</template>

<script>
export default {
    data(){
        return{
            name: '',
            percent: 0,
            fromDate: '',
            toDate: '',
            active: '',
            error: undefined,
        }
    },

    computed:{
      discount(){
        return this.$store.getters['discount/discount']
      }
    },
    methods: {
      async update(){
        let obj = {
          discount_id: this.discount.id,
          name: this.name,
          percent: this.percent,
          from_date: this.fromDate,
          to_date: this.toDate,
          active: this.active
        }
        await this.$store.dispatch('discount/update', obj);
        await this.$router.push({name: 'discounts'})
      }
    },

    async created() {
      await this.$store.dispatch('discount/discount', {discount_id: this.$route.params.id})

        //let response = await axios.get('/api/admin/discounts/' + this.$route.params.id + '/edit');
        this.name =  this.discount.name;
        this.percent =  this.discount.percent;
        this.fromDate =  this.discount.from_date;
        this.toDate =  this.discount.to_date;
        this.active =  this.discount.active;
    }
}
</script>

<style scoped>

</style>
