<template>
  <div class="col">
    <h1>Обращение</h1>

    <div class="row">
      <div class="col-11">
        <table class="table">
          <thead>
          <tr>
            <th>#</th>
            <th>id</th>
            <th>Номер</th>
            <th>Пользователь</th>
<!--            <th>Текст</th>-->
<!--            <th>Ответ</th>-->
            <th>Создан</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(appeal, i) in appeals">
            <td>{{ i + 1 }}</td>
            <td>{{ appeal.id }}</td>

            <td>
              <router-link :to="{ name: 'appeal', params: { id: appeal.id }}"><a href="">{{ appeal.number }}</a></router-link>
            </td>
            <td>{{ appeal.user.name + ' ' + appeal.user.surname }}</td>
<!--            <td>{{ appeal.text }}</td>-->
<!--            <td>{{ appeal.response !== null ? 'Да' : 'Нет' }}</td>-->
            <td>
              <date-component :date="appeal.created_at"></date-component>
            </td>
          </tr>

          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import DateComponent from "../../components/common/DateComponent";
export default {
  components: {DateComponent},
  computed: {
    appeals(){
      return this.$store.getters['appeal/items']
    }
  },
  async created() {
    await this.$store.dispatch('appeal/items')
  }
}
</script>

<style scoped>

</style>