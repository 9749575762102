import http from "@/api/http";

export async function items() {
    let response = await http.get('/search/synonyms');
    return response.data.synonyms
}

export async function store(data) {
    let response = await http.post('/search/synonyms/store', data);
    return response.data.synonyms
}

export async function destroy(params) {
    let response = await http.delete('/search/synonyms/destroy',{data: params});
    return response.data.synonyms
}