<template>
  <li class="page-item" :class="{'active': active}">
    <a @click.prevent="handel" class="page-link" href="#">{{ linkLabel }}</a>
  </li>
</template>

<script>
export default {
  props: ['label', 'active', 'current', 'last'],
  computed: {
    linkLabel(){
      if (this.label === '&laquo; Назад'){
        return this.label.split(' ')[1]
      }
      if (this.label === 'Вперёд &raquo;'){
        return this.label.split(' ')[0]
      }
      return this.label
    }
  },
  methods: {
    handel(){
      let page = '';
      if (this.linkLabel === 'Назад'){
        if (this.current > 1){
          page = this.current - 1
        }
      }else if (this.linkLabel === 'Вперёд'){
        if (this.last !== this.current){
          page = this.current + 1
        }
      }else {
        page = Number(this.label)
      }
      if (typeof page === 'number'){
        this.$emit('select', page)
      }
    }
  }
}
</script>

<style scoped>

</style>