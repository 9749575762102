<template>
  <div style="display:flex;">
    <div style="width: 70%;height: 90vh;">

      <VuePictureCropper
          :boxStyle="{
      width: '100%',
      height: '100%',
      backgroundColor: '#f8f8f8',
      margin: 'auto',
    }"
          :img="img"
          :options="{
      viewMode: 0,
      dragMode: 'crop',
      initialAspectRatio: 1 / 1
    }"
          @cropend="getResult"
      />
    </div>

    <div style="width: 30%;" class="ps-3">
      <input type="file" ref="img" @change="add" class="form-control">

      <div>
        <div class="mt-3">
          <button @click="inc" type="button" class="btn btn-secondary">+</button>
          <button @click="dic" type="button" class="btn btn-secondary ms-3">-</button>
        </div>
        <div class="mt-3" style="display:flex;justify-content: space-between;">
          <div style="width: 48%;">
            <label for="q" class="form-label">Высота</label>
            <input type="text" class="form-control" v-model="height" disabled id="q">
          </div>
          <div  style="width: 48%;">
            <label for="q1" class="form-label">Ширина</label>
            <input type="text" class="form-control" v-model="width" disabled id="q1">
          </div>
        </div>
      </div>
      <div class="mt-3">
        <button @click="convert" type="button" class="btn btn-primary">Сохранить</button>

        <a v-if="url !== ''" :href="url" target="_blank" class="btn btn-success ms-3">скачать</a>
      </div>

      <div style="display:flex;">

        <!--      <section class="section" v-if="result.dataURL">-->
        <!--        <div class="preview">-->
        <!--          <img :src="result.dataURL" style="width: 400px;height: 400px;"/>-->
        <!--        </div>-->
        <!--      </section>-->
        <div class="mt-3" style="display:flex;justify-content: space-between;width: 100%;">
          <preview-product-component  :id-product="1"
                                      :slug="'pudra'"
                                      :name="'Maybelline New York Тушь Volume Express Colossal 100% Black'"
                                      :id-modification="1"
                                      :price="1000"
                                      :has-modifications="true"
                                      :count-modifications="5"
                                      :yellow-price="true"
                                      :image="result.dataURL"
                                      :rating="5"
                                      :category="'lico'"
                                      :sub-category="'pudra'"
                                      :availability="true"></preview-product-component>
          <div>
            <div  style="">
              <label for="q12" class="form-label">Цвет модификации</label>
              <input type="text" class="form-control" v-model="color" id="q12">
            </div>
            <div class="mt-3">
              <button @click="makeExample" type="button" class="btn btn-primary mt-3">Сделать образец</button>

              <a v-if="urlExample !== ''" :href="urlExample" target="_blank" class="btn btn-success mt-3" style="display:block;">скачать</a>
            </div>

          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import PreviewProductComponent from "../components/product/PreviewProductComponent";
import VuePictureCropper, {cropper} from "vue-picture-cropper";

export default {
  components: {PreviewProductComponent, VuePictureCropper, },
  data(){
    return{
      color: '',
      img: '',
      width: '',
      height: '',
      result: {
        dataURL: ''
      },
      obj: ''
    }
  },
  computed: {
    url(){
      return this.$store.getters['products/urlimg']
    },
    urlExample(){
      return this.$store.getters['products/example']
    }
  },
  methods: {
    async convert(){
      this.$store.commit('products/urlimg', '')
      let form = new FormData()
      form.append('file', this.result.dataURL)
      console.log(this.result)
      await this.$store.dispatch('products/convert', {file: this.result.dataURL})
      //await this.$router.push({path: this.url})
    },
    async makeExample(){
      this.$store.commit('products/example', '')
      await this.$store.dispatch('products/example', {color: this.color})
    },
    add(e){
      const file = e.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        // Update the picture source of the `img` prop
        this.img = String(reader.result)
      }
    },
    async getResult() {
      const base64 = cropper.getDataURL({fillColor: '#fff'})
      this.result.dataURL = base64
      this.width = cropper.getData().width
      this.height = cropper.getData().height
    },

    inc(){
      cropper.zoom(0.1)
    },
    dic(){
      cropper.zoom(-0.1)
    }
  }
}
</script>

<style>

.cropper-background {
  background: white;
}
</style>