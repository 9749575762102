<template>
  <div>
    <div class="mb-3">
      <textarea v-model="message.text" class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
    </div>
    <upload-file-component @images="setImages" :count="message.images.length"></upload-file-component>
    <button @click="store" type="button" class="btn btn-primary">Отправить</button>
  </div>
</template>

<script>
import UploadFileComponent from "../components/common/UploadFileComponent";
export default {
  components: {UploadFileComponent},
  props: ['id'],
  data(){
    return {
      message: {
        text: '',
        images: []
      }
    }
  },
  methods: {
    setImages(images){
      this.message.images = images
    },
    store(){
      let form = new FormData();
      form.append('text', this.message.text)
      form.append('appeal_id', this.id)
      this.message.images.forEach(image => {
        form.append('file[]', image)
      })
      this.$store.dispatch('appeal/storeMessage', form)
      this.message.text = ''
      this.message.images = []
    },
  }
}
</script>

<style scoped>

</style>