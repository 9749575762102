import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import store from "../store";

import Main from "../views/Main";
import Order from '../views/order/Order';
import OrderList from '../views/order/OrderList';
import OrderEdit from "../views/order/OrderEdit";
import UserList from "../views/user/UserList";
import User from "../views/user/User";
import UserCreat from "../views/user/UserCreate";
import UserEdit from "../views/user/UserEdit";
import ReviewList from "../views/review/ReviewList";
import Review from "../views/review/Review";
import ReviewCreate from "../views/review/ReviewCreate";
import ReviewEdit from "../views/review/ReviewEdit";
import DiscountList from "../views/discount/DiscountList";
import Discount from "../views/discount/Discount";
import DiscountCreate from "../views/discount/DiscountCreate";
import DiscountEdit from "../views/discount/DiscountEdit";
import ProductList from "../views/product/ProductList";
import ProductCreate from "../views/product/ProductCreate";
import ModificationCreate from "../views/product/modification/ModificationCreate";
import ModificationEdit from "../views/product/modification/ModificationEdit";
import Modification from "../views/product/modification/Modification"
import ProductEdit from "../views/product/ProductEdit";
import Product from "../views/product/Product";
import PropertyIist from "../views/properties/PropertyIist";
import LineList from "../views/line/LineList";
import Line from "../views/line/Line";
import LineCreate from "../views/line/LineCreate";
import LineEdit from "../views/line/LineEdit";
import CategoryList from "../views/category/CategoryList";
import Category from "../views/category/Category";
import CategoryCreate from "../views/category/CategoryCreate";
import CategoryEdit from "../views/category/CategoryEdit";
import BrandList from "../views/brand/BrandList";
import Brand from "../views/brand/Brand";
import BrandCreate from "../views/brand/BrandCreate";
import BrandEdit from "../views/brand/BrandEdit";
import ShowcaseCreate from "../views/showcase/ShowcaseCreate";
import ShowcaseEdit from "../views/showcase/ShowcaseEdit";
import ShowcaseList from "../views/showcase/ShowcaseList";
import Showcase from "../views/showcase/Showcase";
import AppealList from "../views/appeal/AppealList";
import Appeal from "../views/appeal/Appeal"
import SeoList from "../views/seo/SeoList";
import Seo from "../views/seo/Seo";
import SeoCreate from "../views/seo/SeoCreate";
import SeoEdit from "../views/seo/SeoEdit";
import Mailing from "../views/mail/Mailing";
import E404 from "../views/E404";
import YellowPriceTag from "../views/YellowPriceTag";
import Login from "../views/Login";
import Feed from "../views/Feed";
import Image from "../views/Image";

//import * as path from "path";
//import Modification from "../views/product/modification/Modification";

const routes = [
  {
    name: 'login',
    path: '/login',
    component: Login,
    meta: {requiresAuth: false}
  },
  {
    name: 'yellow',
    path: '/products/yellow',
    component: YellowPriceTag,
    meta: {requiresAuth: false}
  },
  {
    name: 'users',
    path: '/users',
    component: UserList,
    meta: {requiresAuth: true}
  },
  {
    name: 'user',
    path: '/users/:id',
    component: User,
    meta: {requiresAuth: true}
  },
  {
    name: 'userCreate',
    path: '/users/create',
    component: UserCreat,
    meta: {requiresAuth: true}
  },
  {
    name: 'userEdit',
    path: '/users/:id/edit',
    component: UserEdit,
    meta: {requiresAuth: true}
  },
  {
    name: 'brands',
    path: '/brands',
    component: BrandList,
    meta: {requiresAuth: true}
  },
  {
    name: 'brand',
    path: '/brands/:id',
    component: Brand,
    meta: {requiresAuth: true}
  },
  {
    name: 'brandCreate',
    path: '/brands/store',
    component: BrandCreate,
    meta: {requiresAuth: true}
  },
  {
    name: 'brandEdit',
    path: '/brands/:id/edit',
    component: BrandEdit,
    meta: {requiresAuth: true}
  },
  {
    name: 'categories',
    path: '/categories',
    component: CategoryList,
    meta: {requiresAuth: true}
  },
  {
    name: 'category',
    path: '/categories/:id',
    component: Category,
    meta: {requiresAuth: true}
  },
  {
    name: 'categoryCreate',
    path: '/categories/create',
    component: CategoryCreate,
    meta: {requiresAuth: true}
  },
  {
    name: 'categoryEdit',
    path: '/categories/:id/edit',
    component: CategoryEdit,
    meta: {requiresAuth: true}
  },
  {
    name: 'main',
    path: '/',
    component: Main,
    meta: {requiresAuth: true}
  },
  {
    name: 'order',
    path: '/orders/:id',
    component: Order,
    meta: {requiresAuth: true}
  },
  {
    name: 'orders',
    path: '/orders',
    component: OrderList,
    meta: {requiresAuth: true}
  },
  {
    name: 'orderEdit',
    path: '/orders/:id/edit',
    component: OrderEdit,
    meta: {requiresAuth: true}
  },
  {
    name: 'reviews',
    path: '/reviews',
    component: ReviewList,
    meta: {requiresAuth: true}
  },
  {
    name: 'review',
    path: '/reviews/:id',
    component: Review,
    meta: {requiresAuth: true}
  },
  {
    name: 'reviewCreate',
    path: '/products/:product_id/reviews/create',
    component: ReviewCreate,
    meta: {requiresAuth: true}
  },
  {
    name: 'reviewEdit',
    path: '/products/:product_id/reviews/:id/edit',
    component: ReviewEdit,
    meta: {requiresAuth: true}
  },

  {
    name: 'discounts',
    path: '/discounts',
    component: DiscountList,
    meta: {requiresAuth: true}
  },
  {
    name: 'discount',
    path: '/discounts/:id',
    component: Discount,
    meta: {requiresAuth: true}
  },
  {
    name: 'discount-create',
    path: '/discounts/create',
    component: DiscountCreate,
    meta: {requiresAuth: true}
  },
  {
    name: 'discountEdit',
    path: '/discounts/:id/edit',
    component: DiscountEdit,
    meta: {requiresAuth: true}
  },
  {
    name: 'products',
    path: '/products',
    component: ProductList,
    meta: {requiresAuth: true},
  },
  {
    name: 'feed',
    path: '/products/feed',
    component: Feed,
    meta: {requiresAuth: true}
  },
  {
    name: 'product',
    path: '/products/:product_id',
    component: Product,
    meta: {requiresAuth: true},
  },
  {
    name: 'productCreate',
    path: '/products/create',
    component: ProductCreate,
    meta: {requiresAuth: true}
  },
  {
    name: 'productEdit',
    path: '/products/:product_id/edit',
    component: ProductEdit,
    meta: {requiresAuth: true}
  },
  {
    name: 'modification',
    path: '/products/:product_id/:modification_id',
    component: Modification,
    meta: {requiresAuth: true},
  },
  {
    name: 'modificationCreate',
    path: '/products/:product_id/modifications/create',
    component: ModificationCreate,
    meta: {requiresAuth: true},
  },
  {
    name: 'modificationEdit',
    path: '/products/:product_id/:modification_id/edit',
    component: ModificationEdit,
    meta: {requiresAuth: true},
  },
  {
    name: 'properties',
    path: '/properties',
    component: PropertyIist,
    meta: {requiresAuth: true}
  },
  {
    name: 'lines',
    path: '/lines',
    component: LineList,
    meta: {requiresAuth: true}
  },
  {
    name: 'line',
    path: '/lines/:id',
    component: Line,
    meta: {requiresAuth: true}
  },
  {
    name: 'lineCreate',
    path: '/lines/create',
    component: LineCreate,
    meta: {requiresAuth: true}
  },
  {
    name: 'lineEdit',
    path: '/lines/:id/edit',
    component: LineEdit,
    meta: {requiresAuth: true}
  },
  {
    name: 'seoList',
    path: '/seo_list',
    component: SeoList,
    meta: {requiresAuth: true}
  },
  {
    name: 'seo',
    path: '/seo/:id',
    component: Seo,
    meta: {requiresAuth: true}
  },
  {
    name: 'seoCreate',
    path: '/seo/create',
    component: SeoCreate,
    meta: {requiresAuth: true}
  },
  {
    name: 'seoEdit',
    path: '/seo/:id/edit',
    component: SeoEdit,
    meta: {requiresAuth: true}
  },

  {
    name: 'showcases',
    path: '/showcases',
    component: ShowcaseList,
    meta: {requiresAuth: true}
  },
  {
    name: 'showcase',
    path: '/showcases/:id',
    component: Showcase,
    meta: {requiresAuth: true}
  },
  {
    name: 'showcaseCreate',
    path: '/showcases/create',
    component: ShowcaseCreate,
    meta: {requiresAuth: true}
  },
  {
    name: 'showcaseEdit',
    path: '/showcases/:id/edit',
    component: ShowcaseEdit,
    meta: {requiresAuth: true}
  },
  {
    name: 'appeals',
    path: '/appeals',
    component: AppealList,
    meta: {requiresAuth: true}
  },
  {
    name: 'appeal',
    path: '/appeals/:id',
    component: Appeal,
    meta: {requiresAuth: true}
  },
  {
    name: 'mailing',
    path: '/mailing',
    component: Mailing,
    meta: {requiresAuth: true}
  },
  {
    name: 'search',
    path: '/search',
    component: () => import('/src/views/Search'),
    meta: {requiresAuth: true}
  },

  {
    name: 'e404',
    path: '/:pathMatch(.*)',
    component: E404,
    meta: {requiresAuth: true}
  },
  {
    name: 'imageProcessing',
    path: '/image/processing',
    component: Image,
    meta: {requiresAuth: true}
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      //return { top: 0 }
    }
  },
})


// router.beforeEach(async (to, from) => {
//     const auth = to.matched.some((record) => record.meta.requiresAuth)
//     const isAuth =  store.getters['isAuth']
//     if (auth && !isAuth){
//       await router.push({name: 'login'})
//     }
//     if (to.name === 'login' && isAuth){
//       await router.push({name: 'main'})
//     }
// })

export default router
