<template>
  <nav aria-label="Page navigation example">
    <ul class="pagination">
<!--      <li class="page-item" :class="{'disabled': data.pages.current_page < 2}">-->
<!--        <a @click.prevent="filter($event, data.pages.current_page - 1)" class="page-link" href="#">Назад</a>-->
<!--      </li>-->

      <pagination-item-component v-for="(link, index) in links"
                                 :key="index"
                                 :label="link.label"
                                 :active="link.active"
                                 :current="current"
                                 :last="last"
                                 @select="handel"
      ></pagination-item-component>

<!--      <li class="page-item" :class="{'disabled': data.pages.current_page >= data.pages.last_page}">-->
<!--        <a @click.prevent="filter($event, data.pages.current_page + 1)" class="page-link" href="#">Вперед</a>-->
<!--      </li>-->
    </ul>
  </nav>
</template>

<script>
import PaginationItemComponent from "../pagination/PaginationItemComponent";
export default {
  components: {PaginationItemComponent},
  props: ['links', 'current', 'last'],
  computed: {

  },
  methods: {
    handel(page){
      this.$emit('select', page)
    }
  }
}
</script>

<style scoped>

</style>