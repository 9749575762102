<template>
  <div class="col-10">

    <div class="row">
      <h1>Редактирование бренда</h1>
    </div>
    <form class="" action="" method="post">
      <div class="col-3">
        <label class="form-label" for="inlineFormInputGroupManufacturerName">Наименование</label>
        <input v-model="name" type="text" class="form-control" id="inlineFormInputGroupManufacturerName" placeholder="Наименование">
      </div>

      <div class="col-2">
        <label class="form-label">Описание</label>
        <textarea v-model="description" id="" cols="50" rows="5"></textarea>
      </div>
      <div class="col-2 mt-3">
        <button @click="update" type="button" class="btn btn-primary">Редактировать</button>
      </div>
    </form>

  </div>
</template>

<script>
export default {
  data(){
    return{
      name: '',
      description: '',
      image: undefined
    }
  },
  computed:{
    brand(){
      return this.$store.getters['brands/item']
    },
  },
  methods: {
    async update(){
      await this.$store.dispatch('brands/update', {title: this.name, brand_id: this.brand.id, description: this.description})
      await this.$router.push({name: 'brands'})

    }
  },

  async created() {
    await this.$store.dispatch('brands/item', {brand_id: this.$route.params.id})
    this.name = this.brand.title;
    if (this.description !== null){
      this.description = this.brand.description
    }
  }
}
</script>

<style scoped>

</style>