<template>
  <div style="margin-bottom: 11px;">

    <div style="display:flex;width: 100%;flex-wrap: wrap;justify-content: start;">
      <div style="border: 2px solid #b0afaf;width: 3.1rem;height: 3.1rem;border-radius: 5px;box-sizing: content-box;margin: .25rem;justify-content: center;align-items: center;display:flex;  cursor: pointer;
">
        <label for="input-file" class="upload" style="height: 33px;width: 33px;"></label>
        <input type="file" id="input-file" style="display:none;" multiple @change="handel">
      </div>
      <image-preview-component v-for="(item, index) in images.raw"
                               :key="index"
                               :path="item"
                               :for-creation-review="true"
                               @remove="remove(index)"
                               :width="3.1"
                               :height="3.1"
      ></image-preview-component>
    </div>

  </div>
</template>

<script>
import ImagePreviewComponent from "@/components/common/ImagePreviewComponent";
export default {
  props: ['count'],
  components: {ImagePreviewComponent},
  data(){
    return {
      images: {
        raw: [],
        raw2: []
      },
    }
  },
  watch:{
    count(){
      if (this.count < 1){
        this.images.raw = []
        this.images.raw2 = []
      }
    }
  },
  methods: {
    handel(e){
      for (let item of e.target.files){
        let reader = new FileReader();
        reader.readAsDataURL(item);
        reader.onload = () => {
          this.images.raw.push(reader.result);
        }
        this.images.raw2.push(item)
      }
      this.$emit('images', this.images.raw2)
    },
    remove(index){
      this.images.raw.splice(index, 1)
      this.images.raw2.splice(index, 1)
      this.$emit('images', this.images.raw2)
    }
  }
}
</script>

<style scoped>
.upload {
  height: 33px;
  width: 33px;
  display: block;
  mask-image: url('../../assets/icon/add_a_photo_FILL0_wght400_GRAD0_opsz48.svg');
  mask-size: contain;
  background-color: #b0afaf;
  cursor: pointer;
}
</style>
