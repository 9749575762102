<template>
  <div class="modal fade" :id="id">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <slot></slot>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['apply'],
  props: ['id', 'title'],
  methods: {

  }
}
</script>

<style scoped>

</style>
