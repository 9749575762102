<template>
    <table class="table">
        <thead>
        <tr>
          <th class="col-1">№</th>
          <th class="col-1">id</th>
          <th class="col-4">Наименование</th>
          <th class="col-2">Артикул</th>
          <th class="col-1">Наличие</th>
          <th class="col-1">Остатки</th>
          <th class="col-2">Цена</th>
        </tr>
        </thead>
        <tbody>
            <modification-row-table-component v-for="(modification, i) in modifications"
                                              :modification-id="modification.id"
                                              :name="modification.name"
                                              :vendor="modification.code"
                                              :availability="modification.availability"
                                              :quantity="modification.quantity"
                                              :price="modification.price"
                                              :product-id="modification.product_id"
                                              :sort="1"
                                              :n="i + 1"
            ></modification-row-table-component>
        </tbody>
    </table>
</template>

<script>
import ModificationRowTableComponent from "./ModificationRowTableComponent";
export default {
    components: {ModificationRowTableComponent},
    props: ['modifications'],
}
</script>

<style scoped>

</style>
