<template>
<!--  <loader-component v-if="loaded"></loader-component>-->
  <div class="container-fluid">
    <div class="row">
      <h1 style="padding: 0;">Заказ № {{ number }}</h1>
    </div>

    <div class="row row-cols-auto mb-3">
      <button v-if="status === 1" @click="approve" type="button" class="btn btn-success">Одобрить</button>
      <button @click="$emit('show-new-mail')" type="button" class="btn btn-primary ms-3">Написать письмо</button>
      <button v-if="status === 3" @click="sent" type="button" class="btn btn-primary ms-3">Отправил</button>
      <button v-if="status === 4" @click="toComplete" type="button" class="btn btn-success ms-3">Завершить</button>
      <button v-if="showCancelBtn" @click="cancel" type="button" class="btn btn-danger ms-3">Отменить</button>
      <button @click="showHistory = true" type="button" class="btn btn-dark ms-3">История заказа</button>
      <router-link v-if="status === 1" :to="{name: 'orderEdit', params: {id: id}}" class="btn btn-secondary ms-3">Редактировать</router-link>
      <button type="button" class="btn btn-danger ms-3" data-bs-toggle="modal"  data-bs-target="#exampleModal">Удалить</button>
      <button v-if="status === 3" @click="toggleAssembly" type="button"  class="btn ms-3" :class="{'btn-secondary': !assembled , 'btn-success': assembled}">{{ !assembled ? 'Собрать' : 'Разобрать'}}</button>
    </div>

    <div class="row">
      <table class="table caption-top table-bordered">
          <tbody>
          <tr>
              <th>id</th>
              <td>{{ id }}</td>
          </tr>
          <tr>
              <th>Создано</th>
              <td>
                <date-component :date="createdAt"></date-component>
              </td>
          </tr>
          <tr>
              <th>Номер заказа</th>
              <td>{{ number }}</td>
          </tr>
          <tr>
            <th>Комментарий</th>
            <td>{{ comment }}</td>
          </tr>

          <tr>
            <th>Url оплата</th>
            <td>{{ urlPayment }}</td>
          </tr>

          <tr>
            <th>url истёк</th>
            <td>
              <date-component :date="urlExpiration"></date-component>
            </td>
          </tr>
          <tr>
              <th>Трекер</th>
              <td >
                <span>{{ tracker !== null ? tracker : 'Пусто'}}</span>
                <button v-if="status === 3" @click="showAddTracker = true" type="button" class="btn btn-secondary" style="margin-left: 44px;">Изменить</button>
              </td>
          </tr>
          <tr>
              <th>Статус</th>
              <td>
                {{ statuses[status] }}
                <button v-if="1" type="button" class="btn btn-secondary ms-3" data-bs-toggle="modal"  data-bs-target="#exampleModal2">Изменить</button>
              </td>
          </tr>
          <tr>
              <th>Покупатель</th>
              <td>
                  <table class="table caption-top table-bordered">
                      <tr>
                          <th>Имя</th>
                          <td>
                            <router-link :to="{name: 'user', params: {id: userId }}" class="ms-0">{{ customerName }}</router-link>
                          </td>
                      </tr>
                      <tr>
                          <th>Номер телефона</th>
                          <td>{{ customerPhone }}</td>
                      </tr>
                      <tr>
                          <th>Email</th>
                          <td>{{ customerEmail }}</td>
                      </tr>
                  </table>
              </td>
          </tr>
          <tr>
              <th>Доставка</th>
              <td>
                  <table class="table caption-top table-bordered">
                      <tr>
                          <th>Служба доставки</th>
                          <td>{{ deliveryServiceName }}</td>
                      </tr>
                      <tr>
                          <th>Метод доставки</th>
                          <td>{{ deliveryMethodName }}</td>
                      </tr>
                      <tr v-if="deliveryMethodName === 'Самовывоз'">
                          <th>Пункт выдачи заказа</th>
                          <td>
                              <table class="table caption-top table-bordered">
                                  <tr>
                                      <th>Название</th>
                                      <td>{{ pickUpPoint.name }}</td>
                                  </tr>
                                  <tr>
                                      <th>Код</th>
                                      <td>{{ pickUpPoint.code }}</td>
                                  </tr>
                                  <tr>
                                      <th>Тип</th>
                                      <td>{{ pickUpPoint.type }}</td>
                                  </tr>
                                  <tr>
                                      <th>Номер телефона</th>
                                      <td>
                                          <span v-for="phone in pickUpPoint.phones">{{ phone.number }}</span>
                                      </td>
                                  </tr>
                                  <tr>
                                      <th>Email</th>
                                      <td>{{ pickUpPoint.email }}</td>
                                  </tr>
                                  <tr>
                                      <th>Адрес</th>
                                      <td>{{ pickUpPoint.address }}</td>
                                  </tr>
                              </table>

                          </td>
                      </tr>
                      <tr v-else>
                          <th>Адрес</th>
                          <td>{{ deliveryAddress }}</td>
                      </tr>
                      <tr>
                          <th>Тариф</th>
                          <td>{{ deliveryTariff.tariff_name }}</td>
                      </tr>

                      <tr>
                          <th>Стоимость доставки</th>
                          <td>
                            <span v-if="deliveryDiscount > 0"  style="color:grey;margin-right: 1rem;text-decoration: line-through;font-weight: 400;">{{ deliveryCost }}</span>
                            <span>{{ deliveryCost - deliveryDiscount }}</span>
                          </td>
                      </tr>
                  </table>
              </td>
          </tr>
          <tr>
              <th>Товары</th>
              <td>
                  <table class="table caption-top table-bordered">
                      <thead>
                      <tr>
                          <th>Наименование</th>
                          <th>Артикул</th>
                          <th>Цена</th>
                          <th>Количество</th>
                          <th>Стоимость</th>
                      </tr>
                      </thead>
                      <tbody>
                        <order-items-table-row-component v-for="product in items"
                                                         :name="product.product_name"
                                                         :vendor="product.vendor"
                                                         :price="product.price"
                                                         :quantity="product.quantity"
                                                         :product-id="product.product_id"
                                                         :modification-id="product.modification_id"
                                                         :discount="product.discount"
                        ></order-items-table-row-component>

                      </tbody>
                  </table>
              </td>
          </tr>
          <tr>
              <th>Стоимость товаров</th>
              <td>
                <span v-if="discount > 0"  style="color:grey;margin-right: 1rem;text-decoration: line-through;font-weight: 400;">{{ cost }}</span>
                <span>{{ cost - discount}}</span>
              </td>
          </tr>

          <tr>
              <th>Итого</th>
              <td>
                <span v-if="discount >0" style="color:grey;margin-right: 1rem;text-decoration: line-through;font-weight: 400;">{{ Number(cost) + Number(deliveryCost) }}</span>
                <span>{{ (cost - discount) + (deliveryCost - deliveryDiscount) }}</span>
              </td>
          </tr>
          </tbody>
      </table>
    </div>

  </div>


  <div v-if="showAddTracker" class="" style="display:flex;background-color: rgba(0,0,0,0.5);width: 100%;height: 100vh;position:fixed;top: 0;left: 0;justify-content: center;align-items: center">
    <div style="background-color: #fff;padding: 20px;">
      <button  @click="showAddTracker=false" type="button" class="btn btn-danger hide-create-sku" >Закрыть</button>

      <form action="" method="post" enctype="multipart/form-data">

        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label">Трекер</label>
          <input v-model="trackerNumber" type="text" class="form-control" id="exampleFormControlInput1" placeholder="">
        </div>
        <div class="col">
          <button @click="setTracker" type="button" class="btn btn-primary">Изменить</button>
        </div>
      </form>
    </div>
  </div>

  <div v-if="showWarning" class="" style="display:flex;background-color: rgba(0,0,0,0.5);width: 100%;height: 100vh;position:fixed;top: 0;left: 0;justify-content: center;align-items: center">
    <div style="background-color: #fff;padding: 20px;">
      <p>Заполните трекер</p>

      <div class="col">
        <button @click="showWarning = false" type="button" class="btn btn-primary">Ок</button>
      </div>
    </div>
  </div>
  <div v-if="showHistory" style="display:flex;background-color: rgba(0,0,0,0.5);width: 100%;height: 100vh;position:fixed;top: 0;left: 0;justify-content: center;align-items: center;">
    <div style="padding: 20px;background-color: white">
      <button @click="showHistory = false" type="button" class="btn btn-primary" style="margin-bottom: 0;">Закрыть</button>
      <order-history-component v-for="history in statusHistory"
                               :status="history.status"
                               :time="history.time"
      ></order-history-component>
    </div>
  </div>


  <div class="modal fade" id="exampleModal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Удаление заказа</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>Вы уверены что хотите удалить ?</p>
        </div>
        <div class="modal-footer">
          <button @click="showConfirmation = false" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Нет</button>
          <button @click="remove" type="button" class="btn btn-primary" data-bs-toggle="modal"  data-bs-target="#exampleModal">Да</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="exampleModal2">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Изменение статуса заказа</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="col">
            <label for="exampleFormControlInput1" class="form-label">Статус:</label>
            <select v-model="selectedStatus" class="form-select" aria-label="Default select example">
              <option :value="''">Выбрать статус</option>
              <option v-for="(status, index) in statuses" :value="index">{{ status }}</option>
            </select>
          </div>
          <div class="form-check mt-3">
            <input v-model="notify" class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
            <label class="form-check-label" for="flexCheckDefault">
              Уведомить ?
            </label>
          </div>
        </div>
        <div class="modal-footer">
          <button @click="showConfirmation = false" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Отмена</button>
          <button @click="changeStatus" type="button" class="btn btn-primary" data-bs-toggle="modal"  data-bs-target="#exampleModal2">Изменить</button>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
// import LoaderComponent from "./LoaderComponent";
import OrderHistoryComponent from "./OrderHistoryComponent";
import NewEmailPopupComponent from "./NewEmailPopupComponent";
import OrderItemsTableRowComponent from "./order/OrderItemsTableRowComponent";
import DateComponent from "./common/DateComponent";
export default {
  components: {DateComponent, NewEmailPopupComponent, OrderHistoryComponent, OrderItemsTableRowComponent},
  props: [
    'id',
    'number',
    'createdAt',
    'status',
    'customerName',
    'customerPhone',
    'customerEmail',
    'deliveryServiceName',
    'comment',
    'deliveryMethodName',
    'deliveryAddress',
    'deliveryTariff',
    'deliveryCost', 'deliveryDiscount', 'items', 'pickUpPoint', 'cost', 'tracker', 'urlPayment', 'urlExpiration', 'statusHistory', 'userId', 'assembled', 'discount'
  ],
  data(){
    return{
      trackerNumber: '',
      showAddTracker: false,
      showWarning: false,
      showHistory: false,
      showConfirmation: false,
      selectedStatus: '',
      notify: false
    }
  },
  computed: {
    message(){
      return this.$store.getters.message
    },
    statuses(){
      return this.$store.getters['orders/statuses']
    },
    showCancelBtn(){
      return this.status === 1 || this.status === 2
    },
  },
  methods: {
    async approve(){
        this.$store.dispatch('orders/approve', {order_id: this.id})
    },
    async remove(){
      await this.$store.dispatch('orders/remove', {order_id: this.id})
      if (!this.message){
        await this.$router.push({name: 'orders'})
      }
    },
    async sent(){
      if (this.tracker !== null){
      this.$store.dispatch('orders/sent', {order_id: this.id})
      }else {
        this.showWarning = true;
      }
    },
    async toComplete(){
      this.$store.dispatch('orders/toComplete', {order_id: this.id})
    },
    async cancel(){
      this.$store.dispatch('orders/cancel', {order_id: this.id})
    },
    async setTracker(){
      this.$store.dispatch('orders/setTracker', {order_id: this.id, tracker: this.trackerNumber})
      this.trackerNumber = '';
      this.showAddTracker = false;
    },

    async changeStatus(){
      if (this.selectedStatus === 4){
        if (this.tracker === null){
          this.showWarning = true
          return;
        }
      }
      this.$store.dispatch('orders/changeStatus', {order_id: this.id, status: this.selectedStatus,  notify: this.notify})
      this.selectedStatus = '';
      this.notify = false;
    },
    toggleAssembly(){
      this.$store.dispatch('orders/update', {order_id: this.id, assembled: !this.assembled})
    }
  },

}
</script>

<style scoped>
.modalShow{
  display: block;
}
</style>
