import axios from "axios";
import discount from "../../views/discount/Discount";

export default {
    namespaced:  true,
    state: {
        discounts: [],
        current: {
            discount: undefined,
            products: [],
        },
        properties: undefined
    },

    getters: {
        discounts(state){
            return state.discounts
        },
        discount(state){
            return state.current.discount
        },
        products(state){
            let a = []
            state.current.products.forEach(item => {
                let b = item.product;
                b.modifications = item.modifications
                a.push(b)
            })
            return a
        },
        properties(state){
            return state.properties
        },
    },

    mutations: {
        discounts(state, discounts){
            state.discounts = discounts
        },
        discount(state, data){
            state.current.discount = data.discount;
            state.current.products = data.products;
        },
        add(state, discount){
            state.discounts.push(discount)
        },
        remove(state, id) {
            let index = state.discounts.findIndex(item => item.id === id)
            state.discounts.splice(index, 1)
        },
        update(state, discount) {
            state.current.discount = discount
        },
        removeFromDiscount(state, payload){
            let index = state.current.products.findIndex(item => {
                return item.product.id === payload.product_id
            })
            if (payload.modification_id === null){
                state.current.products.splice(index, 1)
            }else {
                let index2 = state.current.products[index].modifications.findIndex(modification => {
                    return modification.id === payload.modification_id
                })
                state.current.products[index].modifications.splice(index2, 1)
                if (state.current.products[index].modifications.length < 1){
                    state.current.products.splice(index, 1)
                }
            }
        },
        addInDiscount(state, product){
            if (!product.hasOwnProperty('product_id')){
                state.current.products.push({ product: product, modifications: [] })
            }else {
                let index = state.current.products.findIndex(item => {
                    return item.product.id === product.product_id
                })
                if (index !== -1){
                    state.current.products[index].modifications.push(product)
                }else {
                    let obj = {
                        product: product.product ,
                        modifications: [product]
                    }
                    state.current.products.push(obj)
                }
            }
        },
        properties(state, types){
            state.properties = types
        },
    },

    actions:{
        async discounts(store){
            let response = await axios.get('/api/admin/discounts');
            store.commit('discounts', response.data.discounts)
        },
        async discount(store, payload){
            store.commit('loaded', true, {root: true})
            await axios.get(`/api/admin/discounts/${payload.discount_id}`).then(response => {
                if (response.data.success){
                    store.commit('discount', response.data)
                }else {

                }
            }).catch(error => {

            }).finally(() => {
                store.commit('loaded', false, {root: true})
            })
        },
        async store(store, payload){
            let response = await axios.post('/api/admin/discounts/store', payload);
            await store.commit('add', response.data.discount)
        },
        async update(store, payload){
            let obj = {
                name: payload.name,
                percent: payload.percent,
                active: payload.active, from_date: payload.from_date, to_date: payload.to_date
            }
            let response = await axios.post(`/api/admin/discounts/${payload.discount_id}/update`, obj);
            //await store.commit('update', response.data.discount)
        },
        async remove(store, payload){
            let url = `/api/admin/discounts/${payload.discount_id}/remove`;
            let response = await axios.delete(url);
            if (response.data.success){
                store.commit('remove', payload.discount_id)
            }
        },
        async addInDiscount(store, payload){
            let url = `/api/admin/discounts/${payload.id}/product/add`;
            let response = await axios.post(url, payload);
            if (response.data.error){
            }else{
                store.commit('addInDiscount', response.data.product)
            }
        },
        async removeFromDiscount(store, payload){
            let url = `/api/admin/discounts/${store.getters.discount.id}/products/remove`;

            let response = await axios.delete(url, {
                data: {
                    product_id: payload.product_id,
                    modification_id: payload.modification_id
                }
            });
            if (response.data.success){
                store.commit('removeFromDiscount', {product_id: payload.product_id, modification_id: payload.modification_id})
            }
        },
        async types(store){
            let response = await axios.get('/api/admin/discounts/types');
            store.commit('properties', response.data)
        },
        async activeToggle(store, payload){
            let response = await axios.put(`/api/admin/discounts/${payload.discount_id}/active`);
            await store.commit('update', response.data.discount)
        },
    }
}

