import axios from "axios";
import * as ordersApi from '@/api/orders'
import {destroyOrderItem} from "../../api/orders";

export default {
    namespaced: true,
    state: {
        data: [],
        items: [],
        item: null,

        statuses: [
            'Отменено',
            'Новый',
            'Ожидание оплаты',
            'Оплачено',
            'Отправлено',
            'Выполнено',
            'Срок оплаты истек',
            'Страница оплаты недоступна',
            'Не вручён',
            'Возврат',
            'Готов к выдаче'
        ],
        typeOfDiscounts: [
            {
                id: 0,
                name: 'Процент'
            },
            {
                id: 1,
                name: 'Фиксированная сумма'
            },
            {
                id: 2,
                name: 'Указать новую цену'
            }
        ],
    },
    getters: {
        typeOfDiscounts(state){
            return state.typeOfDiscounts
        },
        items(state){
            return state.items
        },
        item(state){
            return state.item
        },
        statuses(state){
            return state.statuses
        },
        data(state){
            return state.data;
        },
    },
    mutations: {
        items(state, items){
            state.items = items;
        },
        data(state, pages){
            state.data = pages;
        },
        item(state, item){
            state.item = item;
        },
    },
    actions: {
        async items(store, payload){
            let orders = await ordersApi.items(payload)
            store.commit('data', orders)
            store.commit('loaded', false, {root: true})
        },
        async item(store, id){
            let order = await ordersApi.item(id)
            store.commit('item', order)
            store.commit('loaded', false, {root: true})
        },
        async update(store, payload){
            let order = await ordersApi.update(payload.order_id, payload)
            store.commit('item', order)
            store.commit('loaded', false, {root: true})
        },
        async remove(store, payload){
            let response = await ordersApi.destroy(payload.order_id);
            store.commit('loaded', false, {root: true})
        },
        async approve(store, payload){
            let order = await ordersApi.approve(payload.order_id)
            store.commit('item', order)
            store.commit('loaded', false, {root: true})
        },
        async setTracker(store, payload){
            let order = await ordersApi.setTracker(payload.order_id, {tracker: payload.tracker})
            store.commit('item', order)
            store.commit('loaded', false, {root: true})
        },
        async sent(store, payload){
            let order = await ordersApi.sent(payload.order_id)
            store.commit('item', order)
            store.commit('loaded', false, {root: true})
        },
        async toComplete(store, payload){
            let order = await ordersApi.toComplete(payload.order_id)
            store.commit('item', order)
            store.commit('loaded', false, {root: true})
        },
        async cancel(store, payload){
            let order = await ordersApi.cancel(payload.order_id)
            store.commit('item', order)
            store.commit('loaded', false, {root: true})
        },
        async updateQuantity(store, payload){
            let order  = await ordersApi.updateQuantity(payload.order_id, payload);
            store.commit('item', order)
            store.commit('loaded', false, {root: true})
        },
        async updateCost(store, payload){
            let order  = await ordersApi.updateCost(payload.order_id, payload);
            store.commit('item', order)
            store.commit('loaded', false, {root: true})
        },
        async updateDeliveryCost(store, payload){
            let order  = await ordersApi.updateDeliveryCost(payload.order_id, payload);
            store.commit('item', order)
            store.commit('loaded', false, {root: true})
        },

        async destroyDiscount(store, payload){
            let order  = await ordersApi.destroyDiscount(payload.order_id, payload);
            store.commit('item', order)
            store.commit('loaded', false, {root: true})
        },
        async destroyDeliveryDiscount(store, payload){
            let order  = await ordersApi.destroyDeliveryDiscount(payload.order_id, payload);
            store.commit('item', order)
            store.commit('loaded', false, {root: true})
        },
        async changeStatus(store, payload){
            let order  = await ordersApi.changeStatus(payload.order_id, payload);
            store.commit('item', order)
            store.commit('loaded', false, {root: true})
        },
        async exportInFile(store){
            let order  = await ordersApi.exportInFile();
            return order
        },
        async addOrderItem(store, payload){
            let order  = await ordersApi.addOrderItem(payload.order_id,payload);
            store.commit('item', order)
            store.commit('loaded', false, {root: true})
        },
        async destroyOrderItem(store, payload){
            let order  = await ordersApi.destroyOrderItem(payload.order_id, payload);
            store.commit('item', order)
            store.commit('loaded', false, {root: true})
        },
        async updatePriceOrderItem(store, payload){
            let order  = await ordersApi.updatePriceOrderItem(payload.order_id, payload);
            store.commit('item', order)
            store.commit('loaded', false, {root: true})
        },
    }
}
