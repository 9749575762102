<template>
    <table class="table caption-top table-bordered">
        <tbody>
        <tr>
            <th>id</th>
            <td>{{ id }}</td>
        </tr>
        <tr>
            <th>Создано</th>
            <td>
              <date-component :date="created"></date-component>
            </td>
        </tr>
        <tr>
            <th>Текст</th>
            <td>{{ text }}</td>
        </tr>
        <tr>
            <th>Оценка</th>
            <td>{{ assessment }}</td>
        </tr>
        <tr>
            <th>Пользователь</th>
            <td>
              <router-link :to="{name: 'user', params: {id: user.id}}">{{ user.name + ' ' + user.surname }}</router-link>
            </td>
        </tr>
        <tr>
            <th>Продукт</th>
            <td>
              <router-link :to="url">{{ productName }}</router-link>
            </td>
        </tr>

        <tr>
          <th>Изображения</th>
          <td>
            <div style="display:flex;">
              <image-preview-component v-for="(image, index) in images"
                                       :id="image.id"
                                       :path="baseUrl + image.file"
                                       :image="index"
                                       @show-viewer="a"
              ></image-preview-component>
            </div>
          </td>
        </tr>
        <tr>
          <th>Ответ</th>
          <td>{{ response }}</td>
        </tr>

        <tr>
          <th>Дата ответа</th>
          <td>
            <date-component :date="answered"></date-component>
          </td>
        </tr>

        <tr>
            <th>Одобрено</th>
            <td>{{ approved ? 'Да' : 'Нет' }}</td>
        </tr>
        </tbody>
    </table>



  <viewer-component  v-if="showViewer" :path="baseUrl + image.file" @close-viewer="showViewer = false"></viewer-component>
</template>

<script>
import ImagePreviewComponent from "./ImagePreviewComponent";
import ViewerComponent from "./ViewerComponent";
import DateComponent from "./common/DateComponent";
export default {
  components: {DateComponent, ViewerComponent, ImagePreviewComponent},
  props: ['id', 'text', 'assessment', 'user', 'product', 'approved', 'answered', 'created', 'images', 'response'],
  data(){
    return {
      showViewer: false,
      image_id: '',
    }
  },
  computed: {
    image(){
      return this.images.find(image => image.id === this.image_id)
    },
    baseUrl(){
      return this.$store.getters.imagesUrl + '/storage/'
    },
    url(){
      let url = {
        name: 'product',
        params: {
          product_id: this.product.id
        }
      }
      if (this.product.modifications.length > 0){
        url.name = 'modification';
        url.params.modification_id = this.product.modifications[0].id
      }
      return url
    },
    productName(){
      let name = this.product.name
      if (this.product.modifications.length > 0){
        name += ' ' + ( this.product.modifications.length > 0 ? this.product.modifications[0].name : '')
      }
      return name
    },
    date(){
      let date = new Date(this.created)
      return date.getUTCDate() +'.' + (date.getUTCMonth() + 1)+ '.' +date.getUTCFullYear() +' '+date.getUTCHours() +':'+date.getUTCMinutes()
    },

  },
  methods: {
    a(id){
      this.image_id = id
      this.showViewer = true
    },

  }

}
</script>

<style scoped>

</style>
