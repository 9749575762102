<template>
  <loader-component v-if="loaded"></loader-component>

  <div v-else class="container-fluid">
    <div class="row">
        <h1>Жёлтый ценник</h1>
    </div>

    <div class="row">
      <div class="col">
        <discount-products-table-component :products="products"></discount-products-table-component>
      </div>
    </div>

</div>
</template>

<script>
import LoaderComponent from "@/components/LoaderComponent";
import DiscountProductsTableComponent from "../components/discount/DiscountProductsTableComponent";
export default {
    components: {LoaderComponent, DiscountProductsTableComponent},
    data(){
        return{
        }
    },

    computed: {
      loaded(){
        return this.$store.getters.loaded
      },
      products(){
          return this.$store.getters['products/yellow'];
      }
    },
    methods: {
    },

    async created() {
        await this.$store.dispatch('products/itemsYellow')
    }
}
</script>

<style scoped>
h2{
    font-size: 20px;
}
</style>
