<template>
  <loader-component v-if="loaded"></loader-component>
    <div v-else class="col">
        <h1>Отзывы</h1>
        <div class="row">
            <div class="col-11">
                <table class="table">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>id</th>
                        <th>Текст</th>
                        <th>Оценка</th>
                        <th>Пользователь</th>
                        <th>Продукт</th>
                        <th>Одобренный</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(review, i) in reviews">
                        <td>{{ i + 1 }}</td>
                        <td>
                            {{ review.id }}
                        </td>
                        <td>
                          <router-link :to="{ name: 'review', params: { id: review.id }}"><a href="">{{ review.text }}</a></router-link>
                        </td>
                        <td>{{ review.assessment }}</td>
                        <td>{{ review.user.name + ' '+ review.user.surname }}</td>
                        <td>{{ review.product.name }}</td>
                        <td>{{ review.approved ? 'Да' : 'Нет'}}</td>
                    </tr>

                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "@/components/LoaderComponent";
export default {
  components: {LoaderComponent},
  computed: {
      reviews(){
          return this.$store.getters['review/items']
      },
      loaded(){
        return this.$store.getters.loaded
      },

    },
    async created() {
        await this.$store.dispatch('review/items')
    }
}
</script>

<style scoped>

</style>
