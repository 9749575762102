<template>
  <div @click="handler" class="image">
    <img :src="path"  alt="" style="object-fit: contain;width: 50px;height: 50px;">
    <span v-if="forCreationReview" @click="$emit('remove')" class="remove-icon"></span>
  </div>
</template>

<script>
export default {
  props: ['path', 'forCreationReview', 'width', 'review', 'height', 'id'],
  computed: {

  },
  methods: {
    handler(){

      this.$emit('show-viewer', this.id)
    }
  }
}
</script>

<style scoped>
.image{
  border: 2px solid #b0afaf;width: 50px;height: 50px;border-radius: 5px;box-sizing: content-box;margin: .25rem;position: relative;cursor: pointer;
}
.remove-icon{
  width: 22px;
  height: 22px;
  mask-image: url("../../assets/icon/cancel_FILL0_wght400_GRAD0_opsz48.svg");
  background-color: red;
  display: block;
  mask-size: cover;
  position: absolute;
  top: -11px;
  right: -11px;
  cursor: pointer;
}
.border{
  border: 3px solid #df6aaa;
}

</style>
