import axios from "axios";
import router from "@/router";
import store from "@/store";

let baseUrl = process.env.NODE_ENV === 'production' ? 'https://api.aribay.ru' : 'http://localhost:8000';
const instance = axios.create({
    baseURL: baseUrl + '/api/admin',
    withCredentials: true
})

instance.interceptors.request.use(config => {
    if (config.url !== '/user'){
        store.commit('loaded', true, {root: true})
    }
    return config
}, function (error) {
    return Promise.reject(error)
})

instance.interceptors.response.use(response => {
    if (response.data.success === false){
        store.commit('message', response.data.message, {root: true})
        store.commit('loaded', false, {root: true})
        return response;
    }else {
        return response;
    }
}, async error => {
    if (error.response === undefined){
        store.commit('loaded', false, {root: true})
        store.commit('message', 'Произошла ошибка', {root: true})
        return;
    }

    if (error.response.status === 401){
        await router.push({name: 'login'});
        store.commit('loaded', false, {root: true})
        return;
    }
    if (error.response.status === 500){
        store.commit('loaded', false, {root: true})
        store.commit('message', 'Ошибка сервера', {root: true})
        return;
    }

    return Promise.reject(error);
})

export default instance;