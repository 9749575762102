<template>
  <div class="col-10">

    <div class="row">
      <h1>Создание бренда</h1>
    </div>
    <form class="" action="" method="post">
      <div class="col-3">

        <label class="" for="inlineFormInputGroupManufacturerName">Наименование</label>
        <input v-model="name" type="text" class="form-control" id="inlineFormInputGroupManufacturerName" placeholder="Наименование">
      </div>

      <div class="col-2">
        <label class="form-label">Описание</label>
        <textarea v-model="description" id="" cols="50" rows="5"></textarea>
      </div>
      <div class="col-2  mt-3">
        <button @click="store" type="button" class="btn btn-primary">Создать</button>
      </div>
    </form>
    <hr>


  </div>
</template>

<script>
export default {
  data(){
    return{
      name: '',
      description: '',
    }
  },
  computed: {
    message(){
      return this.$store.getters.message
    },
  },
  methods:{
    async store(){
      await this.$store.dispatch('brands/store', {title: this.name, description: this.description})
      if (!this.message){
        await this.$router.push({name: 'brands'})
      }
    }
  }
}
</script>

<style scoped>

</style>