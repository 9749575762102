import * as searchApi from '@/api/search'
export default {
    namespaced:  true,
    state: {
        items: [],
        item: undefined
    },

    getters: {
        items(state){
            return state.items
        },
        item(state){
            return state.item
        },
    },

    mutations: {
        items(state, items){
            state.items = items
        },
        item(state, item){
            state.item = item
        },
        destroy(state, params){
            if (params.hasOwnProperty('j')){
                state.items[params.i].splice(params.j, 1)
            }else {
                delete state.items[params.i]
            }
        }
    },

    actions:{
        async items(store){
            let items = await searchApi.items();
            store.commit('items', items)
        },
        async store(store, payload){
            let synonyms = await searchApi.store(payload);
            store.commit('items', synonyms)
        },
        async destroy(store, payload){
            let response = await searchApi.destroy(payload);
            store.commit('items', response)
        }

    }
}

