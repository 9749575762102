<template>
  <button @click="aaa"
          :class="{'in-cart': inCart,  'not-available': !availability }"
          :disabled="loaded"
          class="card-btn btn-buy"
          type="button"
  >
    <span>{{ labelBtn }}</span>
  </button>

</template>

<script>
export default {
  props: ['inCart', 'loaded', 'availability'],
  computed:{
    labelBtn(){
      if (!this.availability){
        return 'Нет в наличие'
      }
      return !this.inCart ? 'Купить' : 'В корзине'
    },
  },
  methods: {
    aaa(){
      if (this.availability) {
        this.$emit('click-buy')
    }
  }
}
}
</script>
<style scoped>
.card-btn{
  width: 100%;
  padding: .5em 0;
  font-size: 1rem;
  border-radius: .36em;
  border: 1px solid #df6aaa;
}
.card-btn:hover{
}
.btn-buy{
  background-color: #df6aaa;
  color: white;
  border: none;
  cursor: pointer;
}
.in-cart{
  background-color: #91225e;
}
.not-available{
  background-color: #b0afaf;
}

@media (max-width: 991px) {
  .card-btn{
    font-size: 13px;
    border-radius: 3px;
  }
}
</style>
