<template>
<!--  <loader-component v-if="loaded"></loader-component>-->

  <div class="container-fluid">

    <div class="row">
      <h1>Товар</h1>
    </div>
      <product-component :id="product.id"
                         :name="product.name"
                         :category="product.category.name"
                         :price="product.price"
                         :quantity="product.quantity"
                         :code="product.code"
                         :status="product.status"
                         :description="product.description"
                         :images="product.images"
                         :properties="product.properties"
                         :modifications="product.modifications"
                         :has-modifications="product.has_modifications"
                         :main-image-id="product.main_photo_id"
                         :active="product.active"
                         :availability="product.availability"
                         :h1="product.h1"
                         :meta-title="product.meta_title"
                         :meta-description="product.meta_description"
                         :keywords="product.keywords"
                         :rating="product.rating"
                         :has-yellow-price="product.yellow_price_tag"
                         :inventory-control="product.inventory_control"
                         :url="product.slug"
      ></product-component>

    <div class="row">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="#">С этим товаром покупают</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Link</a>
        </li>
      </ul>
    </div>

    <add-item-component @add-item="add"></add-item-component>


    <table class="table">
      <thead>
      <tr>
        <th>№</th>
        <th>ID товара</th>
        <th>ID мод.</th>
        <th class="">Наименование</th>
        <th class="">Бренд</th>
        <th class="">Категория</th>
        <th class="">Артикул</th>
        <th class="">Наличие</th>
        <th class="">Остатки</th>
        <th class="">Цена</th>
        <th>Сортировка</th>
        <th>Удалить</th>
      </tr>
      </thead>
      <tbody>
      <product-row-table-component v-for="(item, i) in product.purchasable"
                                   :id="item.id"
                                   :modification_id="item.id_modification"
                                   :name="item.name"
                                   :brand="item.brand"
                                   :line="item.line"
                                   :category="item.category"
                                   :vendor="item.code"
                                   :availability="item.availability"
                                   :quantity="item.quantity"
                                   :price="item.price"
                                   :sort="item.sort"
                                   :has-modifications="item.has_modifications"
                                   :n="i"
                                   @remove-item="remove"
                                   @move-up="up"
                                   @move-down="down"
      ></product-row-table-component>

      </tbody>
    </table>

  </div>

</template>

<script>
import AddItemComponent from "../../components/AddItemComponent";
import ProductComponent from "../../components/product/ProductComponent";
import LoaderComponent from "../../components/LoaderComponent";
import store from "../../store";
import ProductRowTableComponent from "../../components/product/list/ProductRowTableComponent";
export default {
    components: {LoaderComponent, ProductComponent, AddItemComponent, ProductRowTableComponent},
    data(){
        return{
            props: [],
        }
    },
    computed: {
      product() {
        return this.$store.getters['products/item']
      },
      loaded() {
        return this.$store.getters.loaded
      },
       message(){
        return this.$store.getters.message
       }
    },
    methods: {
        add(params){
          params.product_id = this.product.id
          this.$store.dispatch('products/storePurchasable', params)
        },

        remove(params){
          params.product_id = this.product.id
          this.$store.dispatch('products/removePurchasable', params)
      },

      up(params){
          let purchasable = this.product.purchasable;
            if (purchasable[0].id === params.id || purchasable[0].id_modification === params.id){
              return;
            }

        params.product_id = this.product.id
        this.$store.dispatch('products/up', params)
      },
      down(params){
        let purchasable = this.product.purchasable;
          if (purchasable[purchasable.length - 1].id === params.id || purchasable[purchasable.length - 1].id_modification === params.id){
            return;
          }

        params.product_id = this.product.id
        this.$store.dispatch('products/down', params)
      },
    },

    async beforeRouteEnter(to, from, next) {
      await store.dispatch('products/item', {product_id: to.params.product_id})
      next()
    },
  async beforeRouteUpdate(to, from, next) {
    await store.dispatch('products/item', {product_id: to.params.product_id})
  },
}
</script>

<style scoped>

</style>
