<template>
  <div class="col-4 mt-3 mb-3">
    <label class="form-label">{{ label }}</label>
    <input :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" :type="type" class="form-control" :placeholder="''">
  </div>
</template>

<script>
export default {
  props: ['type', 'label', 'modelValue'],

  data(){
    return {
      //value: ''
    }
  },
}
</script>

<style scoped>

</style>