<template>
    <tr>
      <td>{{ id + 1 }}</td>
      <td>{{ id }}</td>
      <td>
        <router-link :to="{name: 'modification', params: {product_id: productId, modification_id: id} }">{{ name }}</router-link>
      </td>
      <td>{{ vendor }}</td>
      <td>{{ price }}</td>
      <td>
          <form >
              <button @click="removeFromDiscount(id)" type="button" class="delete-btn"></button>
          </form>
      </td>
    </tr>
</template>

<script>
export default {
  props: ['id', 'productId', 'name', 'vendor', 'price'],
  methods: {
    removeFromDiscount(modification_id = null){
      this.$store.dispatch('discount/removeFromDiscount', {product_id: this.productId, modification_id: modification_id})
    },
  }
}
</script>

<style scoped>
.delete-btn{
  height: 22px;
  width: 22px;
  mask-image: url(../../assets/icon/delete_FILL0_wght400_GRAD0_opsz48.svg);
  mask-size: cover;
  background-color: #5c636a;
}
</style>
