<template xmlns="http://www.w3.org/1999/html">
  <loader-component v-if="loaded"></loader-component>
  <div v-else class="col">

    <div class="row" style="display:flex;">
      <h1 style="font-size: 28px;">Пользователь</h1>
      <div class="mb-3">
        <router-link :to="{name: 'userEdit', params: {id: user.id}}">
          <a href="" class="btn btn-secondary" style="">Редактировать</a>
        </router-link>
        <button @click="showEmail = true" type="button" class="btn btn-primary ms-3">Написать письмо</button>
        <button @click="remove" type="button" class="btn btn-danger ms-3">Удалить</button>

      </div>
    </div>

    <div class="row">
      <div class="col-4">
        <table class="table caption-top table-bordered">
          <tbody>
          <tr>
            <th>id</th>
            <td>{{ user.id }}</td>
          </tr>
          <tr>
            <th>Имя Фамилия</th>
            <td>{{ user.name + ' '+ user.surname }}</td>
          </tr>
          <tr>
            <th>Телефон</th>
            <td>{{ user.phone }}</td>
          </tr>
          <tr>
            <th>E-mail</th>
            <td>{{ user.email }}</td>
          </tr>

          <tr>
            <th>Роль</th>
            <td>{{ user.role }}</td>
          </tr>
          <tr>
            <th>Статус</th>
            <td>{{ user.status }}</td>
          </tr>
          <tr>
            <th>Сумма</th>
            <td>{{ user.amount_of_purchases }}</td>
          </tr>
          <tr>
            <th>Чёрный список</th>
            <td>{{ user.black_list ? 'Да' : 'Нет' }}</td>
          </tr>
          <tr>
            <th>Создан</th>
            <td>
              <date-component :date="user.created_at"></date-component>
            </td>
          </tr>

          </tbody>
        </table>

      </div>
    </div>
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a class="nav-link active" aria-current="page" href="#">Адреса доставки</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#">Link</a>
      </li>
    </ul>
    <table class="table">

      <thead>
      <tr>
        <th>№</th>
        <th>id</th>
        <th class="">Служба</th>
        <th class="">Метод</th>
        <th class="">Регион</th>
        <th class="">Населенный пункт</th>
        <th class="col-3">Пвз</th>
        <th class="">Адрес</th>
        <th class="">Текущий</th>
        <th class="">Создан</th>
        <th class="">Удалить</th>
      </tr>
      </thead>
      <tbody>
        <address-table-row-component v-for="(address, i) in addresses"
                                     :id="address.id"
                                     :n="i"
                                     :service="address.delivery_service_name"
                                     :method="address.delivery_method_name"
                                     :region="address.region"
                                     :city="address.city_name"
                                     :point="address.pick_up_point"
                                     :address="address.delivery_address"
                                     :active="user.current_address_id === address.id"
                                     :created-at="address.created_at"
                                     :user-id="user.id"
        ></address-table-row-component>
      </tbody>
    </table>

  </div>
  <new-email-popup-component v-if="showEmail" :email="user.email" @close-new-mail="showEmail = false"></new-email-popup-component>

</template>

<script>
import LoaderComponent from "../../components/LoaderComponent";
import SpinnerComponent from "../../components/SpinnerComponent";
import NewEmailPopupComponent from "@/components/NewEmailPopupComponent";
import DateComponent from "../../components/common/DateComponent";
import AddressTableRowComponent from "../../components/user/addresses/AddressTableRowComponent";
export default {
  components: {AddressTableRowComponent, DateComponent, NewEmailPopupComponent, SpinnerComponent, LoaderComponent},
  data(){
    return{
      showEmail: false,
    }
  },
  computed: {
    loaded(){
      return this.$store.getters.loaded
    },
    user(){
      return this.$store.getters['users/item']
    },
    addresses(){
      return this.user.addresses
    },
    message(){
      return this.$store.getters.message
    },
  },
  methods: {
    async remove(){
      await this.$store.dispatch('users/remove', {user_id: this.user.id})
      if (!this.message) {
        await this.$router.push({name: 'users'})
      }
    }
  },
  async created() {
    await this.$store.dispatch('users/item', {id: this.$route.params.id})

  }
}
</script>

<style scoped>

</style>
