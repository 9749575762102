<template>
    <div class="col">

        <div class="row">
            <h1>Линия</h1>
        </div>

        <div class="row">
            <div class="col-4">
                <table class="table caption-top table-bordered">
                    <caption>Линия</caption>
                    <tbody>
                    <tr>
                        <th>id</th>
                        <td>{{ line.id }}</td>
                    </tr>
                    <tr>
                        <th>Бренд</th>
                        <td>{{ line.brand.title }}</td>
                    </tr>
                    <tr>
                        <th>Наименование</th>
                        <td>{{ line.title }}</td>
                    </tr>

                    </tbody>
                </table>

              <div>
                <router-link :to="{name: 'lineEdit', params: {id: line.id}}">
                  <a class="btn btn-primary" style="margin-right: 16px;">Редактировать</a>
                </router-link>
                <button @click="destroy()" class="btn btn-danger" type="button">Удалить</button>
              </div>

            </div>
        </div>


    </div>
</template>

<script>
export default {
  computed:{
    line(){
      return this.$store.getters['lines/item']
    },
  },
    methods: {
        async destroy(){
          await this.$store.dispatch('lines/destroy', {line_id: this.line.id})
          await this.$router.push({name: 'lines'})
        }
    },
    async created() {
      await this.$store.dispatch('lines/item', {line_id: this.$route.params.id})
    }
}
</script>

<style scoped>

</style>
