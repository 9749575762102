<template>
<div>
    <div class="col">
      <div style="display: flex" class="mb-3">
        <button @click="activeToggle" class="btn btn-success" :class="{'btn-dark': active }" style="margin-right: 16px;" type="button">{{ active ? 'Деактивировать' : 'Активировать'}}</button>

        <button v-if="!hasModifications" @click="yellowPriceToggle" type="button" class="btn me-3" :class="{'btn-warning': hasYellowPrice, 'btn-dark': !hasYellowPrice}">{{ (hasYellowPrice ? 'Снять' : 'Установить')  + ' жёлтый ценник' }}</button>

        <router-link :to="{name: 'reviewCreate', params: {product_id: id}}" class="me-3">
          <a href="" class="btn btn-success ">Создать отзыв</a>
        </router-link>

        <router-link :to="{name: 'productEdit', product_id: {product_id: id}}" class="me-3">
          <a href="" class="btn btn-secondary">Редактировать</a>
        </router-link>

        <button @click="remove" type="button" class="btn btn-danger">Удалить</button>
      </div>
        <table class="table caption-top table-bordered">
          <tbody>
            <tr>
              <th>id</th>
              <td>{{ id }}</td>
            </tr>
            <tr>
              <th>Наименование</th>
              <td>{{ name }}</td>
            </tr>

            <tr v-if="!hasModifications">
              <th>URL</th>
              <td>{{ url }}</td>
            </tr>
            <tr v-if="!hasModifications">
              <th>Складской учёт</th>
              <td>{{ inventoryControl ? 'Да' : 'Нет' }}</td>
            </tr>
            <tr>
              <th>Категория</th>
              <td>{{ category }}</td>
            </tr>
            <tr>
              <th>Рейтинг</th>
              <td>{{ rating }}</td>
            </tr>
            <tr v-if="!hasModifications">
              <th>Описание</th>
              <td>{{ description }}</td>
            </tr>
            <tr>
              <th>Имеет модификации</th>
              <td>{{ hasModifications ? 'Да' : 'Нет' }}</td>
            </tr>
            <tr v-if="!hasModifications">
              <th>Имеет жёлтый ценник</th>
              <td>{{ hasYellowPrice ? 'Да' : 'Нет' }}</td>
            </tr>
            <tr v-if="!hasModifications">
              <th>Наличие</th>
              <td>{{ availability ? 'Да' : 'Нет' }}</td>
            </tr>

            <tr v-if="!hasModifications">
              <th>Количество</th>
              <td>
                <div style="display:flex;justify-content: space-between;">
                  <span>{{ quantity }}</span>
                  <edit-btn-component @click-edit="showEditQuality = true"></edit-btn-component>
                </div>
              </td>
            </tr>
            <tr v-if="!hasModifications">
              <th>H1</th>
              <td>{{ h1 }}</td>
            </tr>
            <tr v-if="!hasModifications">
              <th>Seo Заголовок</th>
              <td>{{ metaTitle }}</td>
            </tr>
            <tr v-if="!hasModifications">
              <th>Seo Описание</th>
              <td>{{ metaDescription ?? '' }}</td>
            </tr>
            <tr v-if="!hasModifications">
              <th>Seo Ключевые слова</th>
              <td>{{ keywords ?? '' }}</td>
            </tr>
            <tr>
              <th>{{ hasModifications ? 'Цена модификации' : 'Цена'}}</th>
              <td>
                <div style="display:flex;justify-content: space-between;">
                  <span>{{ price }}</span>
                  <edit-btn-component v-if="!hasModifications" @click-edit="showChangePrice = true"></edit-btn-component>
                </div>
              </td>
            </tr>
            <tr v-if="!hasModifications">
                <th>Артикул</th>
                <td>{{ code }}</td>
            </tr>
            <tr v-if="!hasModifications">
                <th>Изображения</th>
                <td>
                    <div style="display: flex;margin-right: 11px;margin-bottom: 10px;">
                        <div v-for="photo in images" style="display: flex;margin-right: 10px;">
                            <img :src="imagesUrl + '/storage/' + photo.file" alt="" height="88" width="88" :class="{ 'main': Number(mainImageId) === photo.id }" style="margin-right: 4px;">
                            <div style="display:flex;flex-direction: column;">
                              <button @click="changeMainImage(photo.id)" type="button" class="icon btn-main" style="margin-bottom: 11px;"></button>
                              <button @click="removeImage(photo.id)" type="button" class="delete-btn"></button>
                            </div>
                        </div>
                    </div>
                    <button type="button" class="btn-add" @click="showAddImage = true"></button>
                </td>
            </tr>
            <tr v-if="hasModifications">
                <th>Свойства</th>
                <td>
                    <div style="display: flex">
                        <div v-for="property in properties">
                            <span>{{ property.name +', ' }}</span>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
              <th>Активна</th>
              <td>{{ active ? 'Да' : 'Нет' }}</td>
            </tr>
          </tbody>
        </table>

    </div>

  <div v-if="hasModifications" class="p-4" style="margin-top: 16px;border: 1px #718096 solid;">
    <router-link :to="{name: 'modificationCreate', params: {product_id: id} }" ><a class="btn btn-primary" href="">Новая модификация</a></router-link>
  </div>
  <div v-if="hasModifications" class="row">
    <div class="col-8">

      <modifications-table-component :modifications="modifications"></modifications-table-component>

    </div>
  </div>


  <overlay-component v-if="showEditQuality">
    <modal-component @click-change="setQuantity" @click-close="handel" :title="'Изменить количество'">
      <input-component :type="'number'" :label="'Остаток'" v-model="form.quantity"></input-component>
    </modal-component>
  </overlay-component>
  <change-price-component v-if="showChangePrice" :product-id="id" @close-change-price="showChangePrice = false"></change-price-component>
  <upload-file-component v-if="showAddImage" @upload="storeImage" @close-add-image="showAddImage = false"></upload-file-component>

</div>
</template>

<script>
import ModificationsTableComponent from "./modification/ModificationsTableComponent";
import UploadFileComponent from "../UploadFileComponent2";
import CreateModificationComponent from "../popups/CreateModificationComponent";
import ChangePriceComponent from "./ChangePriceComponent";
import AddBtnComponent from "../buttons/AddBtnComponent";
import EditBtnComponent from "../buttons/EditBtnComponent";
import InputComponent from "../form/InputComponent";
import ModalComponent from "../ModalComponent";
import OverlayComponent from "../common/OverlayComponent2";

export default {
  components: {
    InputComponent,
    ModalComponent,
    OverlayComponent,
    ChangePriceComponent,
    CreateModificationComponent, AddBtnComponent, EditBtnComponent, ModificationsTableComponent, UploadFileComponent},
  props: [
    'id',
    'name',
      'url',
    'description',
    'category',
    'hasModifications',
    'price',
    'hasYellowPrice',
    'code',
    'images',
    'modifications',
      'quantity',
      'inventoryControl',
    'properties', 'mainImageId', 'active', 'availability', 'h1', 'metaTitle', 'metaDescription', 'keywords', 'rating'
  ],
  data(){
    return{

      form: {
        quantity: this.quantity,
      },
      showAddImage: false,
      showCreateModification: false,
      showChangePrice: false,
      showEditQuality: false,
    }
  },
  computed: {
    message(){
      return this.$store.getters.message
    },
    imagesUrl(){
      return this.$store.getters['imagesUrl']
    }
  },
  methods: {

    handel(){
      this.showEditQuality = false
      this.form.quantity = this.quantity
    },
    async remove(){
      await this.$store.dispatch('products/remove', {product_id: this.id})
      if (!this.message) {
        await this.$router.push({name: 'products'})
      }
    },
    async storeImage(images){
      await images.forEach(image => {
        let form = new FormData();
        form.append('file', image)
        let obj = {
          product_id: this.id,
          form: form
        }
        this.$store.dispatch('products/storeImage', obj)
      })
    },

    removeImage(image_id){
      this.$store.dispatch('products/destroyImage', {product_id: this.id, image_id: image_id})
    },
    changeMainImage(image_id){
      this.$store.dispatch('products/changeMainImage', {product_id: this.id, image_id: image_id})
    },
    async activeToggle(){
      await this.$store.dispatch('products/toggleActive', {product_id: this.id});
    },
    async yellowPriceToggle(){
      let value = undefined;
      if (this.hasYellowPrice === null){
        value = true
      }else if (typeof this.hasYellowPrice === 'number'){
        value = !this.hasYellowPrice
      }
      await this.$store.dispatch('products/update', {product_id: this.id, yellow_price_tag: value });
    },

    async setQuantity(){
      await this.$store.dispatch('products/update', {product_id: this.id, quantity: this.form.quantity})
      this.handel();
    },
  },

  created() {
    this.form.quantity = this.quantity
  }
}
</script>

<style scoped>
.main{
  border: 2px solid green;
}
.icon{
  height: 30px;
  width: 30px;
  mask-image: url(../../assets/icon/delete_FILL0_wght400_GRAD0_opsz48.svg);
  mask-size: cover;
  background-color: #718096;
}
.btn-main{
  mask-image: url(../../assets/icon/home_FILL0_wght400_GRAD0_opsz48.svg);
}
.btn-add{
  width: 30px;
  height: 30px;
  mask-image: url(../../assets/icon/add_FILL0_wght400_GRAD0_opsz48.svg);
  mask-size: cover;
  background-color: #718096;
}
.delete-btn{
  height: 30px;
  width: 30px;
  mask-image: url(../../assets/icon/delete_FILL0_wght400_GRAD0_opsz48.svg);
  mask-size: cover;
  background-color: #718096;
}

</style>