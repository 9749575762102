<template>
  <loader-component v-if="loaded"></loader-component>
  <div v-else>
    <h1>Редактирование товара</h1>

    <div class="">
      <div class="col-2">
        <label class="form-label">Категории</label>
        <select class="form-select" v-model="category.id" v-on:change="uni(categories, category)">
          <option v-bind:value="''" selected>Выбрать категорию</option>
          <option v-for="category in categories" v-bind:value="category.id">{{ category.name }}</option>
        </select>
      </div>
      <div class="col-3">
        <label class="form-label">Бренд</label>
        <select class="form-select" v-model="brand.id" v-on:change="uni(brands, brand)">
          <option v-bind:value="''" selected>Выбрать бренд</option>
          <option v-for="brand in brands" v-bind:value="brand.id">{{ brand.title }}</option>
        </select>
      </div>
      <div class="col-3">
        <label class="form-label">Линия</label>
        <select class="form-select" v-model="line.id" v-on:change="uni(lines, line)">
          <option v-bind:value="''" selected>Выбрать линию</option>
          <option v-for="lineD in lines" v-bind:value="lineD.id">{{ lineD.title }}</option>
        </select>
      </div>

    </div>
    <div class="mt-3 mb-3">
      <div class="col-4">
        <label class="form-label">Наименование</label>
        <input v-model="name" type="text" class="form-control" v-bind:placeholder="'Наименование'" disabled>
      </div>

    </div>

    <div v-if="!product.has_modifications">
      <div class="mt-3 mb-3">
        <div class="col-4">
          <label class="form-label">URL</label>
          <input v-model="slug" type="text" class="form-control" v-bind:placeholder="''">
        </div>

      </div>
      <div class="col-2">
        <label class="form-label">Описание</label>
        <textarea v-model="description" id="" cols="50" rows="5"></textarea>
      </div>
      <div class="form-check mt-3">
        <label class="form-check-label" for="checkbox-a">Наличие</label>
        <input class="form-check-input" type="checkbox" v-model="availability" id="checkbox-a">
      </div>
      <div class="form-check mt-3">
        <label class="form-check-label" for="checkbox-b">Складской учёт</label>
        <input class="form-check-input" type="checkbox" v-model="inventory_control" id="checkbox-b">
      </div>
      <div class="col-4 mt-3">
        <label class="form-label">H1</label>
        <input v-model="h1" type="text" class="form-control" :placeholder="''">
      </div>

      <div class="col-4">
        <label class="form-label">Мета-Заголовок</label>
        <input v-model="meta_title" type="text" class="form-control" :placeholder="''">
      </div>
      <div class="col-2 mt-3 mb-3">
        <label class="form-label">Мета-Описание</label>
        <textarea v-model="meta_description" cols="50" rows="5"></textarea>
      </div>
      <div class="col-4">
        <label class="form-label">Ключевые слова</label>
        <input v-model="keywords" type="text" class="form-control" :placeholder="''">
      </div>
    </div>




    <div class="mt-4" v-if="!product.has_modifications">
      <div class="row">

        <div class="col-2">
          <label class="form-label">Артикул</label>
          <input v-model="code" class="form-control" type="text" placeholder="">
        </div>
      </div>
    </div>
    <div v-else >
      <h4 style="font-size: 18px;color:grey;">Свойства</h4>

      <div>
        <div class="form-check" v-for="property in properties">
          <input class="form-check-input" type="checkbox" v-model="selectedProperties" :value="property.id" :id="property.id">
          <label class="form-check-label" :for="property.id">
            {{ property.name }}
          </label>
        </div>
      </div>
    </div>
    <div class="col-2 mt-3">
      <button @click="update" type="button" class="btn btn-primary">Редактировать</button>
    </div>
  </div>

</template>

<script>
import LoaderComponent from "../../components/LoaderComponent";
export default {
  components: {LoaderComponent},
  data(){
    return {
      name: '',
      brand: {
        id: '',
        title: ''
      },
      category: {
        id: '',
        name: ''
      },
      line: {
        id: '',
        title: ''
      },
      description: '',
      code: '',
      selectedProperties: [],
      h1: '',
      meta_title: '',
      meta_description: '',
      keywords: '',
      availability: false,
      inventory_control: false,
      slug: ''
    }
  },
  computed:{
    loaded(){
      return this.$store.getters.loaded
    },
    categories(){
      return this.$store.getters['categories/items'];
    },
    brands(){
      return this.$store.getters['brands/items'];
    },
    lines(){
      return this.$store.getters['lines/items'];
    },
    properties(){
      return this.$store.getters['property/properties'];
    },
    product(){
      return this.$store.getters['products/item'];
    },
    dynamicName(){
      return this.category.name + ' ' + this.line.title + ' ' + this.brand.title
    }
  },
  watch: {
    dynamicName(){
      this.name = this.dynamicName
    }
  },
  methods: {
    uni(values, obj){
      for (let value of values){
        if (Number(value.id) === obj.id){
          if (value.hasOwnProperty('name')){
            obj.name = value.name
          }else {
            obj.title = value.title
          }
        }
      }
    },
    async update(){
      let object = {
        product_id: this.product.id,
          name: this.name,
          brand_id: this.brand.id,
          category_id: this.category.id,
          line_id: this.line.id,
          availability: this.availability,
          properties: this.selectedProperties,
      }

      if (!this.product.has_modifications){
        object.inventory_control = this.inventory_control
        object.description = this.description
        object.code = this.code
        object.h1 = this.h1
        object.meta_title = this.meta_title
        object.meta_description = this.meta_description
        object.keywords = this.keywords
        object.slug = this.slug
      }
      await this.$store.dispatch('products/update', object);
      if (!this.message) {
        await this.$router.push({name: 'product', params: {product_id: this.product.id}})
      }
    },
  },
  async created(){
    await this.$store.dispatch('products/item', {product_id: this.$route.params.product_id});
    await this.$store.dispatch('categories/items');
    await this.$store.dispatch('brands/items');
    await this.$store.dispatch('lines/items');
    await this.$store.dispatch('property/properties');

    this.name = this.product.name
    this.brand = this.product.brand
    this.category = this.product.category
    this.line = this.product.line
    this.description = this.product.description ?? ''
    this.availability = this.product.availability
    this.inventory_control = this.product.inventory_control
    this.h1 = this.product.h1 ?? ''
    this.meta_title = this.product.meta_title ?? ''
    this.meta_description = this.product.meta_description ?? ''
    this.keywords = this.product.keywords ?? ''
    this.code = this.product.code ?? ''
    this.slug = this.product.slug ?? ''

    this.product.properties.forEach(item => {
      this.selectedProperties.push(item.id)
    })
  }
}
</script>

<style scoped>

</style>
