import  http from "@/api/http";

export async function items(filtration) {
    let url = '/orders';
    let response = await http.get(url, {
        params: filtration
    });
    return response.data
}

export async function item(id) {
    let response = await http.get(`/orders/${id}`);
    return response.data.order
}

export async function update(order_id, data) {
    let response = await http.put(`/orders/${order_id}/update`, data);
    return response.data.order
}

export async function destroy(id) {
    let response = await http.delete(`/orders/${id}/destroy`);
}

export async function approve(id) {
    let response = await http.put(`/orders/${id}/approve`);
    return response.data.order
}

export async function sent(id) {
    let response = await http.put(`/orders/${id}/sent`);
    return response.data.order
}

export async function setTracker(id, data) {
    let response = await http.put(`/orders/${id}/set_tracker`, data);
    return response.data.order
}

export async function toComplete(id) {
    let response = await http.put(`/orders/${id}/to_complete`);
    return response.data.order
}

export async function cancel(id) {
    let response = await http.put(`/orders/${id}/cancel`);
    return response.data.order
}

export async function destroyOrderItem(order_id, data) {
    let response = await http.post(`/orders/${order_id}/order_items/${data.id}/destroy`, data);
    return response.data.order
}


export async function addOrderItem(id, data) {
    let response = await http.post(`/orders/${id}/order_items/add`, data);
    return response.data.order
}

export async function updateQuantity(order_id, data) {
    let response = await http.put(`/orders/${order_id}/order_items/${data.id}/quantity/update`, data);
    return response.data.order
}

export async function updateDeliveryCost(order_id, data) {
    let response = await http.put(`/orders/${order_id}/delivery_cost/update`, data);
    return response.data.order
}

export async function updateCost(order_id, data) {
    let response = await http.put(`/orders/${order_id}/cost/update`, data);
    return response.data.order
}

export async function destroyDiscount(order_id, data) {
    let response = await http.put(`/orders/${order_id}/discount/destroy`, data);
    return response.data.order
}

export async function destroyDeliveryDiscount(order_id, data) {
    let response = await http.put(`/orders/${order_id}/delivery_discount/destroy`, data);
    return response.data.order
}

export async function changeStatus(order_id, data) {
    let response = await http.put(`/orders/${order_id}/edit/status`, data);
    return response.data.order
}
export async function exportInFile() {
    let response = await http.get(`/orders/export/excel`);
    return response
}

export async function updatePriceOrderItem(order_id, data) {
    let response = await http.put(`/orders/${order_id}/order_items/${data.id}/price/update`, data);
    return response.data.order
}



