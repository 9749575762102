<template>
    <div class="row">
        <h1>Скидки</h1>
    </div>
    <form class="" action="" method="post">
        <h6 class="">Добавление скидки</h6>

        <div v-if="error !== undefined" style="background-color: red;padding: 10px;">
            <p style="color:#fff;font-size: 15px;">{{ error }}</p>
        </div>

        <div class="col-3 mt-3 mb-3">
            <input v-model="name" type="text" name="name" class="form-control" id="inlineFormInputGroupManufacturerName" placeholder="Наименование">
        </div>

        <div class="col-3 mt-3 mb-3">
          <select class="form-select" aria-label="Default select example" v-model="subType">
            <option v-for="type in properties.sub_types" :value="type.value">{{ type.name }}</option>
          </select>
        </div>
        <div class="col-3 mt-3 mb-3">
          <select class="form-select" aria-label="Default select example" v-model="type">
            <option v-for="type in properties.types" :value="type.value">{{ type.name }}</option>
          </select>
        </div>
        <div class="col-3 mt-3 mb-3">
            <input v-model="fromDate" type="date" name="from_date" class="form-control" placeholder="С">
        </div>
        <div class="col-3 mt-3 mb-3">
            <input v-model="toDate" type="date" name="to_date" class="form-control" placeholder="По">
        </div>
        <div class="col-3 mt-3 mb-3">
            <select class="form-select" aria-label="Default select example" v-model="active">
                <option selected value="1">Да</option>
                <option value="0">Нет </option>
            </select>
        </div>


        <div style="margin-top: 44px;">

          <div v-if="subType === 'personal'">
            <button @click="addItem" type="button" class="btn btn-secondary">+</button>
            <div class="col-3 mt-3 mb-3">
              <div v-for="(item, index) in personal" style="margin-bottom: 16px;display: flex;align-items: center;">
                <div>
                  <input v-model="personal[index].limit" type="text" class="form-control" placeholder="Лимит">
                  <input v-model="personal[index].percent" type="text" name="to_date" class="form-control" placeholder="Процент">
                </div>
                <button @click="deleteItem(personal.value)" type="button" class="btn btn-danger">-</button>
              </div>
            </div>
          </div>

          <div v-if="type === 'dynamic'">
            <div v-if="subType === 'for_product'">
              <div class="col-3 mt-3 mb-3">
                <input v-model="dynamicProduct" type="number" class="form-control" placeholder="Процент">
              </div>
            </div>
          </div>

          <div v-if="type === 'fixed'">
            <div v-if="subType === 'for_product'">
              <div class="col-3 mt-3 mb-3">
                <input v-model="fixedProduct" type="number" class="form-control" placeholder="Сумма">
              </div>
            </div>

            <div v-if="subType === 'for_delivery'">
              <div class="col-3 mt-3 mb-3">
                <input v-model="fixedDelivery" type="number" class="form-control" placeholder="Сумма">
              </div>
            </div>
          </div>


        </div>



        <div class="col-2">
            <button @click="add" type="button" class="btn btn-primary">Создать скидку</button>
        </div>
    </form>
</template>

<script>
import axios from "axios";
export default {
  data(){
      return{
          name: '',
          fromDate: '',
          toDate: '',
          active: '',
          type: '',
          subType: '',

          error: undefined,

          personal: undefined,
          fixedProduct: '',
          dynamicProduct: '',
          fixedDelivery: '',
      }
  },
  computed:{
    properties(){
      return this.$store.getters['discount/properties']
    }
  },
  methods: {
      async add(){
        let obj = {
          name: this.name,
          from_date: this.fromDate,
          to_date: this.toDate,
          active: this.active,
          type: this.type,
          subType: this.subType
        }
        if (this.subType === 'personal'){
          obj.options = {name: 'percent', value: this.personal}
        }else if (this.type === 'dynamic'){
          if (this.subType === 'for_product'){
            obj.options = {name: 'percent', value: this.dynamicProduct}
          }
        }else if (this.type === 'fixed') {
          if (this.subType === 'for_product') {
            obj.options = {name: 'fix', value: this.fixedProduct}
          } else if (this.subType === 'for_delivery') {
            obj.options = {name: 'fix', value: this.fixedDelivery}
          }
        }
        await this.$store.dispatch('discount/store', obj);
        this.$router.push({name: 'discounts'})
      },
      addItem(){
          let obj = {
            limit: '',
            percent: ''
          }
          if (this.personal === undefined){
            this.personal = [];
          }
          this.personal.push(obj)
      },
      deleteItem(value){
        let index = this.personal.findIndex(item => item.value === value)
        this.personal.splice(index, 1)
      }
  },
  async created() {
    await this.$store.dispatch('discount/types');
    this.type = this.properties.types[0].value;
    this.subType = this.properties.sub_types[0].value;
  }
}
</script>

<style scoped>

</style>
