<template>
    <div class="col-10">
        <div class="row">
            <h1>Редактирование линии</h1>
        </div>
        <form class="" method="post">
            <div class="col-3">
                <label class="form-label" for="inlineFormInputGroupManufacturerName">Бренды</label>
                <select class="form-select" v-model="brand">
                    <option v-for="item in brands" :value="item.id">{{ item.title }}</option>
                </select>
            </div>
            <div class="col-3 mt-3 mb-3">
                <label class="form-label" for="inlineFormInputGroupManufacturerName">Наименование</label>
                <input type="text" v-model="title" class="form-control" id="inlineFormInputGroupManufacturerName" placeholder="Наименование">
            </div>
            <div class="col-2">
                <button @click="update" type="button" class="btn btn-primary">Редактировать</button>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    data(){
        return{
            title: '',
            brand: ''
        }
    },
    computed:{
      brands(){
        return this.$store.getters['brands/items']
      },
      line(){
        return this.$store.getters['lines/item']
      },
    },
    methods: {
        async update(){
          await this.$store.dispatch('lines/update', {title: this.title, brand_id: this.brand, line_id: this.line.id})
          await this.$router.push({name: 'lines'})
        }
    },
    async created() {
      console.log(this.$route.params.id)
      await this.$store.dispatch('brands/items')
      await this.$store.dispatch('lines/item', { line_id: this.$route.params.id })
      this.title = this.line.title
      this.brand = this.line.brand_id

    }
}
</script>

<style scoped>

</style>
