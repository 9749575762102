<template>
    <div class="col">
        <h1>Создание товара</h1>

<!--        <form action="" method="post" enctype="multipart/form-data" class="row">-->
<!--            <h6 class="">Добавление продуктов из файла</h6>-->
<!--            <div class="col-3">-->
<!--                <input class="form-control" type="file" name="file">-->
<!--            </div>-->
<!--            <div class="col-auto">-->
<!--                <button type="submit" class="btn btn-primary">Добавить</button>-->
<!--            </div>-->
<!--        </form>-->
        <hr>

        <div class="">
            <create-product-component
                v-bind:categories="categories"
                v-bind:brands="brands"
                v-bind:lines="lines"
                v-bind:properties="properties"
            ></create-product-component>
        </div>

    </div>
</template>

<script>
import CreateProductComponent from "../../components/product/CreateProductComponent";
export default {
  components: {CreateProductComponent},
  computed:{
    categories(){
      return this.$store.getters['categories/subItems'];
    },
    brands(){
      return this.$store.getters['brands/items'];
    },
    lines(){
      return this.$store.getters['lines/items'];
    },
    properties(){
      return this.$store.getters['property/properties'];
    },
  },

  async created(){
    this.$store.dispatch('categories/items');
    this.$store.dispatch('brands/items');
    this.$store.dispatch('lines/items');
    this.$store.dispatch('property/properties');
  }
}
</script>

<style scoped>

</style>
