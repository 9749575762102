<template>
<!--  <loader-component v-if="loaded"></loader-component>-->
<div>
    <div class="">
        <h3>Витрины</h3>
    </div>
    <div class="mt-3">
        <router-link :to="{name: 'showcaseCreate'}" class="btn btn-primary me-3">Создать витрину</router-link>
      <button @click="actualize" type="button" class="btn btn-success">Актуализировать</button>
    </div>

    <div class="">
        <div class="">
            <table class="table caption-top table-bordered">
                <caption>Скидки</caption>
                <thead>
                  <tr>
                      <th scope="">#</th>
                      <th scope="">id</th>
                      <th style="">Наименование</th>
                      <th >Активна</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(showcase, i) in showcases">
                      <td>{{ i + 1 }}</td>
                      <td>{{ showcase.id }}</td>
                      <td>
                          <router-link :to="{name: 'showcase', params: {id: showcase.id}}">
                              <a href="">{{ showcase.name }}</a>
                          </router-link>
                      </td>
                      <td>{{ showcase.active ? 'Да' : 'Нет' }}</td>
                  </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
</template>

<script>
import LoaderComponent from "../../components/LoaderComponent";
export default {
  components: {LoaderComponent},
  computed: {
    loaded() {
      return this.$store.getters.loaded
    },
    showcases(){
        return this.$store.getters['showcase/showcases']
    }
  },
  methods: {
    actualize(){
      this.$store.dispatch('showcase/actualize');
    }
  },
    async created() {
        await this.$store.dispatch('showcase/items');
    }
}
</script>

<style scoped>

</style>
