<template>
    <div class="col-10">
        <div class="row">
            <h1>Линии</h1>
        </div>
        <form action="" method="post" enctype="multipart/form-data" class="row">
            <h6 class="">Добавление категории из файла</h6>
            <div class="col-3">
                <input class="form-control" type="file" name="file">
            </div>
            <div class="col-auto">
                <button type="submit" class="btn btn-primary">Добавить</button>
            </div>
        </form>
        <hr>
        <form class="" method="post">
            <h6 class="">Добавление линии</h6>
            <div class="col-3">
                <label class="form-label" for="inlineFormInputGroupManufacturerName">Бренды</label>
                <select class="form-select" v-model="brand">
                    <option :value="''" selected>Выбрать бренд</option>
                    <option v-for="brand in brands" :value="brand.id">{{ brand.title }}</option>
                </select>
            </div>
            <div class="col-3 mt-3 mb-3">
                <label class="form-label" for="inlineFormInputGroupManufacturerName">Наименование</label>
                <input type="text" v-model="title" class="form-control" id="inlineFormInputGroupManufacturerName" placeholder="Наименование">
            </div>
            <div class="col-2">
                <button @click="store" type="button" class="btn btn-primary">Создать</button>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    data(){
        return{
            title: '',
            brand: ''
        }
    },
    computed:{
      brands(){
        return this.$store.getters['brands/items']
      }
    },
    methods: {
        async store(){
          await this.$store.dispatch('lines/store', {title:this.title, brand_id: this.brand})
          await this.$router.push({name: 'lines'})

        }
    },
    async created() {
        this.$store.dispatch('brands/items')
    }
}
</script>

<style scoped>

</style>
