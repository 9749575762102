<template>
  <loader-component v-if="false"></loader-component>
  <div>
    <h1>Редактирование пользователя</h1>
    <form>
      <input-component v-model="user.name" :type="'text'" :label="'Имя'"></input-component>
      <input-component v-model="user.surname" :type="'text'" :label="'Фамилия'"></input-component>
      <input-component v-model="user.email" :type="'email'" :label="'E-mail'"></input-component>
      <input-component v-model="user.phone" :type="'tel'" :label="'Телефон'"></input-component>

      <div class="form-check">
        <input v-model="user.black_list" class="form-check-input" type="checkbox" id="flexCheckIndeterminate" style="cursor: pointer;">
        <label class="form-check-label" for="flexCheckIndeterminate">
          Чёрный список
        </label>
      </div>
      <div class="col-2 mt-3">
        <button @click="update" type="button" class="btn btn-primary">Редактировать</button>
      </div>
    </form>

  </div>
</template>

<script>
import LoaderComponent from "../../components/LoaderComponent";
import SelectComponent from "../../components/form/SelectComponent";
import InputComponent from "../../components/form/InputComponent";
export default {
  components: {InputComponent, SelectComponent, LoaderComponent},
  data(){
    return {
      user: {
        name: '',
        surname: '',
        email: '',
        phone: '',
        black_list: false,
      },
    }
  },
  computed: {
    message(){
      return this.$store.getters.message
    },
    item(){
      return this.$store.getters['users/item']
    },
  },
  methods: {
    async update(){
      await this.$store.dispatch('users/update', {id: this.item.id, user: this.user})
      if (!this.message){
        await this.$router.push({name: 'user', params: {id: this.item.id}})
      }
    },
  },
  async created() {
    await this.$store.dispatch('users/item', {id: this.$route.params.id})
    this.user.name = this.item.name
    this.user.surname = this.item.surname
    this.user.email = this.item.email
    this.user.phone = this.item.phone
    this.user.black_list = this.item.black_list === 1
  }
}
</script>

<style scoped>

</style>