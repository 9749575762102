<template>
  <div class="popup-create-sku" style="display:flex;background-color: rgba(0, 0, 0, 0.4);width: 100%;height: 100vh;position:fixed;top: 0;left: 0;justify-content: center;align-items: center">
    <div style="background-color: #fff;padding: 20px;">
      <button  @click="$emit('close-change-price')" type="button" class="btn btn-danger hide-create-sku" >Закрыть</button>

      <form action="" method="post" enctype="multipart/form-data">
        <div class="mt-4">
          <div class="mb-3">
            <input v-model="price" class="form-control" type="number" placeholder="Цена">
          </div>
        </div>

        <div class="col">
          <button @click="change" type="button" class="btn btn-primary">Изменить</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: ['productId', 'modificationId'],
  data(){
    return {
      price: ''
    }
  },
  methods:{
    async change(){
      await this.$store.dispatch('products/changePrice', {product_id: this.productId, modification_id: this.modificationId, price: this.price})
      this.$emit('close-change-price')
    }
  }
}
</script>

<style scoped>

</style>