<template>
  <table class="table caption-top table-bordered">
    <caption>Категория</caption>
    <tbody>
    <tr>
      <th>id</th>
      <td>{{ id }}</td>
    </tr>
    <tr>
      <th>Слаг</th>
      <td>{{ slug }}</td>
    </tr>
    <tr>
      <th>Наименование</th>
      <td>{{ name }}</td>
    </tr>
    <tr>
      <th>Родитель</th>
      <td>{{ parentName }}</td>
    </tr>
    <tr>
      <th>Активна</th>
      <td>{{ active ? 'Да' : 'Нет' }}</td>
    </tr>

    </tbody>
  </table>
</template>

<script>
export default {
  props:['id', 'name', 'parent', 'name', 'active', 'slug'],
  computed: {
    parentName(){
      if (this.parent === null){
        return 'Нет родителя'
      }else {
        return this.parent.name + ' (' + this.parent.id + ')'
      }
    }
  }
}
</script>

<style scoped>

</style>