import { createStore } from 'vuex'

import categories from "./modules/categories";
import products from "./modules/products";
import orders from "./modules/orders";
import review from "./modules/review";
import discount from "./modules/discount";
import property from "./modules/property";
import lines from "./modules/lines";
import brands from "./modules/brands";
import users from "./modules/users";
import showcase from "./modules/showcase";
import appeal from "./modules/appeal";
import seo from "./modules/seo";
import auth from "./modules/auth";
import search from "./modules/search";

import axios from "axios";
import router from "@/router";
import * as authApi from '@/api/auth';

const store = createStore({
  modules: {
    categories,
    products,
    orders,
    review,
    discount,
    property,
    lines,
    brands,
    users,
    showcase,
    appeal,
    seo,
    auth,
    search
  },
  state:{
    baseUrl: process.env.NODE_ENV === 'production' ? 'https://api.aribay.ru' : 'http://localhost:8000',
    imagesUrl: process.env.NODE_ENV === 'production' ? 'https://api.aribay.ru' : 'http://localhost:8000',
    user: null,
    message: '',
    loaded: false,
  },
  getters: {
    loaded(state){
      return state.loaded
    },
    isAuth(state){
      return state.user !== null
    },
    imagesUrl(state){
      return state.imagesUrl
    },
    baseUrl(state){
      return state.baseUrl
    },
    message(state){
      return state.message
    }
  },
  mutations:{
    loaded(state, value){
      state.loaded = value
    },
    user(state, user){
      state.user = user;
    },
    logout(state){
      state.user = null
    },
    message(state, message){
      state.message = message
    }
  },
  actions: {
    async user(store){
      let user = await authApi.user();
      store.commit('user', user)
    },

    async login(store, payload){
      return  axios.get('/sanctum/csrf-cookie', {
        headers: {
          'Accept': 'application/json',
        },
      }).then(() => {
        return axios.post('/admin/login', payload, {
          headers: {
            'Accept': 'application/json',
          },
        });
      }).then(response => {
        store.state.user = response.data.user;
        router.push({name: 'main'})
      });
    },

    async logout(store){
      await axios.post('/logout')
      store.commit('logout')
      await router.push({name: 'login'})
    }
  }
})

export default store
