import  http from "@/api/http";

export async function items() {
    let response = await http.get('/categories');
    return response.data.categories
}

export async function item(id) {
    let response = await http.get(`/categories/${id}`);
    return response.data.category
}

export async function store(id, data) {
    let response = await http.post(`/categories/${id}/store`, data);
    return response.data.category
}

export async function update(id, data) {
    let response = await http.put(`/categories/${id}/update`, data);
    return response.data.category
}

export async function destroy(id) {
    let response = await http.delete(`/categories/${id}/destroy`);
}

