<template>
  <div class="mt-5">
    <h2 style="font-size: 1.5rem;" class="">Добавление товара</h2>

    <form class="" action="" method="post">

      <div class="col-3 mt-3 mb-3" style="display:flex;align-items: center;">
        <label for="isMod" style="margin-right: 6px;">Является модификацией</label>
        <input v-model="is_modification" id="isMod" type="checkbox" name="sku" style="width: 22px;height: 22px;">
      </div>

      <div class="col-3 mt-3 mb-3">
        <input v-model="vendor" type="text" class="form-control" id="inlineFormInputGroupManufacturerName" placeholder="Артикул">
      </div>

      <div class="col-2">
        <button @click="add" type="button" class="btn btn-primary">Добавить</button>
      </div>

    </form>
  </div>
</template>

<script>
export default {
  data(){
    return {
      is_modification: false,
      vendor: '',
    }
  },
  computed: {
    message(){
      return this.$store.getters.message
    }
  },
  methods: {
    add(){
      this.$emit('add-item', {is_modification: this.is_modification, vendor: this.vendor})
      this.is_modification = false;
      this.vendor = ''
      if (this.message){

      }
    }
  }
}
</script>

<style scoped>

</style>