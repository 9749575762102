<template>
    <tr>
      <td>{{ n + 1 }}</td>
      <td>{{ id }}</td>
      <td>{{ modification_id === null ? '-' : modification_id }}</td>
      <td class="">
        <router-link :to="{name: route.name, params: route.params}">{{ name }}</router-link>
        <router-view></router-view>
      </td>
      <td>{{ line }}</td>
      <td>{{ category }}</td>
      <td>{{ vendor }}</td>
      <td>{{ availability ? 'Да' : 'Нет' }}</td>
      <td>{{ quantity }}</td>
      <td>{{ price }}</td>
      <td>
        <div style="display:flex;">
          <span style="margin-right: 8px;">{{ sort }}</span>
          <div style="width: 60%;display:flex;justify-content: space-between;">
            <button @click="up" type="button" class="up"></button>
            <button @click="down" type="button" class="down"></button>
          </div>
        </div>
      </td>
      <td>
        <form action="" method="post" style="margin: 0 auto;">
            <button @click="remove" type="button" class="btn btn-secondary delete-btn"></button>
        </form>
      </td>
    </tr>
</template>

<script>
import ModificationsTableComponent from "../modification/ModificationsTableComponent";
export default {
    components: {ModificationsTableComponent},
    props: [
      'id',
      'modification_id',
      'name',
      'brand',
      'line',
      'category','vendor', 'price', 'hasModifications', 'modifications', 'n', 'sort', 'availability', 'quantity'],
  computed: {
      route(){
        let obj = {
          name: 'product',
          params: {
            product_id: this.id
          }
        }
        if (this.hasModifications){
          obj.name = 'modification'
          obj.params.modification_id =  this.modification_id
        }
        return obj;
      }
  },
    methods: {
      remove(){
        this.$emit('remove-item', {vendor: this.vendor, is_modification: this.hasModifications})
      },
      up(){
        this.$emit('move-up', {id: this.modification_id ?? this.id, is_modification: this.hasModifications})
      },
      down(){
        this.$emit('move-down', {id: this.modification_id ?? this.id, is_modification: this.hasModifications})
      },
    }
}
</script>

<style scoped>
.delete-btn{
  height: 22px;
  width: 22px;
  mask-image: url(../../../assets/icon/delete_FILL0_wght400_GRAD0_opsz48.svg);
  mask-size: cover;
}
.up{
  height: 22px;
  width: 22px;
  mask-image: url(../../../assets/icon/north_FILL0_wght400_GRAD0_opsz48.svg);
  mask-size: cover;
  background-color: black;
}
.down{
  height: 22px;
  width: 22px;
  mask-image: url(../../../assets/icon/south_FILL0_wght400_GRAD0_opsz48.svg);
  mask-size: cover;
  background-color: black;
}
</style>
