import axios from "axios";

export default {
    namespaced: true,
    state: {
        properties: []
    },
    getters: {
        properties(state){
            return state.properties
        },
    },
    mutations: {
        properties(state, properties){
            state.properties = properties
        },
        store(state, property){
            state.properties.push(property)
        },
        destroy(state, id){
            let index = state.properties.findIndex(item => {
                return item.id === id
            })
            state.properties.splice(index,1)
        },
    },

    actions: {
        async properties(store){
            let response = await axios.get('/api/admin/properties');
            store.commit('properties', response.data.properties)
        },
        async store(store, payload){
            let response = await axios.post('/api/admin/properties/store', payload);
            store.commit('store', response.data.property)
        },
        async destroy(store, payload){
            await axios.delete(`/api/admin/properties/${payload.id}/destroy`).then(response  => {
                if (response.data.success){
                    store.commit('destroy', payload.id)
                }
            });

        },
    }
}
