import * as usersApi from "@/api/users";

export default {
    namespaced:  true,
    state: {
        pagination: [],
        items: [],
        item: undefined
    },

    getters: {
        pagination(state){
            return state.pagination
        },
        items(state){
            return state.items
        },
        itemsOthers(state){
            return state.items.filter(item => item.role === 'other')
        },
        item(state){
            return state.item
        },
    },

    mutations: {
        pagination(state, pagination){
            state.pagination = pagination
        },
        items(state, items){
            state.items = items
        },
        item(state, item){
            state.item = item
        },
        destroyAddress(state, id){
            let index = state.item.addresses.findIndex(address => address.id === id)
            state.item.addresses.splice(index, 1)
        },
    },

    actions:{
        async items(store, payload){
            let users = await usersApi.items(payload)
            store.commit('items', users)
            store.commit('loaded', false, {root: true})
        },
        async pagination(store, payload){
            let users = await usersApi.pagination(payload)
            store.commit('pagination', users)
            store.commit('loaded', false, {root: true})
        },
        async item(store, payload){
            let user = await usersApi.item(payload.id)
            store.commit('item', user)
            store.commit('loaded', false, {root: true})
        },
        async store(store, payload){
            let user = await usersApi.store(payload);
            store.commit('item', user)
            store.commit('loaded', false, {root: true})
        },
        async update(store, payload){
            let user = await usersApi.update(payload);
            store.commit('item', user)
            store.commit('loaded', false, {root: true})
        },
        async remove(store, payload){
            await usersApi.remove(payload.user_id);
            store.commit('loaded', false, {root: true})
        },
        async destroyAddress(store, payload){
            let id = await usersApi.destroyAddress(payload.address_id, payload);
            store.commit('destroyAddress', id)
            store.commit('loaded', false, {root: true})
        }
    }
}

