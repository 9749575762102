import http from "@/api/http";

export async function items(data) {
    let response = await http.get('/users', {params: data});
    return response.data.users
}
export async function pagination(data) {
    let response = await http.get('/users/pagination', {params: data});
    return response.data.users
}

export async function item(id) {
    let response = await http.get(`/users/${id}`);
    return response.data.user
}

export async function store(data) {
    let response = await http.post('/users/store', data);
    return response.data.user
}

export async function update(obj) {
    let response = await http.put(`/users/${obj.id}/update`, obj.user);
    return response.data.user
}

export async function remove(id) {
    let response = await http.delete(`/users/${id}/delete`);
    return response.data.user_id
}

export async function destroyAddress(id) {
    let response = await http.delete(`/delivery_addresses/${id}/destroy`);
    return response.data.address_id
}