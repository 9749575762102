<template>
  <loader-component v-if="loaded"></loader-component>
  <div v-else class="col">

    <div class="row">
      <h1>Редактирование категории</h1>
    </div>
    <hr>
    <form class="" action="" method="post">
      <div class="col-2">
        <label class="form-label">Категории</label>
        <select v-model="parent_id" class="form-select">
          <option v-for="category in categories" :value="category.id">{{ category.name }}</option>
        </select>
      </div>

      <div class="col-3 mt-3 mb-3">
        <div class="input-group">
          <input v-model="name" type="text" class="form-control" id="inlineFormInputGroupManufacturerSlug" placeholder="Наименование">
        </div>
      </div>
      <div class="col-3 mt-3 mb-3">
        <div class="input-group">
          <input v-model="slug" type="text" class="form-control" id="inlineFormInputGroupManufacturerName" placeholder="Наименование">
        </div>
      </div>

      <div class="col-2 mt-3">
        <button @click="update" type="button" class="btn btn-primary">Редактировать</button>
      </div>
    </form>
  </div>
</template>

<script>
import LoaderComponent from "../../components/LoaderComponent";
export default {
  components: {LoaderComponent},
  data(){
    return {
      name: '',
      parent_id: '',
      slug: '',
    }
  },
  computed:{
    loaded(){
      return this.$store.getters.loaded
    },
    categories(){
      return this.$store.getters['categories/items']
    },
    category(){
      return this.$store.getters['categories/item']
    },
    message(){
      return this.$store.getters.message
    }
  },
  methods:{
    async update(){
      let obj = {
        name: this.name,
        parent_id: this.parent_id,
        category_id: this.category.id,
        slug: this.slug,
      }
      await this.$store.dispatch('categories/update', obj)
      if (!this.message) {
        await this.$router.push({name: 'categories'})
      }
    },
  },
  async created() {
    await this.$store.dispatch('categories/item', this.$route.params.id)
    await this.$store.dispatch('categories/items')
    this.name = this.category.name
    this.parent_id = this.category.parent_id
    this.slug = this.category.slug
  }
}
</script>

<style scoped>

</style>