<template>
  <loader-component v-if="loaded"></loader-component>
  <div v-else class="col-10">

    <div class="row">
      <h1>Бренд</h1>
    </div>

    <div class="row">
      <div class="col-6">
        <table class="table caption-top table-bordered">
          <caption>Бренд</caption>
          <tbody>
          <tr>
            <th>id</th>
            <td>{{ brand.id }}</td>
          </tr>
          <tr>
            <th>Наименование</th>
            <td>{{ brand.title }}</td>
          </tr>
          <tr>
            <th>Описание</th>
            <td>{{ brand.description }}</td>
          </tr>
          <tr>
            <th>Обновлено</th>
            <td>{{ brand.updated_at }}</td>
          </tr>
          <tr>
            <th>Логотип</th>
            <td>
              <div v-if="brand.image" style="display: flex;margin-right: 11px;margin-bottom: 10px;">
                <div style="display: flex;margin-right: 10px;">

                  <img :src="imagesUrl + '/storage/' + brand.image.file" alt="" style="width: 100px;height: 100px;object-fit: contain;">

                  <div style="display:flex;flex-direction: column;">
                    <button @click="destroyImage" type="button" class="delete-btn"></button>
                  </div>
                </div>
              </div>
              <button v-else type="button" class="btn-add" @click="showAddImage = true"></button>
            </td>
          </tr>
          <tr>
            <th>Активна</th>
            <td>{{ brand.active ? 'Да' : 'Нет' }}</td>
          </tr>
          </tbody>
        </table>
        <div>
          <button @click="activeToggle" class="btn btn-success" :class="{'btn-dark': brand.active }" type="button">{{ brand.active ? 'Деактивировать' : 'Активировать'}}</button>

          <router-link :to="{name:'brandEdit',params: { id: brand.id }}">
            <a class="btn btn-secondary ms-3">Редактировать</a>
          </router-link>
          <button @click="destroy" type="button" class="btn btn-danger ms-3">Удалить</button>
        </div>
      </div>
    </div>

    <upload-file-component  v-if="showAddImage" @close-add-image="showAddImage = false" @upload="storeImage"></upload-file-component>


  </div>
</template>

<script>
import LoaderComponent from "../../components/LoaderComponent";
import UploadFileComponent from "../../components/UploadFileComponent2";
export default {
  components: {UploadFileComponent, LoaderComponent},
  data(){
    return {
      showAddImage: false
    }
  },
  computed:{
    loaded(){
      return this.$store.getters.loaded
    },
    brand(){
      return this.$store.getters['brands/item']
    },
    imagesUrl(){
      return this.$store.getters['imagesUrl']
    }
  },
  methods: {
    async destroy(){
      this.$store.dispatch('brands/destroy', this.brand.id)
      await this.$router.push({name: 'brands'})
    },
    async activeToggle(){
      await this.$store.dispatch('brands/update', {brand_id: this.brand.id, active: !this.brand.active});
    },
    async storeImage(image){
      image.forEach(i => {
        let form = new FormData();
        form.append('image', i)
        let obj = {
          brand_id: this.brand.id,
          form: form
        }
        this.$store.dispatch('brands/storeImage', obj)
      })
      this.showAddImage = false
    },
    async destroyImage(){
      let obj = {
        brand_id: this.brand.id,
        image_id: this.brand.image.id
      }
      this.$store.dispatch('brands/destroyImage', obj)
    },
  },

  async created() {
    await this.$store.dispatch('brands/item', {brand_id: this.$route.params.id})
  }
}
</script>

<style scoped>
.btn-add{
  width: 30px;
  height: 30px;
  mask-image: url(../../assets/icon/add_FILL0_wght400_GRAD0_opsz48.svg);
  mask-size: cover;
  background-color: #718096;
}
.delete-btn{
  height: 30px;
  width: 30px;
  mask-image: url(../../assets/icon/delete_FILL0_wght400_GRAD0_opsz48.svg);
  mask-size: cover;
  background-color: #718096;
}
</style>