import * as authApi from "@/api/auth";

export default {
    namespaced:  true,
    state: {
        user: null
    },

    getters: {
        user(state){
            return state.user
        },
        isAuth(state){
            return state.user !== null
        },
    },

    mutations: {
        login(state, user){
            state.user = user
        },
        logout(state){
            state.user = null
        },
    },

    actions:{
        async user(store){
            let user = await authApi.user();
            store.commit('user', user);
        },

        async login(store, payload){
            return  axios.get('/sanctum/csrf-cookie', {
                headers: {
                    'Accept': 'application/json',
                },
            }).then(() => {
                return axios.post('/admin/login', payload, {
                    headers: {
                        'Accept': 'application/json',
                    },
                });
            }).then(response => {
                store.state.user = response.data.user;
                router.push({name: 'main'})
            });
        },

        async logout(store){
            await axios.post('/logout')
            store.commit('logout')
            await router.push({name: 'login'})
        }
    }
}

