<template>
   <div class="col">
        <h1>Создание сео</h1>
        <hr>
      <div>
        <form class="" action="" method="post" enctype="multipart/form-data">

          <div class="col-2 mb-3">
            <label class="form-label">Уровень каталога</label>
            <select class="form-select" v-model="currentLevel">
              <option v-for="level in levels" :value="level.value">{{ level.name }}</option>
            </select>
          </div>

          <div class="">
            <div class="col-2">
              <label class="form-label">Категории</label>
              <select class="form-select" v-model="category_id">
                <option v-bind:value="''" selected>Выбрать категорию</option>
                <option v-for="category in categories" v-bind:value="category.id">{{ category.name }}</option>
              </select>
            </div>
            <div class="col-3">
              <label class="form-label">Бренд</label>
              <select class="form-select" v-model="brand_id">
                <option v-bind:value="''" selected>Выбрать бренд</option>
                <option v-for="brand in brands" :value="brand.id">{{ brand.title }}</option>
              </select>
            </div>

          </div>


          <div class="mt-3 mb-3">
            <div class="col-4">
              <label class="form-label">Заголовок</label>
              <input v-model="title" type="text" class="form-control" :placeholder="''">
            </div>
            <div class="col-2">
              <label class="form-label">Описание</label>
              <textarea v-model="description" cols="50" rows="5"></textarea>
            </div>

            <div class="col-4">
              <label class="form-label">Ключевые слова</label>
              <input v-model="keywords" type="text" class="form-control" :placeholder="''">
            </div>
            <div class="col-4">
              <label class="form-label">H1</label>
              <input v-model="h1" type="text" class="form-control" :placeholder="''">
            </div>

            <div class="form-check mt-3" v-if="brand_id === '' && category_id === ''">
              <input v-model="for_brand" class="form-check-input" type="checkbox" id="flexCheckIndeterminate">
              <label class="form-check-label" for="flexCheckIndeterminate">
                Для бренда
              </label>
            </div>


          </div>



          <div class="col-2 mt-3">
            <button @click="store" type="button" class="btn btn-primary">Создать</button>
          </div>
        </form>
      </div>

        <div class="">
        </div>

    </div>
</template>

<script>
import AddProductComponent from "@/components/product/CreateProductComponent";
export default {
  components: {AddProductComponent},
  data(){
    return {
      levels: [
        {
          name: 'Корень',
          value: 1
        },
        {
          name: 'Каталог категорий',
          value: 2
        },
        {
          name: 'Каталог подкатегорий',
          value: 3
        }
      ],
      currentLevel: undefined,
      brand_id: '',
      category_id: '',
      title: '',
      description: '',
      keywords: '',
      h1: '',
      for_brand: false,
    }
  },
  computed: {
    categories(){
      return this.$store.getters['category/allCategories'];
    },
    brands(){
      return this.$store.getters['brand/items'];
    },
    message(){
      return this.$store.getters.message
    },
    item(){
      return this.$store.getters['seo/item']
    },
  },
  methods: {
    async store(){
      let options = {
        brand_id: this.brand_id,
        category_id: this.category_id,
        description: this.description,
        title: this.title,
        h1: this.h1,
        keywords: this.keywords,
        for_brand: this.for_brand,
        level: this.currentLevel,
      }
      //if (this.brand_id === '' && this.category_id === ''){

      //}
      await this.$store.dispatch('seo/store', options);
      if (!this.message){
        await this.$router.push({name: 'seo', params: {id: this.item.id}})
      }
    },
  },

  async created(){
    this.currentLevel = this.levels[0].value
    this.$store.dispatch('category/categories');
    this.$store.dispatch('brand/items');

  }
}
</script>

<style scoped>

</style>
