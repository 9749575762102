<template>
<div style="margin:16px 0;">
  <div style="display: flex">
    <div style="margin-right: 11px;">Статус:</div>
    <div>
      <div>Менеджер: {{ status.user }}</div>
      <div>Покупатель: {{ statuses[status.seller] }}</div>
    </div>
  </div>
  <div>Время: {{ date }}</div>
</div>
</template>

<script>
export default {
  props: ['status', 'time'],
  computed: {
    date(){
      let date = new Date(this.time)
      let minute = date.getMinutes();
      if (minute < 10){
        minute = '0' + String(minute)
      }
      return date.getDate() +'.' + (date.getMonth() + 1)+ '.' +date.getFullYear() +' '+date.getHours() +':'+minute
    },

    statuses(){
      return this.$store.getters['orders/statuses']
    },
  }
}
</script>

<style scoped>

</style>