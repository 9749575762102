<template>
    <div class="row">
        <h1>Витрина</h1>
    </div>
    <form class="" action="" method="post">
        <h6 class="">Добавление витрины</h6>

<!--        <div v-if="error !== undefined" style="background-color: red;padding: 10px;">-->
<!--            <p style="color:#fff;font-size: 15px;">{{ error }}</p>-->
<!--        </div>-->

        <div class="col-3 mt-3 mb-3">
            <label for="name" class="form-label">Наименование</label>
            <input v-model="name" id="name" type="text" name="name" class="form-control" placeholder="Наименование">
        </div>

        <div class="col-3 mt-3 mb-3">
          <label for="active" class="form-label">Активировать</label>
            <select id="active" class="form-select" aria-label="Default select example" v-model="active">
                <option selected value="1">Да</option>
                <option value="0">Нет </option>
            </select>
        </div>

        <div class="col-2">
            <button @click="add" type="button" class="btn btn-primary">Создать витрину</button>
        </div>
    </form>
</template>

<script>
export default {
  data(){
      return{
          name: '',
          active: 0,
      }
  },
  methods: {
      async add(){
        let obj = {
          name: this.name,
          active: this.active,
        }

        await this.$store.dispatch('showcase/store', obj);
        this.$router.push({name: 'showcases'})
      },
  },
}
</script>

<style scoped>

</style>
