<template>
  <loader-component v-if="loaded"></loader-component>

  <div v-else class="row">

    <h1>Запись сео</h1>
    <hr>
    <seo-item-component  :id="item.id"
                         :title="item.title"
                         :category="item.category_id ?? 'Пусто'"
                         :brand="item.brand_id ?? 'Пусто'"
                         :description="item.description"
                         :keywords="item.keywords"
                         :h1="item.h1"
                         :updated-at="item.updated_at"
                         :with-brand="item.for_brand"
                         :level="item.level"
                         :active="item.active"
    ></seo-item-component>
  </div>
</template>

<script>
import LoaderComponent from "@/components/LoaderComponent";
import SeoItemComponent from "@/components/admin/seo/SeoItemComponent";
export default {
    components: {SeoItemComponent, LoaderComponent},
    computed: {
      loaded(){
        return this.$store.getters.loaded
      },
      item(){
          return this.$store.getters['seo/item']
      },
    },
    async created(){
        await this.$store.dispatch('seo/item', {id: this.$route.params.id})
    }
}
</script>

<style scoped>

</style>
