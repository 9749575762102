<template>
<div>
    <div class="col">
      <div class="mb-3">
        <button @click="yellowPriceToggle"
                type="button" class="btn me-3"
                :class="{'btn-warning': hasYellowPrice, 'btn-dark': !hasYellowPrice}"
        >{{ (hasYellowPrice ? 'Снять' : 'Установить')  + ' жёлтый ценник' }}</button>
        <button @click="activeToggle"
                class="btn btn-success me-3"
                :class="{'btn-dark': active }"
                type="button"
        >{{ active ? 'Деактивировать' : 'Активировать'}}</button>
        <router-link :to="{name: 'modificationEdit', params: {id: productId, modification_id: id}}" class="me-3">
          <a href="" class="btn btn-secondary">Редактировать</a>
        </router-link>
        <button @click="remove" type="button" class="btn btn-danger ">Удалить</button>
      </div>
        <table class="table caption-top table-bordered">
            <tbody>
            <tr>
                <th>id</th>
                <td>{{ id }}</td>
            </tr>
            <tr>
                <th>Наименование</th>
                <td>{{ name }}</td>
            </tr>

            <tr>
              <th>URL</th>
              <td>{{ slug }}</td>
            </tr>
            <tr>
              <th>Описание</th>
              <td>{{ description }}</td>
            </tr>
            <tr>
              <th>Имеет жёлтый ценник</th>
              <td>{{ hasYellowPrice ? 'Да' : 'Нет' }}</td>
            </tr>
            <tr>
            <th>Наличие</th>
              <td>{{ availability ? 'Да' : 'Нет' }}</td>
            </tr>

            <tr>
              <th>Складской учёт</th>
              <td>{{ inventoryControl ? 'Да' : 'Нет' }}</td>
            </tr>
            <tr>
              <th>Количество</th>
              <td>
                <div style="display:flex;justify-content: space-between;">
                  <span>{{ quantity }}</span>
                  <edit-btn-component @click-edit="showEditQuality = true"></edit-btn-component>
                </div>
              </td>
            </tr>
            <tr>
              <th>H1</th>
              <td>{{ h1 }}</td>
            </tr>
            <tr>
              <th>Seo Заголовок</th>
              <td>{{ metaTitle }}</td>
            </tr>

            <tr>
              <th>Seo Описание</th>
              <td>{{ metaDescription ?? '' }}</td>
            </tr>

            <tr>
              <th>Seo Ключевые слова</th>
              <td>{{ keywords ?? '' }}</td>
            </tr>
            <tr>
              <th>Цена</th>
              <td>
                <div style="display:flex;justify-content: space-between;">
                  <span>{{ price }}</span>
                  <edit-btn-component @click-edit="showChangePrice = true"></edit-btn-component>
                </div>
              </td>
            </tr>
            <tr>
                <th>Артикул</th>
                <td>{{ code }}</td>
            </tr>
            <tr>
              <th>Изображения</th>
              <td>
                <div style="display: flex;margin-right: 11px;margin-bottom: 10px;" v-if="images.length > 0">
                    <div v-for="image in images" style="display: flex;margin-right: 10px;">
                      <image-component :id="image.id"
                                       :file="image.file"
                                       :main-image-id="mainImageId"
                                       :sample-image-id="sampleImageId"
                                       :modification-id="id"
                                       :product-id="productId"
                      ></image-component>
                    </div>
                </div>
                <add-btn-component @click-add="showAddImage = true"></add-btn-component>
              </td>
            </tr>
            <tr>
              <th>Активна</th>
              <td>{{ active ? 'Да' : 'Нет' }}</td>
            </tr>
            </tbody>
        </table>

    </div>
  <overlay-component v-if="showEditQuality">
    <modal-component @click-change="setQuantity" @click-close="handel" :title="'Изменить количество'">
      <input-component :type="'number'" :label="'Остаток'" v-model="form.quantity"></input-component>
    </modal-component>
  </overlay-component>

  <change-price-component v-if="showChangePrice"
                          :product-id="id"
                          :modification-id="id"
                          @close-change-price="showChangePrice = false"
  ></change-price-component>
  <upload-file-component v-if="showAddImage" @upload="storeImage" @close-add-image="showAddImage = false"></upload-file-component>

</div>
</template>

<script>
import UploadFileComponent from "../../UploadFileComponent2";
import CreateModificationComponent from "./CreateModificationComponent";
import ChangePriceComponent from "../ChangePriceComponent";
import ModificationsTableComponent from "./ModificationsTableComponent";
import ImageComponent from "../../ImageComponent";
import OverlayComponent from "../../common/OverlayComponent2";
import ModalComponent from "../../ModalComponent";
import InputComponent from "../../form/InputComponent";
import AddBtnComponent from "../../buttons/AddBtnComponent";
import EditBtnComponent from "../../buttons/EditBtnComponent";

export default {
  components: {
    InputComponent,
    ModalComponent,
    OverlayComponent,
    EditBtnComponent,
    ImageComponent,
    AddBtnComponent,
    ModificationsTableComponent, ChangePriceComponent, CreateModificationComponent, UploadFileComponent
  },
  props: [
      'slug',
    'id',
    'productId',
    'name',
    'description',
    'price',
    'quantity',
    'code',
    'hasYellowPrice',
    'images',
      'inventoryControl',
    'mainImageId', 'sampleImageId', 'active', 'availability', 'metaTitle', 'metaDescription', 'keywords', 'h1'
  ],
  data(){
    return{
      form: {
        quantity: this.quantity,
      },
      showAddImage: false,
      showChangePrice: false,
      showEditQuality: false,
    }
  },
  computed: {
    message(){
      return this.$store.getters.message
    },
  },
  methods: {
    handel(){
      this.showEditQuality = false
      this.form.quantity = this.quantity
    },
    async storeImage(images){
      await images.forEach(image => {
        let form = new FormData();
        form.append('file', image)
        let obj = {
          modification_id: this.id,
          product_id: this.productId,
          form: form
        }
        this.$store.dispatch('products/storeModificationImage', obj)
      })
    },
    async remove(){
      await this.$store.dispatch('products/removeModification', {product_id: this.productId, modification_id: this.id})
      if (!this.message) {
        await this.$router.push({name: 'product', params: {product_id: this.productId}})
      }
    },
    async activeToggle(){
      let options = {
        product_id: this.productId,
        modification_id: this.id,
        active: !this.active,
      }
      await this.$store.dispatch('products/updateModification', options);
    },
    async yellowPriceToggle(){
      let value = undefined;
      if (this.hasYellowPrice === null){
        value = true
      }else if (typeof this.hasYellowPrice === 'number'){
        value = !this.hasYellowPrice
      }
      await this.$store.dispatch('products/updateModification', {product_id: this.productId, modification_id: this.id, yellow_price_tag: value });
    },
    async setQuantity(){
      await this.$store.dispatch('products/updateModification', {product_id: this.productId, modification_id: this.id, quantity: this.form.quantity})
      this.handel();
    },
  },
  created() {
    this.form.quantity = this.quantity
  }
}
</script>

<style scoped>

</style>