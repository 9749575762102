import axios from "axios";
import * as linesApi from '@/api/lines';

export default {
    namespaced:  true,
    state: {
        items: [],
        item: undefined
    },

    getters: {
        items(state){
            return state.items
        },
        item(state){
            return state.item
        },
    },

    mutations: {
        items(state, lines){
            state.items = lines
        },
        item(state, line){
            state.item = line;
        },
    },

    actions:{
        async items(store) {
            let lines = await linesApi.items()
            store.commit('items', lines)
            store.commit('loaded', false, {root: true})
        },
        async item(store, payload){
            let line = await linesApi.item(payload.line_id)
            store.commit('item', line)
            store.commit('loaded', false, {root: true})
        },
        async store(store, payload){
            console.log(payload)
            let line = await linesApi.store(payload)
            store.commit('item', line)
            store.commit('loaded', false, {root: true})
        },
        async update(store, payload){
            let line = await linesApi.update(payload.line_id, payload)
            store.commit('item', line)
            store.commit('loaded', false, {root: true})
        },
        async destroy(store, payload){
            let line = await linesApi.destroy(payload.line_id)
            store.commit('item', line)
            store.commit('loaded', false, {root: true})
        }
    }
}

