<template>
  <loader-component v-if="loaded"></loader-component>
  <div v-else class="container-fluid">
    <div class="row">
      <h4 class="">Заказ № {{ order.number }}</h4>
    </div>
    <div class="row row-cols-auto">
      <router-link :to="{ name: 'order', params: { id: order.id }}" class="btn btn-secondary">Назад</router-link>
      <button v-if="1" @click="destroyDiscount" type="button" class="btn btn-primary ms-3">Удалить скидку товара</button>
      <button v-if="1" @click="destroyDeliveryDiscount" type="button" class="btn btn-primary ms-3">Удалить скидку доставки</button>
      <button type="button" class="btn btn-secondary ms-3" data-bs-toggle="modal"  data-bs-target="#addOrderItem">Добавить товар</button>

    </div>
    <div class="row">
      <form action="">
      <table class="table align-middle">
        <caption class="caption-top">Товары</caption>
        <tbody>
        <tr>
          <td>
            <table class="table caption-top table-bordered">
              <thead>
              <tr>
                <th class="col-3">Наименование</th>
                <th>Артикул</th>
                <th>Цена</th>
<!--                <th>Скидка</th>-->
                <th>Количество</th>
                <th>Сумма</th>
                <th></th>
              </tr>
              </thead>
              <tbody class="align-middle">
              <order-item-component v-for="product in order.order_items"
                                    :order-id="order.id"
                                    :id="product.id"
                                    :quantity="product.quantity"
                                    :price="product.price"
                                    :discount="product.discount"
                                    :vendor="product.vendor"
                                    :product-name="product.product_name"
                                    :key="product.id"
              ></order-item-component>

              </tbody>
              <tr>
                <th>Стоимость доставки</th>
                <td>
                  <div style="display:flex;align-items: center;">
                    <div>
                      <span v-if="order.delivery_discount > 0" class="me-3 mr-3" style="font-weight: normal;text-decoration: line-through; color: #718096">{{ order.delivery_cost }}</span>
                      <span>{{ order.delivery_cost - order.delivery_discount }}</span>
                    </div>
                    <button type="button" class="icon btn-edit ms-3" data-bs-toggle="modal"  data-bs-target="#changeDeliveryCost">Изменить</button>
                  </div>
                </td>
              </tr>
              <tr>
                <th>Стоимость товаров</th>
                <td>
                  <div style="display:flex;align-items: center">
                    <div>
                      <span v-if="order.discount > 0" class="me-3" style="font-weight: normal;text-decoration: line-through; color: #718096">{{ order.cost }}</span>
                      <span >{{ order.cost - order.discount }}</span>
                    </div>
                    <button type="button" class="icon btn-edit ms-3" data-bs-toggle="modal" data-bs-target="#calcOrder"></button>
                  </div>
                </td>
              </tr>


<!--              <tr>-->
<!--                <th>Скидка</th>-->
<!--                <td>{{ order.discount }}</td>-->
<!--              </tr>-->


              <tr>
                <th>Итого</th>
                <td>
                  <div>
                    <span v-if="order.discount > 0 || order.delivery_discount > o" class="me-3" style="font-weight: normal;text-decoration: line-through; color: #718096">{{ Number(order.cost) + Number(order.delivery_cost) }}</span>
                    <span >{{ (order.cost - order.discount) + (order.delivery_cost - order.delivery_discount) }}</span>
                  </div>
                  </td>
              </tr>
            </table>
          </td>
        </tr>
        </tbody>
      </table>
    </form>
    </div>
    <overlay-component :id="'changeDeliveryCost'" :title="'Изменение стоимости доставки'">
      <div class="modal-body">
        <form>
          <div class="mb-3">
            <label for="recipient-name" class="col-form-label">Стоимость доставки:</label>
            <input v-model="deliveryCost" type="text" class="form-control" id="recipient-name">
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Нет</button>
        <button @click="updateDeliveryCost" type="button" class="btn btn-primary" data-bs-toggle="modal"  data-bs-target="#changeDeliveryCost">Да</button>
      </div>
    </overlay-component>

    <overlay-component :id="'addOrderItem'" :title="'Добавить товар в заказ'">
      <div class="modal-body">
        <form>
          <div class="form-check">
            <input v-model="is_modification" class="form-check-input" type="checkbox" id="flexCheckIndeterminate" style="cursor: pointer;">
            <label class="form-check-label" for="flexCheckIndeterminate">
              Является модификаций
            </label>
          </div>
          <div class="mb-3">
            <label for="vendor" class="col-form-label">Артикул товара:</label>
            <input v-model="vendor" type="text" class="form-control" id="vendor">
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Нет</button>
        <button @click="add" type="button" class="btn btn-primary" data-bs-toggle="modal"  data-bs-target="#addOrderItem">Да</button>
      </div>
    </overlay-component>

    <overlay-component :id="'calcOrder'" :title="'Рассчитать скидку заказа'">
      <discount-calculation-component :id="'calcOrder'" :value="order.cost" @apply="handel"></discount-calculation-component>
    </overlay-component>
  </div>

</template>
<script>
import OrderComponent from "../../components/OrderComponent";
import InputQuantityComponent from "../../components/InputQuantityComponent";
import OrderItemComponent from "../../components/order/edit/OrderItemComponent";
import LoaderComponent from "../../components/LoaderComponent";
import OverlayComponent from "../../components/OverlayComponent";
import Discount from "../discount/Discount";
import DiscountCalculationComponent from "../../components/DiscountCalculationComponent";
export default {
  components: {
    DiscountCalculationComponent,
    Discount, OverlayComponent, LoaderComponent, OrderItemComponent, InputQuantityComponent, OrderComponent},
  emits: ['apply'],
  data(){
    return {
      vendor: '',
      is_modification: false,
      deliveryCost: '',
      dataOrder: {
        cost: undefined,
        discount: 0,
      },
    }
  },
  computed: {
    loaded(){
      return this.$store.getters.loaded
    },
    order(){
      return this.$store.getters['orders/item']
    },
  },
  methods: {
    async updateDeliveryCost(){
      await this.$store.dispatch('orders/updateDeliveryCost', {order_id: this.order.id, delivery_cost: this.deliveryCost})
      this.deliveryCost = ''
    },
    async destroyDiscount(){
      let payload = {order_id: this.order.id}
      await this.$store.dispatch('orders/destroyDiscount', payload)
    },
    async destroyDeliveryDiscount(){
      let payload = {order_id: this.order.id}
      await this.$store.dispatch('orders/destroyDeliveryDiscount', payload)
    },
    async updateCost(obj){
      await this.$store.dispatch('orders/updateCost', {order_id: this.order.id, cost: obj.newValue,is_self_price: obj.hasOwnProperty('type')})
      this.dataOrder.cost = ''
    },
    async handel(obj){
        await this.updateCost(obj)
    },
    async add(){
      await this.$store.dispatch('orders/addOrderItem', {order_id: this.order.id, is_modification: this.is_modification, code: this.vendor})
      this.vendor = ''
      this.is_modification = false
    },
  },

  async created() {
    await this.$store.dispatch('orders/item', this.$route.params.id)
    this.tracker = this.order.tracker
  },
}
</script>
<style scoped>
.icon{
  height: 20px;
  width: 20px;
  mask-size: contain;
  background-color: #718096;
}
.btn-edit{
  mask-image: url(../../assets/icon/edit_FILL0_wght400_GRAD0_opsz48.svg);
}
</style>
