import  http from "@/api/http";

export async function items() {
    let response = await http.get('/reviews');
    return response.data.reviews
}

export async function item(id) {
    let response = await http.get(`/reviews/${id}`);
    return response.data.review
}

export async function store(data) {
    let response = await http.post(`/reviews/create`, data);
    return response.data.review
}

export async function update(id, data) {
    let response = await http.put(`/reviews/${id}/update`, data);
    return response.data.review
}

export async function approve(id) {
    let response = await http.put(`/reviews/${id}/approve`);
    return response.data.review
}

export async function destroy(id) {
    let response = await http.delete(`/reviews/${id}/delete`);
}

export async function reply(id, data) {
    let response = await http.put(`/reviews/${id}/reply`, data);
    return response.data.review
}