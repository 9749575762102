<template>
  <div class="container-fluid" style="">
    <button @click="showMenu = true" type="button" class="btn btn-primary ms-3" style="position: absolute;top: 12px;right: 12px;">Меню</button>

    <div class="">
      <router-view></router-view>
    </div>


    <div class="" v-if="showMenu" style="width: 100%;height: 100vh;position: absolute;top: 0;background-color: white;">
      <menu-left-component @hide-menu="showMenu = false"></menu-left-component>
    </div>
  </div>
</template>

<script>
import MenuLeftComponent from "../components/MenuLeftComponent";
export default {
  components: { MenuLeftComponent },
  data(){
    return {
      showMenu: false,
    }
  }
}
</script>

<style scoped>

</style>