<template>
  <tr>
    <td>{{ i + 1  }}</td>
    <td>{{ id }}</td>
    <td>{{ name }}</td>
    <td>
      <button type="button" class="delete-btn" @click="destroy"></button></td>
  </tr>
</template>

<script>
export default {
  props:['id', 'name', 'i'],
  methods:{
    destroy(){
      this.$store.dispatch('property/destroy',{id:this.id})
    }
  },
}
</script>

<style scoped>

.delete-btn{
  height: 22px;
  width: 22px;
  mask-image: url(../assets/icon/delete_FILL0_wght400_GRAD0_opsz48.svg);
  mask-size: cover;
  background-color: #5c636a;
}
</style>