<template style="position: relative;">
    <ul class="nav flex-column" style="border-right: 1px solid #000;">

        <li v-for="item in menu" class="nav-item">
          <router-link @click="$emit('hide-menu')" :to="{name: item.component}" class="nav-link">{{ item.name }}</router-link>
          <ul v-if="item.hasOwnProperty('child')" class="" style="list-style: none;">
            <li v-for="item in item.child" class="nav-item">
              <router-link @click="$emit('hide-menu')" :to="{name: item.component}" class="nav-link">{{ item.name }}</router-link>
            </li>
          </ul>
        </li>

      <li class="nav-item">
        <a @click.prevent="logout" class="nav-link" style="color: red; cursor: pointer;text-decoration: none;">Выйти</a>
      </li>

    </ul>
  <button @click="$emit('hide-menu')" type="button" class="btn btn-secondary ms-3" style="position: absolute;top: 16px;right: 16px;">Закрыть</button>

</template>

<script>
export default {
  data(){
    return {
      menu: [
        {
          name: 'Главная',
          component: 'main'
        },
        {
          name: 'Категории',
          component: 'categories'
        },
        {
          name: 'Бренды',
          component: 'brands'
        },
        {
          name: 'Линейки',
          component: 'lines'
        },
        {
          name: 'Товары',
          component: 'products',
          child: [
            {
              name: 'Желтый ценник',
              component: 'yellow'
            },
            {
              name: 'Свойства',
              component: 'properties'
            },
            {
              name: 'Фид',
              component: 'feed'
            },
            {
              name: 'Обработка изображений',
              component: 'imageProcessing'
            },
          ]
        },
        {
          name: 'Скидки',
          component: 'discounts'
        },
        {
          name: 'Заказы',
          component: 'orders'
        },
        {
          name: 'Пользователи',
          component: 'users'
        },
        {
          name: 'Витрины',
          component: 'showcases'
        },
        {
          name: 'Отзывы',
          component: 'reviews'
        },
        {
          name: 'Обращения',
          component: 'appeals'
        },
        {
          name: 'Рассылка',
          component: 'mailing'
        },
        {
          name: 'СЕО для каталога',
          component: 'seoList'
        },
        {
          name: 'Поиск',
          component: 'search'
        }
      ]
    }
  },
  methods: {
    logout(){
      this.$store.dispatch('logout')
      this.$router.push({name:'login'})
    }
  }
}
</script>

<style scoped>

</style>
