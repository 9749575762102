<template>
    <div>
        <form class="" action="" method="post" enctype="multipart/form-data">

            <div class="col-2">
                <label class="form-label">Категории</label>
                <select class="form-select" v-model="category.id" v-on:change="uni(categories, category)">
                    <option v-bind:value="''" selected>Выбрать категорию</option>
                    <option v-for="category in categories" v-bind:value="category.id">{{ category.name }}</option>
                </select>
            </div>
            <div class="col-3 mt-3 mb-3">
                <label class="form-label">Бренд</label>
                <select class="form-select" v-model="brand.id" @change="uni(brands, brand)">
                    <option v-bind:value="''" selected>Выбрать бренд</option>
                    <option v-for="brand in brands" :value="brand.id">{{ brand.title }}</option>
                </select>
            </div>
            <div class="col-3">
                <label class="form-label">Линия</label>
                <select class="form-select" v-model="line.id" v-on:change="uni(lines, line)">
                    <option v-bind:value="''" selected>Выбрать линию</option>
                    <option v-for="lineD in lines" v-bind:value="lineD.id">{{ lineD.title }}</option>
                </select>
            </div>

            <div class="col-4 mt-3 mb-3">
                <label class="form-label">Наименование</label>
                <input v-model="name" type="text" class="form-control" :placeholder="''" disabled>
            </div>

            <div class="form-check">
                <input v-model="has_modifications" class="form-check-input" type="checkbox" id="flexCheckIndeterminate" style="cursor: pointer;">
                <label class="form-check-label" for="flexCheckIndeterminate">
                    Имеет модификации
                </label>
            </div>


        <div class="mt-3" v-if="!has_modifications">

          <div class="col-2">
            <label class="form-label">Описание</label>
            <textarea v-model="description" id="" cols="50" rows="3"></textarea>
          </div>
            <div class="col-4 mt-3 mb-3">
              <label class="form-label">H1</label>
              <input v-model="h1" type="text" class="form-control" :placeholder="''">
            </div>
            <div class="col-4">
              <label class="form-label">Мета-Заголовок</label>
              <input v-model="meta_title" type="text" class="form-control" :placeholder="''">
            </div>
            <div class="col-2 mt-3 mb-3">
              <label class="form-label">Мета-Описание</label>
              <textarea v-model="meta_description" cols="50" rows="1"></textarea>
            </div>

            <div class="col-4">
              <label class="form-label">Ключевые слова</label>
              <input v-model="keywords" type="text" class="form-control" :placeholder="''">
            </div>

            <div class="row mt-3">
                <div class="col-2">
                  <label class="form-label">Артикул</label>
                    <input v-model="code" class="form-control" type="text" placeholder="">
                </div>
                <div class="col-2">
                  <label class="form-label">Цена</label>
                    <input v-model="price" class="form-control" type="number" placeholder="">
                </div>
            </div>
          </div>

        <div v-else class="mt-3">
            <h4 style="font-size: 18px;color:grey;">Выберите свойства</h4>

            <div>
                <div class="form-check" v-for="property in properties">
                    <input class="form-check-input" type="checkbox" v-model="selectedProperties" :value="property.id" :id="property.id">
                    <label class="form-check-label" :for="property.id">
                        {{ property.name }}
                    </label>
                </div>
            </div>
        </div>

            <div class="col-2 mt-3">
                <button @click="store" type="button" class="btn btn-primary">Создать товар</button>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    props: ['brands', 'categories', 'lines', 'properties'],
    data(){
        return {
          name: '',
          brand: {
                id: '',
              name: ''
            },
            category: {
                id: '',
              name: ''
            },
            line: {
                id: '',
                name: ''
            },
            has_modifications: false,
            description: '',
            code: '',
            price: '',
            selectedProperties: [],
            h1: '',
            meta_title: '',
            meta_description: '',
            keywords: ''
        }
    },
    computed: {
      message(){
        return this.$store.getters.message
      },
      dynamicName(){
          return this.category.name + ' ' + this.line.name + ' ' + this.brand.name
      }
    },
  watch: {
    dynamicName(){
      this.name = this.dynamicName
    }
  },
    methods: {
        async store(){
            let options = {
              name: this.name,
              brand: this.brand.id,
              category: this.category.id,
              line: this.line.id,
              additionalField: this.additionalField,
              has_modifications: this.has_modifications,
              properties: this.selectedProperties
            }
            if (!this.has_modifications){
              options.description = this.description
              options.code = this.code
              options.price = this.price
              options.h1 = this.h1
              options.meta_title = this.meta_title
              options.meta_description = this.meta_description
              options.keywords = this.keywords
            }
            await this.$store.dispatch('products/store', options);
          if (!this.message) {
            await this.$router.push({name: 'products'})
          }
        },
        uni(values, obj){
            for (let value of values){
                if (Number(value.id) === obj.id){
                  if (value.hasOwnProperty('name')){
                    obj.name = value.name
                  }else {
                    obj.name = value.title
                  }
                }
            }
        },
    },
}
</script>

<style scoped>

</style>
