<template>
<!--  <loader-component v-if="loaded"></loader-component>-->
  <div class="col">
    <h1>Пользователи</h1>
    <div class="col-auto">
      <router-link :to="{name: 'userCreate'}" class="btn btn-primary">Создать пользователя</router-link>
      <a :href="url + '/api/admin/users/export/excel' + query" class="btn btn-primary ms-3">Выгрузить</a>
    </div>


    <div class="row row-cols-auto mb-3">
      <div class="col">
        <label for="start" class="form-label">С</label>
        <input @change="filter" v-model="filtration.start" type="date" class="form-control" id="start" placeholder="">
      </div>
      <div class="col">
        <label for="finish" class="form-label">По</label>
        <input @change="filter" v-model="filtration.finish"  type="date" class="form-control" id="finish" placeholder="">
      </div>

      <div class="col">
        <label for="search" class="form-label">Поиск</label>
        <input @input="filter" v-model="filtration.search"  type="text" class="form-control" id="search" placeholder="">
      </div>

      <button @click="clear" type="button" class="btn btn-secondary col ms-3">Сбросить</button>

    </div>


    <div class="row">
      <div class="col-8" style="position: relative;">

        <table class="table">

          <thead>
          <tr>
            <th>№</th>
            <th>id</th>
            <th class="col-4">Имя</th>
            <th class="col-4">Фамилия</th>
            <th class="col">Создан</th>
            <th class="col-3">Телефон</th>
            <th class="col-2">E-mail</th>
            <th class="col-2">Роль</th>
            <th class="col-2">Статус</th>
            <th class="col-2">Сумма</th>
          </tr>
          </thead>


          <tbody v-if="!loaded">
            <tr v-for="(user, i) in pagination.data">
              <td>{{ i + 1 }}</td>
              <td>{{ user.id }}</td>
              <td class="col-3">
                <router-link :to="{ name: 'user', params: {id: user.id} }"><a href="">{{ user.name }}</a></router-link>
              </td>
              <td class="col-3">{{ user.surname }}</td>
              <td>{{ getDate(user.created_at) }}</td>
              <td>{{ user.phone }}</td>
              <td>{{ user.email }}</td>
              <td>{{ user.role }}</td>
              <td>{{ user.status }}</td>
              <td>{{ user.amount_of_purchases }}</td>

            </tr>
          </tbody>
        </table>
        <preloader-local-component v-if="loaded"></preloader-local-component>

      </div>
    </div>
    <pagination-component :links="pagination.links" :current="pagination.current_page" :last="pagination.last_page" @select="filter"></pagination-component>
  </div>
</template>

<script>
import LoaderComponent from "../../components/LoaderComponent";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import {ref} from 'vue'
import PreloaderLocalComponent from "../../components/PreloaderLocalComponent";
export default {
  components: {PreloaderLocalComponent, PaginationComponent, LoaderComponent},
  setup(props){
    const filtration = ref( {
      start: '',
      finish: '',
      search: ''
    })
    return {
      filtration
    }
  },
  data(){
    return {
      page: undefined
    }
  },
  computed: {
    query(){
      let a = this.$route.query;
      delete a.page;
      let str = '?'
      for (let key in a){
        str += key + '=' + a[key] + '&'
      }
      return str;
    },
    url(){
      return this.$store.getters.baseUrl
    },
    loaded(){
      return this.$store.getters.loaded
    },
    params(){
      let params = {};
      for (let key in this.filtration){
        if (this.filtration[key]) {
          Object.defineProperty(params, key, {enumerable: true, writable: true, value: this.filtration[key]})
        }
      }
      if (this.page){
        params.page = this.page
      }
      return params;
    },
    pagination(){
      return this.$store.getters['users/pagination']
    },
  },
  methods: {
    filter(page){
      let a = this.params;
      if (typeof page === 'number'){
        a.page = page
      }
      this.$router.push({path: 'users', query: a})
      this.$store.dispatch('users/pagination', a);
    },
    getDate(datetime){
      if (datetime !== null){
        let date = new Date(datetime)
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let hours = date.getHours();
        let minute = date.getMinutes();
        if (month < 10){
          month = '0' + String(month)
        }
        if (day < 10){
          day = '0' + String(day)
        }
        if (hours < 10){
          hours = '0' + String(hours)
        }
        if (minute < 10){
          minute = '0' + String(minute)
        }
        return day +'.' + month+ '.' +date.getFullYear() +' '+hours +':'+minute
      }
      return ''
    },
    clear(){
      this.filtration.start = ''
      this.filtration.finish = ''
      this.filtration.search = ''
      this.filtration.page = ''
      this.filter()
    }
  },
  created() {
    let object = {};
    for (let key in this.$route.query){
      if (this.$route.query.hasOwnProperty(key)){
        Object.defineProperty(object, key, {enumerable: true, writable: true, value: this.$route.query[key]})
      }
    }
    this.filtration = object
    this.$store.dispatch('users/pagination', this.$route.query);
  },
}
</script>

<style scoped>

</style>
