<template>
  <loader-component v-if="loaded"></loader-component>

  <div v-else class="container">
    <div class="row">
      <h1>Категория</h1>
    </div>
    <div class="row row-cols-auto">
      <button @click="activeToggle" class="btn btn-success" :class="{'btn-dark': category.active }" type="button">{{ category.active ? 'Деактивировать' : 'Активировать'}}</button>
      <router-link :to="{name: 'categoryEdit', params: {id: category.id}}">
        <a class="btn btn-secondary ms-3">Редактировать</a>
      </router-link>
        <button @click="destroy" type="button" class="btn btn-danger ms-3">Удалить</button>
    </div>

    <div class="row">
      <div class="col">
      <category-table-component :id="category.id"
                                :name="category.name"
                                :parent="category.parent"
                                :active="category.active"
                                :description="category.description"
                                :keywords="category.keywords"
                                :h1="category.h1"
                                :slug="category.slug"
      ></category-table-component>
      </div>
    </div>
  </div>
</template>

<script>

import CategoryTableComponent from "../../components/CategoryTableComponent";
import LoaderComponent from "../../components/LoaderComponent";

export default {
  components: {CategoryTableComponent, LoaderComponent},
  computed:{
    loaded(){
      return this.$store.getters.loaded
    },
    category(){
      return this.$store.getters['categories/item']
    }
  },
  methods:{
    async destroy(){
      await this.$store.dispatch('categories/destroy', {category_id: this.category.id})
      await this.$router.push({name: 'categories'})
    },

    async activeToggle(){
      await this.$store.dispatch('categories/update', {category_id: this.category.id, active: !this.category.active});
    }
  },
  async created() {
    await this.$store.dispatch('categories/item', this.$route.params.id)
  }
}
</script>

<style scoped>

</style>