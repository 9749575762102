<template>
<div>
    <div class="">
        <h1>Скидки</h1>
    </div>
    <div class="">
        <a href="" class="btn btn-primary">Выгрузить</a>
    </div>
    <div class="mt-3">
        <router-link :to="{name: 'discount-create'}">
            <a href="" class="btn btn-primary">Создать скидку</a>
        </router-link>
    </div>

    <div class="">
        <div class="">
            <table class="table caption-top table-bordered">
                <caption>Скидки</caption>
                <thead>
                  <tr>
                      <th scope="">#</th>
                      <th scope="">id</th>
                      <th style="">Наименование</th>
                      <th style="">Процент</th>
                      <th scope="">С</th>
                      <th >По</th>
                      <th >Активна</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(discount, i) in discounts">
                      <td>{{ i + 1 }}</td>
                      <td>{{ discount.id }}</td>
                      <td>
                          <router-link :to="{name: 'discount', params: {id: discount.id}}">
                              <a href="">{{ discount.name }}</a>
                          </router-link>
                      </td>
                      <td>{{ discount.percent }}</td>
                      <td>{{ discount.from_date }}</td>
                      <td>{{ discount.to_date }}</td>
                      <td>{{ discount.active ? 'Да' : 'Нет' }}</td>
                  </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data(){
        return{
            n: 1
        }
    },
    computed: {
        discounts(){
            return this.$store.getters['discount/discounts']
        }
    },
    async created() {
        await this.$store.dispatch('discount/discounts');
    }
}
</script>

<style scoped>

</style>
