<template>
  <div>
    <table class="table caption-top table-bordered">
      <tbody>
      <tr>
        <th>id</th>
        <td>{{ id }}</td>
      </tr>

      <tr>
        <th>Номер</th>
        <td>{{ number }}</td>
      </tr>
      <tr>
        <th>Решено</th>
        <td>
          {{ solved ? 'Да' : 'Нет' }}
        </td>
      </tr>

      <tr>
        <th>Тема</th>
        <td>{{ theme }}</td>
      </tr>
<!--      <tr>-->
<!--        <th>Изображения</th>-->
<!--        <td>-->
<!--          <div style="display:flex;">-->
<!--            <image-preview-component v-for="(image, index) in images"-->
<!--                                     :path="baseUrl + image.file"-->
<!--                                     :image="index"-->
<!--                                     @show-viewer="a"-->
<!--                                     :id="image.id"-->
<!--            ></image-preview-component>-->
<!--          </div>-->
<!--        </td>-->
<!--      </tr>-->
      <tr>
        <th>Пользователь</th>
        <td>
          <router-link :to="{name: 'user', params: {id: user.id}}">{{ user.name + ' ' + user.surname }}</router-link>
        </td>
      </tr>
      <tr>
        <th>Email</th>
        <td>{{ user.email }}</td>
      </tr>

      </tbody>
    </table>

<!--    <table v-if="response !== null" class="table caption-top table-bordered">-->
<!--      <tbody>-->
<!--        <tr>-->
<!--          <th>Ответ</th>-->
<!--          <td>{{ response.text }}</td>-->
<!--        </tr>-->
<!--        <tr>-->
<!--          <th>Изображения</th>-->
<!--          <td>-->
<!--            <div v-if="response.images.length > 0" style="display:flex;">-->
<!--              <image-preview-component v-for="(image, index) in response.images"-->
<!--                                       :path="baseUrl + image.file"-->
<!--                                       :image="index"-->
<!--                                       @show-viewer="a"-->
<!--                                       :id="image.id"-->
<!--              ></image-preview-component>-->
<!--            </div>-->
<!--            <span v-else>Нет изображении</span>-->
<!--          </td>-->
<!--        </tr>-->

<!--        <tr>-->
<!--          <th>Дата ответа</th>-->
<!--          <td><date-component :date="response.created_at"></date-component></td>-->
<!--        </tr>-->
<!--      </tbody>-->
<!--    </table>-->


  </div>
</template>

<script>
import DateComponent from "../components/common/DateComponent";
import ViewerComponent from "../components/ViewerComponent";
import ImagePreviewComponent from "../components/common/ImagePreviewComponent";
import MessageComponent from "./MessageComponent";
export default {
  components: {MessageComponent, DateComponent, ImagePreviewComponent, ViewerComponent},
  props: ['id', 'number', 'createdAt', 'user', 'theme', 'solved'],
  data(){
    return {
      showViewer: false,
      image_id: '',
      type: '',
    }
  },

  computed: {

  },

  methods: {
    a(id){
      this.image_id = id
      this.showViewer = true
    },
  },
}
</script>

<style scoped>

</style>