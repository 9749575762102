<template>
  <div>
    <div class="row">
      <h1 style="font-size: 28px;">Фид</h1>
    </div>
    <div class="col-auto">
      <button @click="updateFeed" class="btn btn-primary">Обновить фид</button>
      <a :href="url" target="_blank" style="margin-left: 16px;">Посмотреть фид</a>
    </div>

  <table class="table">
    <thead>
    <tr>
      <th>№</th>
      <th>ID</th>
      <th class="col-1">Изображение</th>
      <th class="">Наименование</th>
      <th class="">Категория</th>
      <th class="">Бренд</th>
      <th class="">Цена</th>
      <th class="">Артикул</th>
      <th class="">Описание</th>
      <th class="">Модификация</th>
    </tr>
    </thead>
    <tbody>
      <FeedTableRowComponent v-for="(offer, index) in offers"
                             :n="index + 1"
                             :id="offer.id"
                             :name="offer.name"
                             :vendor="offer.vendor"
                             :category="offer.category"
                             :has-modifications="offer.has_modifications"
                             :image="offer.image"
                             :brand="offer.brand"
                             :price="offer.price"
                             :description="offer.description"
                             :modification_id="offer.modification_id"
                             :product_id="offer.product_id"
      ></FeedTableRowComponent>
    </tbody>
  </table>
  </div>
</template>

<script>
import FeedTableRowComponent from "../components/feed/FeedTableRowComponent";
export default {
  components: {FeedTableRowComponent},
  computed: {
    url(){
      return this.$store.getters.imagesUrl + '/storage/feed.xml'
    },
    offers(){
      return this.$store.getters['products/offers']
    }
  },
  methods: {
    async updateFeed(){
      await this.$store.dispatch('products/generateFeed')
    }
  },
  async created(){
    await this.$store.dispatch('products/offers')
  }
}
</script>

<style scoped>

</style>