<template>
  <h3>Главная страница</h3>

</template>

<script>
export default {

}
</script>

<style scoped>
</style>
