import http from "@/api/http";

export async function items() {
    let response = await http.get('/appeals');
    return response.data.appeals
}

export async function item(id) {
    let response = await http.get(`/appeals/${id}`);
    if (response.data.hasOwnProperty('appeal')){
        return response.data.appeal
    }
}

export async function storeMassage(id, data) {
    let response = await http.post(`/appeals/${id}/messages/store`, data);
    if (response.data.hasOwnProperty('appeal')) {
        return response.data.appeal
    }
}

export async function deleteMassage(id, data) {
    let response = await http.delete(`/appeals/${id}/messages/delete`, {data: data});
    if (response.data.hasOwnProperty('appeal')) {
        return response.data.appeal
    }
}

export async function editMassage(id, data) {
    let response = await http.put(`/appeals/${id}/messages/edit`, data);
    if (response.data.hasOwnProperty('appeal')) {
        return response.data.appeal
    }
}

export async function toggleSolved(id) {
    let response = await http.put(`/appeals/${id}/solved`);
    if (response.data.hasOwnProperty('appeal')) {
        return response.data.appeal
    }
}

export async function destroy(data) {
    let response = await http.delete(`/appeals/${data.appeal_id}/destroy`);
    return response.data
}