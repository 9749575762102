<template>
  <button  @click="$emit('click-add')" type="button" class="icon btn-add"></button>
</template>

<script>
export default {
  name: "EditBtnComponent"
}
</script>

<style scoped>
.icon{
  height: 30px;
  width: 30px;
  mask-size: cover;
  background-color: #718096;
}
.btn-add{
  mask-image: url(../../assets/icon/add_FILL0_wght400_GRAD0_opsz48.svg);
}
</style>