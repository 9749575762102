import axios from "axios";
import * as brandsApi from '@/api/brands'

export default {
    namespaced:  true,
    state: {
        items: [],
        item: undefined
    },

    getters: {
        items(state){
            return state.items
        },
        item(state){
            return state.item
        },
    },

    mutations: {
        items(state, items){
            state.items = items
        },
        item(state, item){
            state.item = item;
        },
    },

    actions:{
        async items(store){
            let brands = await brandsApi.items()
            store.commit('items', brands)
            store.commit('loaded', false, {root: true})
        },
        async item(store, payload){
            let brand = await brandsApi.item(payload.brand_id)
            store.commit('item', brand)
            store.commit('loaded', false, {root: true})
        },
        async store(store, payload){
            let brand = await brandsApi.store(payload)
            store.commit('item', brand)
            store.commit('loaded', false, {root: true})
        },
        async update(store, payload){
            let brand = await brandsApi.update(payload.brand_id, payload);
            store.commit('item', brand)
            store.commit('loaded', false, {root: true})
        },
        async destroy(store, payload){
            await brandsApi.destroy(payload)
            store.commit('loaded', false, {root: true})
        },
        async storeImage(store, payload){
            let brand = await brandsApi.storeImage(payload.brand_id, payload.form)
            store.commit('item', brand)
            store.commit('loaded', false, {root: true})
        },
        async destroyImage(store, payload){
            let brand = await brandsApi.destroyImage(payload.brand_id, payload)
            store.commit('item', brand)
            store.commit('loaded', false, {root: true})
        }
    }
}

