<template>
<div class="quantity">
  <button @click="decrease" class="btn" :style="{ width: size + 'rem', height: size + 'rem' }">-</button>
  <input type="text" v-model="quantity" class="quantity-input" disabled>
  <button @click="increase" class="btn" :style="{ width: size + 'rem', height: size + 'rem' }">+</button>
</div>
</template>

<script>
export default {
  props: ['currentQuantity', 'size'],
  data(){
    return {
      quantity: 1
    }
  },
  watch: {
    currentQuantity(){
      this.quantity = this.currentQuantity
    }
  },
  methods: {
    increase() {
      this.quantity++;
      this.fireEventQuantity()
    },
    decrease() {
      if (this.quantity !== 1){
        this.quantity--;
        this.fireEventQuantity()
      }
    },
    fireEventQuantity(){
      this.$emit('quantity', this.quantity)
    }
  },
  created() {
    if (this.currentQuantity !== undefined){
      this.quantity = this.currentQuantity
    }
  }
}
</script>

<style scoped>
.quantity{
  display: flex;
}
button{
  font-size: 1.1rem;
  padding: 10px;
}
.btn{
  border: none;
  color: white;
  font-size: 1rem;
  background-color: #0b5ed7;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
}
.quantity-input{
  border: none;font-size: 1rem;font-weight: 500;width: 3em;text-align: center;
}
@media (max-width: 991px) {
  .quantity{
  }
}
</style>
