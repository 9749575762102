import http from "@/api/http";

export async function sendMail(data){
    let response = await http.post('/mailing/email/send', data)
    return response.data
}

export async function updateUsers(){
    let response = await http.get('/mailing/users/update')
    return response.data
}

export async function sendOut(data){
    let response = await http.post('/mailing/send_out', data)
    return response.data
}