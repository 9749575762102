<template>
    <tr>
        <td>{{ id }}</td>
        <td>{{ name }}</td>
        <td>{{ vendor }}</td>
        <td>{{ price }}</td>
    </tr>
</template>

<script>
export default {
  props: ['id', 'productId', 'name', 'vendor', 'price'],
}
</script>
<style scoped></style>