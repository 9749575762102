<template>
  <div class="" id="exampleModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">{{ title }}</h1>
          <button @click="$emit('click-close')" type="button" class="btn-close"></button>
        </div>
        <div class="modal-body">
            <slot></slot>
        </div>
        <div class="modal-footer">
          <button @click="$emit('click-close')" type="button" class="btn btn-secondary">Закрыть</button>
          <button @click="$emit('click-change')" type="button" class="btn btn-primary">Изменить</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', '']

}
</script>

<style scoped>

</style>