<template>
  <div class="preloader">
    <spinner-component :size="22" :color="'#5c636a'" :style="position"></spinner-component>
  </div>
</template>

<script>
import SpinnerComponent from "./SpinnerComponent";
export default {
  components: {SpinnerComponent},
  props: ['center'],
  computed: {
    position(){
      if (this.center){
        return {
          alignItems: 'center',
          justifyContent: 'center'
        }
      }else {
        return {
          marginTop: '0px'
        }
      }
    }
  },
}
</script>

<style scoped>
.preloader{
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 8;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 300px;
}
</style>
