<template>
  <div class="overlay">Loading...</div>

</template>

<script>
export default {
  computed: {

  }
}
</script>

<style scoped>
.overlay{
  width: 100%;
  height: 100vh;
  display:flex;
  justify-content: center;
  align-items: center;font-size: 22px;background-color:rgba(0,0,0,0.5);color:#fff;position: absolute;top: 0;left: 0;z-index: 1;
}
</style>