<template>
  <div class="col">

    <div class="row">
      <h1>Категории</h1>
    </div>

    <form action="" method="post" enctype="multipart/form-data" class="row">
      <h6 class="">Добавление категории из файла</h6>
      <div class="col-3">
        <input class="form-control" type="file" name="file">
      </div>
      <div class="col-auto">
        <button type="submit" class="btn btn-primary">Добавить</button>
      </div>
    </form>
    <hr>
    <form class="" action="" method="post">
      <h6 class="">Добавление категории</h6>
      <div class="col-2">
        <label class="form-label">Категории</label>
        <select v-model="parent_id" class="form-select">
          <option :value="''" >Выбрать категорию</option>
          <option v-for="category in categories" :value="category.id">{{ category.name }}</option>
        </select>
      </div>
      <div class="col-3 mt-3 mb-3">
          <label class="form-label">Наименование</label>
          <input v-model="name" type="text" class="form-control" id="inlineFormInputGroupManufacturerName" placeholder="">
      </div>

<!--      <div class="col-4">-->
<!--        <label class="form-label">Мета-Заголовок</label>-->
<!--        <div style="border: 1px solid #718096;padding: 10px;border-radius: 5px">-->

<!--        <div class="">-->
<!--          <input v-model="meta_title" type="text" class="form-control" placeholder="">-->
<!--        </div>-->
<!--        <div class="">-->
<!--          <label class="form-label brand-name">С бредом</label>-->
<!--          <input v-model="meta_title_brand" type="text" class="form-control" placeholder="">-->
<!--        </div>-->
<!--      </div>-->

<!--      </div>-->

<!--      <div class="col-4">-->
<!--        <label class="form-label">Мета-Описание</label>-->

<!--        <div style="border: 1px solid #718096;padding: 8px 10px;border-radius: 5px">-->

<!--          <div class="">-->
<!--            <textarea v-model="meta_description" cols="43" rows="2"></textarea>-->
<!--          </div>-->
<!--          <div class="col-2">-->
<!--            <label class="form-label brand-name">С бредом</label>-->
<!--            <textarea v-model="meta_description_brand" cols="43" rows="2"></textarea>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="col-4">-->
<!--        <label class="form-label">Ключевые слова</label>-->

<!--        <div style="border: 1px solid #718096;padding: 8px 10px;border-radius: 5px">-->
<!--          <div class="">-->
<!--            <input v-model="keywords" type="text" class="form-control" placeholder="">-->
<!--          </div>-->
<!--          <div class="">-->
<!--            <label class="form-label brand-name">С бредом</label>-->
<!--            <input v-model="keywords_brand" type="text" class="form-control" placeholder="">-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="col-4">-->
<!--        <label class="form-label">H1</label>-->

<!--        <div style="" class="border-group">-->
<!--          <div class="">-->
<!--            <input v-model="h1" type="text" class="form-control" placeholder="">-->
<!--          </div>-->
<!--          <div class="">-->
<!--            <label class="form-label brand-name"></label>-->
<!--            <input v-model="h1_brand" type="text" class="form-control" placeholder="">-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

      <div class="col-2 mt-3">
        <button @click="store" type="button" class="btn btn-primary">Добавить</button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data(){
    return{
      name: '',
      parent_id: '',
      meta_title: '',
      meta_title_brand: '',
      meta_description: '',
      meta_description_brand: '',
      keywords: '',
      keywords_brand: '',
      h1: '',
      h1_brand: ''
    }
  },
  computed:{
    categories(){
      return this.$store.getters['categories/items']
    },
    message(){
      return this.$store.getters.message
    }
  },
  methods:{
    async store(){
      let obj = {
        name: this.name,
        parent_id: this.parent_id,
        // meta_title: this.meta_title,
        // meta_title_brand: this.meta_title_brand,
        // meta_description: this.meta_description,
        // meta_description_brand: this.meta_description_brand,
        // keywords: this.keywords,
        // keywords_brand: this.keywords_brand,
        // h1: this.h1,
        // h1_brand: this.h1_brand
      }
      await this.$store.dispatch('categories/store', obj)

      if (!this.message){
        await this.$router.push({name: 'categories'})

      }
    },
  },
  async created() {
    await this.$store.dispatch('categories/items')
  }
}
</script>

<style scoped>
.border-group{
  border: 1px solid #ced4da;padding: 14px 10px;border-radius: 5px
}
.brand-name{
  font-size: 14px;
}
</style>