<template>
<div style="height: 100vh;width: 100%;display:flex;justify-content: center;align-items: center">
  <form class="col-2" style="">
    <div class="mb-3">
      <label for="exampleInputEmail1" class="form-label">Email address</label>
      <input v-model="email" type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
      <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div>
    </div>
    <div class="mb-3">
      <label for="exampleInputPassword1" class="form-label">Password</label>
      <input v-model="password" type="password" class="form-control" id="exampleInputPassword1">
    </div>
    <button @click="login" type="button" class="btn btn-primary">Submit</button>
  </form>
</div>
</template>

<script>
export default {
  data(){
    return{
      email: '',
      password: '',
    }
  },
  methods:{
    async login(){
      await this.$store.dispatch('login',{email:this.email,password:this.password})
    }
  }
}
</script>

<style scoped>

</style>