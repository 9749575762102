<template>
    <div>

        <div class="row">
            <h1>Линии</h1>
        </div>
        <div class="col-auto">
            <a class="btn btn-primary">Выгрузить</a>
        </div>

        <div class="col-auto mt-3">
            <router-link :to="{name: 'lineCreate'}" class="btn btn-primary">
                <a>Создать линию</a>
            </router-link>
        </div>

        <div class="row">
            <div class="col-9">
                <table class="table caption-top table-bordered">
                    <caption>Линии</caption>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>id</th>
                        <th>Бренд</th>
                        <th>Наименование</th>
                        <th>Перевод</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="line in lines">
                        <td>{{ 4 }}</td>
                        <td>{{ line.id }}</td>
                        <td>{{ line.brand.title }}</td>
                        <td>
                            <router-link :to="{name: 'line', params: {id: line.id}}">{{ line.title }}</router-link>
                        </td>
                        <td>{{ line.translate }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  computed:{
    lines(){
      return this.$store.getters['lines/items']
    }
  },
  async created() {
    await this.$store.dispatch('lines/items')
  }
}
</script>

<style scoped>

</style>
