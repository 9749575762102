import axios from "axios";
import * as showcasesApi from "@/api/showcases";
import {actualize} from "../../api/showcases";

export default {
    namespaced:  true,
    state: {
        showcases: [],
        current: {
            showcase: undefined,
            items: []
        }
    },

    getters: {
        showcases(state){
            return state.showcases
        },
        showcase(state){
            return state.current.showcase
        },
        items(state){
            return state.current.items
        },
    },

    mutations: {
        showcases(state, showcases){
            state.showcases = showcases
        },
        showcase(state, data){
            state.current.showcase = data.showcase;
            state.current.items = data.items;
        },
        update(state, showcase){
            state.current.showcase = showcase;
        },
        addInShowcase(state, product){
            if (!product.hasOwnProperty('product_id')){
                state.current.items.push({ product: product, modifications: [] })
            }else {
                let index = state.current.items.findIndex(item => {
                    return item.product.id === product.product_id
                })
                if (index !== -1){
                    state.current.items[index].modifications.push(product)
                }else {
                    let obj = {
                        product: product.product ,
                        modifications: [product]
                    }
                    state.current.items.push(obj)
                }
            }
        },
        removeFromShowcase(state, payload){
            let index = state.current.items.findIndex(item => {
                return item.product.id === payload.product_id
            })
            if (payload.modification_id === null){
                state.current.items.splice(index, 1)
            }else {
                let index2 = state.current.items[index].modifications.findIndex(modification => {
                    return modification.id === payload.modification_id
                })
                state.current.items[index].modifications.splice(index2, 1)
                if (state.current.items[index].modifications.length < 1){
                    state.current.items.splice(index, 1)
                }
            }
        },
        up(state, id){
            let item = state.current.items.find(i => i.id === id);
            let item2 = state.current.items.find(i => i.sort === item.sort - 1);
            item2.sort = item.sort
            item.sort = item.sort - 1;
            state.current.items = state.current.items.sort((a, b) => {
                return a.sort - b.sort
            })

        },
        down(state, id){
            let item = state.current.items.find(i => i.id === id);
            let item2 = state.current.items.find(i => i.sort === item.sort + 1);
            item2.sort = item.sort
            item.sort = item.sort + 1;
            state.current.items = state.current.items.sort((a, b) => {
                return a.sort - b.sort
            })

        }
    },

    actions:{
        async items(store){
            let showcases = await showcasesApi.items();
            store.commit('showcases', showcases)
            store.commit('loaded', false, {root: true})
        },
        async showcase(store, payload){
            store.commit('loaded', true, {root: true})
            await axios.get(`/api/admin/showcases/${payload.showcase_id}`).then(response => {
                if (response.data.success){
                    store.commit('showcase', response.data)
                }else {
                    store.commit('message', response.data.message, {root: true})
                }
            }).catch(() => {

            }).finally(() => {
                store.commit('loaded', false, {root: true})
            })

        },
        async store(store, payload){
            let response = await axios.post('/api/admin/showcases/store', payload);
            // await store.commit('showcase', response.data)
        },
        async update(store, payload){
            let response = await axios.put(`/api/admin/showcases/${payload.showcase_id}/update`, {name: payload.name, active:payload.active});
            await store.commit('update', response.data.showcase)
        },
        async actualize(store, payload){
            let response = await showcasesApi.actualize();
            //await store.commit('update', response.data.showcase)
            store.commit('loaded', false, {root: true})
        },
        async remove(store, payload){
            let url = `/api/admin/showcases/${payload.showcase_id}/remove`;
            let response = await axios.delete(url);
        },
        async activeToggle(store, payload){
            let response = await axios.put(`/api/admin/showcases/${payload.showcase_id}/active`);
            await store.commit('update', response.data.showcase)
        },
        async addInShowcase(store, payload){
            store.commit('loaded', true, {root: true})
            let data =  {
                is_modification: payload.is_modification,
                vendor: payload.vendor
            }
            await axios.post(`/api/admin/showcases/${payload.showcase_id}/products/add`, data)
                .then(response => {
                    if (response.data.success){
                        store.dispatch('showcase', {showcase_id: store.getters.showcase.id})
                        //store.commit('addInShowcase', response.data.item)
                    }else {
                        store.commit('message', response.data.message, {root: true})
                    }
                }).catch(() => {

                }).finally(() => {
                    store.commit('loaded', false, {root: true})
                })
        },
        async removeFromShowcase(store, payload){
            store.commit('loaded', true, {root: true})

            let url = `/api/admin/showcases/${store.getters.showcase.id}/items/remove`;
            await axios.delete(url, {
                data: {
                    product_id: payload.product_id,
                    modification_id: payload.modification_id
                }
            }).then(response => {
                if (response.data.success){
                    store.dispatch('showcase', {showcase_id: store.getters.showcase.id})
                    //store.commit('removeFromShowcase', {product_id: payload.product_id, modification_id: payload.modification_id})
                }else {
                    store.commit('message', response.data.message, {root: true})
                }
            }).catch(() => {

            }).finally(() => {
                store.commit('loaded', false, {root: true})
            })
        },
        async up(store, payload){
            store.commit('loaded', true, {root: true})
            await axios.put(`/api/admin/showcases/${store.getters.showcase.id}/items/${payload.item_id}/up`).then(response => {
                if (response.data.success){
                    store.commit('up', payload.item_id)
                }else {
                    store.commit('message', response.data.message, {root: true})
                }
            }).catch(() => {

            }).finally(() => {
                store.commit('loaded', false, {root: true})
            })
        },
        async down(store, payload){
            store.commit('loaded', true, {root: true})
            console.log(store)
            await axios.put(`/api/admin/showcases/${store.getters.showcase.id}/items/${payload.item_id}/down`).then(response => {
                if (response.data.success){
                    store.commit('down', payload.item_id)
                }else {
                    store.commit('message', response.data.message, {root: true})
                }
            }).catch(() => {

            }).finally(() => {
                store.commit('loaded', false, {root: true})
            })
        }
    }
}

