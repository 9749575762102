<template>
    <div>
        <form class="" action="" method="post" enctype="multipart/form-data">

<!--            <div class="col-4">-->
<!--                <label class="form-label">Наименование</label>-->
<!--                <input v-model="modification.name" type="text" class="form-control" :placeholder="''">-->
<!--            </div>-->
            <div class="col-2 mt-3 mb-3">
                <label class="form-label">Описание</label>
                <textarea v-model="modification.description" id="" cols="50" rows="3"></textarea>
            </div>

          <div>
            <h4 style="font-size: 18px;color:grey;">Свойства</h4>
            <div>

              <div v-for="(property, index) in props" class="col-4">
                <label class="form-label">{{ property.name }}</label>
                <input v-model="modification.properties[index].value" type="text" class="form-control" :placeholder="''">
              </div>
            </div>
          </div>


            <div class="col-4 mt-3 mb-3">
              <label class="form-label">H1</label>
              <input v-model="modification.h1" type="text" class="form-control" :placeholder="''">
            </div>
            <div class="col-4">
              <label class="form-label">Мета-Заголовок</label>
              <input v-model="modification.meta_title" type="text" class="form-control" :placeholder="''">
            </div>
            <div class="col-2 mt-3 mb-3">
              <label class="form-label">Мета-Описание</label>
              <textarea v-model="modification.meta_description" cols="50" rows="1"></textarea>
            </div>

            <div class="col-4">
              <label class="form-label">Ключевые слова</label>
              <input v-model="modification.keywords" type="text" class="form-control" :placeholder="''">
            </div>

            <div class="row mt-3">
                <div class="col-2">
                  <label class="form-label">Артикул</label>
                    <input v-model="modification.code" class="form-control" type="text" placeholder="">
                </div>
                <div class="col-2">
                  <label class="form-label">Цена</label>
                    <input v-model="modification.price" class="form-control" type="number" placeholder="">
                </div>
            </div>
            <div class="col-2 mt-4">
                <button @click="store" type="button" class="btn btn-primary">Создать модификацию</button>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    components: {},
    props: ['productId', 'props'],
    data(){
        return {
          modification: {
            description: '',
            code: '',
            price: '',
            h1: '',
            meta_title: '',
            meta_description: '',
            keywords: '',
            properties: []
          }
        }
    },
    computed: {
      message(){
        return this.$store.getters.message
      },
    },
    methods: {
        async store(){
          //this.modification.properties = JSON.stringify(this.modification.properties)

          await this.$store.dispatch('products/storeModification', {product_id: this.productId, modification: this.modification});
          if (!this.message) {
            await this.$router.push({name: 'product', params: {product_id: this.productId}})
          }
        },
    },
  created() {
    let array = [];
    this.props.forEach(item => {
      let obj = {
        id: item.id,
        name: item.name,
        value: ''
      }
      array.push(obj)
    })
    this.modification.properties = array;
  }
}
</script>

<style scoped>

</style>
