import http from "@/api/http";

export async function items() {
    let response = await http.get('/lines');
    return response.data.lines
}

export async function item(id) {
    let response = await http.get(`/lines/${id}`);
    return response.data.line
}

export async function store(data) {
    let response = await http.post(`/lines/store`, data);
    return response.data.line
}

export async function destroy(id) {
    let response = await http.delete(`/lines/${id}/destroy`);
}

export async function update(id, data) {
    let response = await http.put(`/lines/${id}/update`, data);
    return response.data.line
}


