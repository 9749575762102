<template>
  <button  @click="$emit('click-delete')" type="button" class="delete-btn "></button>
</template>

<script>
export default {
  name: "EditBtnComponent"
}
</script>

<style scoped>
.icon{
}
.delete-btn{
  mask-image: url(../../assets/icon/delete_FILL0_wght400_GRAD0_opsz48.svg);

  mask-size: cover;
  height: 22px;
  width: 22px;
  background-color: #718096;
}
</style>