<template>
  <div class="popup-create-sku" style="display:flex;background-color: #000;width: 100%;height: 100vh;position:fixed;top: 0;left: 0;justify-content: center;align-items: center">
    <div style="background-color: #fff;padding: 20px;">
      <button  @click="$emit('close-create-modification')" type="button" class="btn btn-danger hide-create-sku" >Закрыть</button>

      <form action="" method="post" enctype="multipart/form-data">
        <div class="mt-4">
          <div class="mb-3">
            <input v-model="vendor" class="form-control" type="text"  placeholder="Штрих-Код">
          </div>
          <div class="mb-3">
            <input v-model="price" class="form-control" type="number" placeholder="Цена">
          </div>
        </div>
        <div>
          <h4 style="font-size: 18px;color:grey;">Свойства</h4>
          <div>
            <div v-for="(property, i) in properties" class="mb-3">
              <input v-model="properties[i].value" class="form-control" type="text" :placeholder="property.name">
            </div>
          </div>
        </div>
        <div class="col">
          <button @click="storeModification" type="button" class="btn btn-primary">Создать модификацию</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: ['productId', 'props'],
  data(){
    return{
        vendor: '',
        price: '',
        properties: [],
    }
  },
  computed: {
    message(){
      return this.$store.getters.message
    }
  },
  methods:{
    async storeModification(){
      let form = new FormData();
      form.append('code', this.vendor);
      form.append('price', this.price)
      form.append('properties', JSON.stringify(this.properties))
      let options = {
        product_id: this.productId,
        data: form
      }
      await this.$store.dispatch('product/storeModification', options);
      if (this.message === ''){
        this.$emit('close-create-modification')
      }
    },
  },
  created() {
    let array = [];
    this.props.forEach(item => {
      let obj = {
        id: item.id,
        name: item.name,
        value: ''
      }
      array.push(obj)
    })
    this.properties = array;
  }
}
</script>

<style scoped>

</style>