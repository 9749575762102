<template>
  <loader-component v-if="loaded"></loader-component>

  <div class="row" v-else>

    <div class="row">
      <h1>Модификация</h1>
    </div>
    <modification-component :id="modification.id"
                            :product-id="modification.product_id"
                            :name="modification.name"
                            :price="modification.price"
                            :quantity="modification.quantity"
                            :code="modification.code"
                            :status="modification.status"
                            :description="modification.description"
                            :images="modification.images ?? []"
                            :main-image-id="modification.main_photo_id"
                            :sample-image-id="modification.sample_photo_id"
                            :active="modification.active"
                            :availability="modification.availability"
                            :inventory-control="modification.inventory_control"
                            :h1="modification.h1"
                            :meta-title="modification.meta_title"
                            :meta-description="modification.meta_description"
                            :keywords="modification.keywords"
                            :has-yellow-price="modification.yellow_price_tag"
                            :slug="modification.slug"
    ></modification-component>
    </div>
</template>

<script>
import LoaderComponent from "../../../components/LoaderComponent";
import ModificationComponent from "../../../components/product/modification/ModificationComponent";
import store from "../../../store";
export default {
    components: {ModificationComponent, LoaderComponent},
    computed:{
      modification(){
          return this.$store.getters['products/modification']
      },
      loaded(){
        return this.$store.getters.loaded
      }
    },
    async created(){
      let options = {
        product_id: this.$route.params.product_id,
        modification_id: this.$route.params.modification_id
      }
      //await this.$store.dispatch('products/modification', options)
    },

  async beforeRouteEnter(to, from, next) {
    await store.dispatch('products/modification', {product_id: to.params.product_id, modification_id: to.params.modification_id})
    next()
  },
}
</script>

<style scoped>

</style>
