<template>
  <loader-component v-if="loaded"></loader-component>
  <div v-else>
    <div class="row">
      <h1>Отзыв</h1>

      <div>
        <button v-if="!review.approved" @click="approve" type="button" class="btn btn-success me-3" style="padding: 6px 10px;">Одобрить</button>
        <router-link :to="{name: 'reviewEdit', params: {product_id: review.product_id, id: review.id}}" class="me-3">
          <a href="" class="btn btn-secondary" style="">Редактировать</a>
        </router-link>
        <button @click="destroy" type="button" class="btn btn-danger" style="padding: 6px 10px;">Удалить</button>
      </div>
    </div>
    <review-component :id="review.id"
                      :text="review.text"
                      :assessment="review.assessment"
                      :created="review.created_at"
                      :product="review.product"
                      :user="review.user"
                      :approved="review.approved"
                      :images="review.images"
                      :response="review.response"
                      :answered="review.response_date"
    ></review-component>
    <div v-if="!review.response">
      <div class="mb-3">
        <label for="exampleFormControlTextarea1" class="form-label">Ответ</label>
        <textarea v-model="responseText" class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
      </div>
      <button @click="reply" type="button" class="btn btn-primary">Ответить</button>
    </div>
  </div>
</template>

<script>
import ReviewComponent from "@/components/ReviewComponent";
import LoaderComponent from "@/components/LoaderComponent";
import ViewerComponent from "../../components/ViewerComponent";
import review from "../../store/modules/review";
export default {
    components: {ViewerComponent, LoaderComponent, ReviewComponent},
  data(){
      return {
        responseText: ''
      }
  },
    computed: {
      message(){
        return this.$store.getters.message
      },
      review(){
        return this.$store.getters['review/item']
      },
      loaded(){
        return this.$store.getters.loaded
      },
    },
    methods: {
      async approve(){
        this.$store.dispatch('review/approve', {review_id: this.review.id})
      },
      async destroy(){
        this.$store.dispatch('review/delete', {review_id: this.review.id})
        if (!this.message){
          await this.$router.push({name: 'reviews'})
        }
      },
      async reply(){
        await this.$store.dispatch('review/reply', {review_id: this.review.id, response: this.responseText})
        if (this.message === '') {
          this.responseText = ''
        }
      }
    },
    async created() {
        await this.$store.dispatch('review/item', {review_id: this.$route.params.id})
    }
}
</script>

<style scoped>

</style>
