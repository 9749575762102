<template>
    <tr>
        <td>{{ n + 1 }}</td>
        <td>{{ id }}</td>
        <td class="col-3">
            <router-link :to="{name: 'product', params:{product_id: id}}"><a href="">{{ name }}</a></router-link>
        </td>
        <td>{{ brand.title }}</td>
        <td>{{ line.title }}</td>
        <td>{{ category.name }}</td>
        <td>{{ vendor }}</td>
        <td>{{ price }}</td>

        <td>
          <form v-if="!hasModifications" action="" method="post" style="margin: 0 auto;">
              <button @click="removeFromDiscount(id)" type="button" class="delete-btn"></button>
          </form>
        </td>
    </tr>
    <tr class="" v-if="hasModifications">
        <td class="col-5" colspan="6">
            <discount-modifications-table-component :modifications="modifications"></discount-modifications-table-component>
        </td>
    </tr>
</template>

<script>
import DiscountModificationsTableComponent from "./DiscountModificationsTableComponent";
export default {
    components: {DiscountModificationsTableComponent},
    props: ['id','name','brand','line','category','vendor', 'price', 'hasModifications', 'modifications', 'n'],
    methods: {
      removeFromDiscount(product_id, modification_id = null){
            this.$store.dispatch('discount/removeFromDiscount', {product_id: product_id, modification_id: modification_id})
      },
    }
}
</script>

<style scoped>
.delete-btn{
  height: 22px;
  width: 22px;
  mask-image: url(../../assets/icon/delete_FILL0_wght400_GRAD0_opsz48.svg);
  mask-size: cover;
  background-color: #5c636a;
}
</style>
