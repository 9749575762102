<template>
  <loader-component v-if="loaded"></loader-component>

  <div v-else class="container-fluid">

    <div class="row">
      <h1>Категории</h1>
    </div>
<!--    <div class="col-auto">-->
<!--      <a href="" class="btn btn-primary">Выгрузить</a>-->
<!--    </div>-->
    <div class="col-auto">
      <router-link :to="{name: 'categoryCreate'}">
        <a href="" class="btn btn-primary">Создать категорию</a>
      </router-link>
    </div>

    <div class="row">
      <div class="">
        <table class="table caption-top table-bordered">
          <caption>Категории</caption>
          <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">id</th>
            <th scope="col">Родитель</th>
            <th scope="col">Наименование</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(category, i) in categories">
            <td>{{ i + 1 }}</td>
            <td>{{ category.id }}</td>
            <td>{{ category.parent === null ? '-' : category.parent.name + ' (' + category.parent.id + ')' }}</td>
            <td>
              <router-link :to="{ name: 'category', params: {id: category.id} }">
                <a>{{ category.name }}</a>
              </router-link>
            </td>
<!--            <td>-->
<!--              <router-link :to="{name: 'categoryEdit', params: {id: category.id}}">-->
<!--                <a href="">Редактировать</a>-->
<!--              </router-link>-->
<!--            </td>-->
          </tr>
          </tbody>
        </table>
      </div>
    </div>


  </div>
</template>

<script>
import LoaderComponent from "../../components/LoaderComponent";
export default {
  components: {LoaderComponent},
  computed:{
    loaded(){
      return this.$store.getters.loaded
    },
    categories(){
      return this.$store.getters['categories/items']
    }
  },
  async created() {
    await this.$store.dispatch('categories/items')
  }
}
</script>

<style scoped>

</style>