<template>

  <div class="modal-body">
    <form>
      <div class="col">
        <label for="type" class="form-label">Тип:</label>
        <select @change="discount.value = 0" v-model="discount.type" class="form-select" aria-label="Default select example" id="type">
          <option :value="undefined">Выбрать</option>
          <option v-for="item in typesDiscounts" :value="item.id">{{ item.name }}</option>
        </select>
      </div>

      <div class="mb-3">
        <label for="recipient-name" class="col-form-label">Значение</label>
        <input v-model="discount.value" type="number" class="form-control" id="recipient-name">
      </div>
    </form>

    <div class="row">
      <div class="col">
        <label for="withoutDiscount" class="col-form-label">Цена со скидкой</label>
        <input type="text" class="form-control" id="withoutDiscount" disabled :value="newValue">
      </div>
      <div class="col">
        <label for="discount" class="col-form-label">Скидка</label>
        <input type="text" class="form-control" id="discount" disabled :value="discountValue">
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="discount.type = undefined">Нет</button>
    <button @click="handleApply" type="button" class="btn btn-primary" data-bs-toggle="modal" :data-bs-target="'#' + id">Принять</button>
  </div>
</template>

<script>
export default {
  props: ['value', 'id'],
  emits: ['apply'],
  data(){
    return {
      discount: {
        type: undefined,
        value: undefined,
      },
    }
  },
  computed: {
    typesDiscounts(){
      return this.$store.getters['orders/typeOfDiscounts']
    },
    newValue(){
      let value = 0
      let a = this.discount.value === undefined ? 0 : this.discount.value
      if (this.discount.type === 0){
        value = this.value - this.value * (a / 100)
      }
      if (this.discount.type === 1){
        value = this.value - a
      }
      if (this.discount.type === 2){
        value = this.discount.value
      }
      if (this.discount.type === undefined){
        value = Number(0)
      }
      return value.toFixed()
    },
    discountValue(){
      let value = Number(0)
      let a = this.discount.value === undefined ? 0 : this.discount.value

      if (this.discount.type === 0){
        value = this.value * (a / 100)
      }
      if (this.discount.type === 1){
        value = Number(a)
      }
      if (this.discount.type === 2){
        value = Number(0)
      }
      if (this.discount.type === undefined){
        value = Number(0)
      }
      return value.toFixed()
    }
  },
  methods: {
    handleApply(){
      if (this.discount.type === undefined){
        this.$store.commit('message', 'Поле "Тип" обязательно для заполнения')
        return;
      }
      if (this.discount.value < 1) {
        this.$store.commit('message', 'Поле "Значение" обязательно для заполнения')
        return;
      }
      let obj = {
        newValue: this.newValue
      }
      if (this.discount.type === 2){
        obj.type = 2
      }
      this.$emit('apply', obj)
    }
  }
}
</script>

<style scoped>

</style>