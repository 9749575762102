<template>
<!--  <loader-component v-if="loaded"></loader-component>-->
  <div class="col">
      <h1>Товары</h1>
      <div class="col-auto mt-3">
          <router-link :to="{name: 'productCreate'}" class="btn btn-primary">Создать товар</router-link>
      </div>

    <div class="row mb-3">

      <div class="col-2">
        <label class="form-label">Товар:</label>
        <select @change="filter" v-model="filtration.category" class="form-select" aria-label="Default select example">
          <option selected :value="undefined">Выбрать товар</option>
          <option v-for="(category, index) in categories" :value="category.id">{{ category.name }}</option>
        </select>
      </div>
      <div class="col-2">
        <label class="form-label">Бренд:</label>
        <select @change="filter" v-model="filtration.brand" class="form-select" aria-label="Default select example">
          <option selected :value="undefined">Выбрать бренд</option>
          <option v-for="(brand, index) in brands" :value="brand.id">{{ brand.title }}</option>
        </select>
      </div>

    </div>

      <div class="row">
          <div class="col-8">
              <products-table-component :products="products"></products-table-component>
          </div>
      </div>
  </div>
</template>

<script>
import LoaderComponent from "../../components/LoaderComponent";
import ProductsTableComponent from "../../components/product/ProductsTableComponent";
export default {
    components: {LoaderComponent, ProductsTableComponent},
  data(){
    return {
      filtration: {
        category: undefined,
        brand: undefined
      }
    }
  },
  computed:{
    loaded(){
      return this.$store.getters.loaded
    },
    products(){
        return this.$store.getters['products/items']
    },
    categories(){
      return this.$store.getters['categories/subItems']
    },
    brands(){
      return this.$store.getters['brands/items']
    },
  },
  methods: {
    async filter(e, link){
      let filtration = {}
      for (let key in this.filtration){
        let k = '';
        let value = '';
        value = this.filtration[key];
        k = key
        if (this.filtration[key] === ''){
          k = ''
        }
        if(key === 'status'){
          value = this.filtration.status.toString();
          if (this.filtration[key].length < 1){
            k = '';
            value = ''
            delete filtration.status
          }
        }
        if (k !== '') {
          Object.defineProperty(filtration, k, {enumerable: true, writable: true, value: value})
        }
      }

      if (link){
        filtration.page = link
      }else {
        // if (this.$route.query.hasOwnProperty('from')){
        //   //if (this.filtration.from !== ''){
        //     filtration.from = this.$route.query.from
        //   //}
        // }
        //
        // if (this.$route.query.hasOwnProperty('to')){
        //   if (this.filtration.to !== '') {
        //     //filtration.to = this.$route.query.to
        //   }
        // }
        // if (this.$route.query.hasOwnProperty('status')){
        //   if (this.filtration.status.length < 1){
        //   }
        // }
        // if (this.$route.query.hasOwnProperty('user')){
        //   if (this.filtration.user !== undefined) {
        //     filtration.user = this.$route.query.user
        //   }
        // }


        if (this.$route.query.hasOwnProperty('page')){
          filtration.page = this.$route.query.page
          if (this.$route.query.brand !== this.filtration.brand){
            filtration.page = 1
          }
          if (this.$route.query.to !== this.filtration.to){
            filtration.page = 1
          }
          if (this.$route.query.category !== this.filtration.category){
            filtration.page = 1
          }
          if (this.$route.query.user !== this.filtration.user){
            filtration.page = 1
          }
        }
      }

      await this.$router.push({path: 'products', query: filtration })
      await this.$store.dispatch('products/items', filtration)
    },
  },
  created() {
    this.$store.dispatch('categories/items')
    this.$store.dispatch('brands/items')
    if (this.$route.query.hasOwnProperty('category')){
      this.filtration.category = this.$route.query.category
    }
    if (this.$route.query.hasOwnProperty('brand')){
      this.filtration.brand = this.$route.query.brand
    }
    this.filter()
  },
  // beforeRouteEnter(to, from, next) {
  //     next(vm => {
  //       vm.$store.dispatch('categories/items')
  //       vm.$store.dispatch('brands/items')
  //       if (vm.$route.query.hasOwnProperty('category')){
  //         vm.filtration.category = vm.$route.query.category
  //       }
  //       if (vm.$route.query.hasOwnProperty('brand')){
  //         vm.filtration.brand = vm.$route.query.brand
  //       }
  //       vm.filter()
  //     })
  // },
}
</script>

<style scoped>

</style>
