<template>
  <div>
    <div class="col-4">
      <table class="table caption-top table-bordered">
        <caption>Товар</caption>
        <tbody>
        <tr>
          <th>id</th>
          <td>{{ id }}</td>
        </tr>
        <tr>
          <th>Уровень</th>
          <td>{{ level }}</td>
        </tr>

        <tr>
          <th>Категория</th>
          <td>{{ category }}</td>
        </tr>
        <tr>
          <th>Бренд</th>
          <td>{{ brand }}</td>
        </tr>
        <tr>
          <th>Заголовок</th>
          <td>{{ title }}</td>
        </tr>

        <tr>
          <th>Описание</th>
          <td>{{ description }}</td>
        </tr>

        <tr>
          <th>Ключевые слова</th>
          <td>{{ keywords }}</td>
        </tr>
        <tr>
          <th>H1</th>
          <td>{{ h1 }}</td>
        </tr>
        <tr>
          <th>Обновлено</th>
          <td>{{ updatedAt }}</td>
        </tr>
        <tr>
          <th>С брендом</th>
          <td>{{ withBrand || brand != 'Пусто' ? 'Да' : 'Нет' }}</td>
        </tr>
        <tr>
          <th>Активна</th>
          <td>{{ active ? 'Да' : 'Нет' }}</td>
        </tr>
        </tbody>
      </table>
      <div style="display: flex">
        <button @click="activeToggle" class="btn btn-success" :class="{'btn-dark': active }" style="margin-right: 16px;" type="button">{{ active ? 'Деактивировать' : 'Активировать'}}</button>
        <router-link :to="{name: 'seoEdit', password: {id: id}}">
          <a href="" class="btn btn-secondary" style="">Редактировать</a>
        </router-link>
        <button @click="remove" type="button" class="btn btn-danger " style="margin-left: 16px;">Удалить</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['id', 'withBrand', 'title', 'category', 'brand', 'description', 'keywords', 'h1', 'updatedAt', 'level', 'active'],
  computed: {
  },
  methods: {
    remove(){
      this.$store.dispatch('seo/destroy', {id: this.id})
      this.$router.push({name: 'seoList'})
    },
    async activeToggle(){
      await this.$store.dispatch('seo/toggleActive', {seo_id: this.id});
    }
  }
}
</script>

<style scoped>
.main{
  border: 2px solid green;
}
</style>