<template>
    <tr>
        <td>{{ n }}</td>
        <td>{{ modificationId }}</td>
        <td>
          <router-link :to="{name: 'modification', params: {product_id: productId, modification_id: modificationId}}">{{ name }}</router-link>
        </td>
        <td>{{ vendor }}</td>
      <td>{{ availability ? 'Да' : 'Нет' }}</td>
      <td>{{ quantity }}</td>
        <td>{{ price }}</td>

<!--      <td v-if="this.$route.name === 'showcase'">-->
<!--        <div style="display:flex;justify-content: space-between">-->
<!--          {{ sort }}-->
<!--          <div style="width: 60%;display:flex;justify-content: space-between;">-->
<!--            <button type="button" class="up"></button>-->
<!--            <button type="button" class="down"></button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </td>-->
<!--      <td v-if="pageIsDiscount">-->
<!--          <form >-->
<!--              <button @click="removeFromDiscount(id)" type="button" class="btn btn-secondary">Убрать из скидки</button>-->
<!--          </form>-->
<!--      </td>-->
<!--      <td v-if="$route.name === 'showcase'">-->
<!--        <form >-->
<!--          <button @click="removeFromShowcase(id)" type="button" class="btn btn-secondary delete-btn"></button>-->
<!--        </form>-->
<!--      </td>-->
    </tr>
</template>

<script>
export default {
  props: ['modificationId', 'productId', 'name', 'vendor', 'price', 'sort', 'n', 'availability', 'quantity'],
  computed:{
    pageIsDiscount(){
      return this.$route.name === 'discount'
    }
  },
  methods: {
    removeFromDiscount(modification_id = null){
      this.$store.dispatch('discount/removeFromDiscount', {product_id: this.productId, modification_id: modification_id})
    },
    removeFromShowcase(modification_id = null){
      this.$store.dispatch('showcase/removeFromShowcase', {product_id: this.productId, modification_id: modification_id})
    }
  }
}
</script>

<style scoped>
.delete-btn{
  height: 22px;
  width: 22px;
  mask-image: url(../../../assets/icon/delete_FILL0_wght400_GRAD0_opsz48.svg);
  mask-size: cover;
}

.up{
  height: 22px;
  width: 22px;
  mask-image: url(../../../assets/icon/north_FILL0_wght400_GRAD0_opsz48.svg);
  mask-size: cover;
  background-color: black;
}
.down{
  height: 22px;
  width: 22px;
  mask-image: url(../../../assets/icon/south_FILL0_wght400_GRAD0_opsz48.svg);
  mask-size: cover;
  background-color: black;
}
</style>
