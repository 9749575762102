<template>
  <div class="message" :class="{'admin': whom === 'admin'}">

    <div style="display:flex;justify-content: right;">
      <span style="font-size: 12px;">
        <date-component :date="createdAt"></date-component>
      </span>
    </div>

    <div style="display:flex;">
      <div>
        <div v-if="!modeEdit" class="wrap-text" :class="{'pink': whom === 'admin'}">
          <p class="text">{{ text }}</p>
        </div>
        <form v-else class="" style="width: 100%;">
          <textarea v-model="editText" class="" cols="38" rows="3" style="font-size: 14px;padding: .5rem;border-radius: 5px;border: 1px solid #b0afaf;">{{ editText }}</textarea>
          <div style="display:flex;">
            <button @click="edit" class="icon-btn" type="button">Отправить</button>
            <button @click="offModeEdit" class="icon-btn" type="button">Отменить</button>
          </div>
        </form>
      </div>


      <div v-if="!modeEdit" style="display:flex;flex-direction: column;justify-content: space-around;margin-left: .5rem;">
        <button @click="onModeEdit" type="button" class="message-btn message-btn-edit"></button>
        <button @click="destroy" type="button" class="message-btn message-btn-delete"></button>
      </div>
    </div>


    <div style="display:flex;">
      <image-preview-component v-for="(image, index) in images"
                               :path="baseUrl + image.file"
                               :image="index"
                               @show-viewer="a"
                               :id="image.id"
      ></image-preview-component>
    </div>


    <viewer-component  v-if="showViewer" :path="baseUrl + image.file" @close-viewer="showViewer = false"></viewer-component>
    <div>
    </div>

  </div>
</template>

<script>
import ViewerComponent from "./ViewerComponent";
import ImagePreviewComponent from "./common/ImagePreviewComponent";
import DateComponent from "./common/DateComponent";
export default {
  components: {DateComponent, ImagePreviewComponent, ViewerComponent},
  props: ['id', 'text', 'whom', 'images', 'createdAt'],
  data(){
    return {
      showViewer: false,
      image_id: '',
      type: '',
      modeEdit: false,
      editText: ''
    }
  },
  computed: {
    image(){
      return this.images.find(image => image.id === this.image_id)
    },
    baseUrl(){
      return this.$store.getters.imagesUrl + '/storage/'
    },
  },
  methods: {
    onModeEdit(){
      this.editText = this.text;
      this.modeEdit = true;
    },
    offModeEdit(){
      this.editText = '';
      this.modeEdit = false;
    },
    a(id){
      this.image_id = id
      this.showViewer = true
    },
    async edit(){
      await this.$store.dispatch('appeal/editMessage', {id: this.$route.params.id, message_id: this.id, text: this.editText})
      this.modeEdit = false;
      this.editText = ''
    },
    async destroy(){
      await this.$store.dispatch('appeal/deleteMessage', {id: this.$route.params.id, message_id: this.id})
    },
  },
}
</script>

<style scoped>
.message{
  width: 50%;
  margin-bottom: 16px;
}
.text{
  font-size: 14px;
}
.admin{
  align-self: end;
}
.wrap-text{
  padding: 16px;border-radius: 5px;background-color: rgba(0,0,0,0.1);
}
.pink{
  background-color: rgba(11,94,215,0.1);
}

.message-btn-edit{
  mask-image: url(../assets/icon/edit_FILL0_wght400_GRAD0_opsz48.svg);
}
.message-btn-delete{
  mask-image: url(../assets/icon/delete_FILL0_wght400_GRAD0_opsz48.svg);
}
.message-btn{
  width: 18px;
  height: 18px;
  mask-size: contain;
  display: block;
  background-color: #b0afaf;
  cursor: pointer;
}
.icon-btn{
  color: #fff;
  border: none;
  background-color: #0b5ed7;
  font-size: 14px;
  border-radius: 3px;
  margin-right: 10px;
  padding: 6px 10px;
  cursor: pointer;
}
</style>