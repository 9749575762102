<template>
    <table class="table">
        <thead>
        <tr>
          <th class="col-1">№</th>
          <th class="col-1">id</th>
          <th class="col-4">Дополнительное поле</th>
          <th class="col-2">Артикул</th>
          <th class="col-2">Цена</th>
          <th class="col-1">Удалить</th>
        </tr>
        </thead>
        <tbody>
        <discount-modification-row-table-component v-for="(modification, i) in modifications"
                                                   :id="modification.id"
                                                   :name="modification.name"
                                                   :vendor="modification.code"
                                                   :price="modification.price"
                                                   :product-id="modification.product_id"
                                                   :sort="1"
                                                   :n="i"
        ></discount-modification-row-table-component>
<!--            <modification-row-table-component v-for="(modification, i) in modifications"-->
<!--                                              :id="modification.id"-->
<!--                                              :title="modification.title"-->
<!--                                              :vendor="modification.code"-->
<!--                                              :price="modification.price"-->
<!--                                              :product-id="modification.product_id"-->
<!--                                              :sort="1"-->
<!--                                              :n="i"-->
<!--            ></modification-row-table-component>-->
        </tbody>
    </table>
</template>

<script>
import DiscountModificationRowTableComponent from "./DiscountModificationRowTableComponent";
export default {
    components: {DiscountModificationRowTableComponent},
    props: ['modifications'],
}
</script>

<style scoped>

</style>
