<template>
  <tr>
    <td>{{ n + 1 }}</td>
    <td>{{ id }}</td>
    <td class="col-3">
      <router-link :to="{name: 'product', params:{product_id: id}}">{{ name }}</router-link>
    </td>
    <td>{{ brand.title }}</td>
    <td>{{ line.title }}</td>
    <td>{{ category.name }}</td>
    <td>{{ vendor }}</td>
    <td>{{ availability ? 'Да' : 'Нет' }}</td>
    <td>{{ quantity }}</td>
    <td>{{ price }}</td>


    <!--        <td>-->
    <!--          <div style="display:flex;justify-content: space-between">-->
    <!--          {{ sort }}-->
    <!--          <div style="width: 60%;display:flex;justify-content: space-between;">-->
    <!--            <button @click="up" type="button" class="up"></button>-->
    <!--            <button @click="down" type="button" class="down"></button>-->
    <!--          </div>-->
    <!--          </div>-->
    <!--        </td>-->

    <td v-if="$route.name === 'discount'">
      <form v-if="!hasModifications" action="" method="post" style="margin: 0 auto;">
        <button @click="removeFromDiscount(id)" type="button" class="btn btn-secondary delete-btn"></button>
      </form>
    </td>
    <td v-if="$route.name === 'showcase'">
      <form action="" method="post">
        <button @click="removeFromShowcase(id)" type="button" class="btn btn-secondary delete-btn"></button>
      </form>
    </td>
  </tr>
  <tr class="" v-if="hasModifications">
    <td class="col-5" colspan="6">
      <modifications-table-component :modifications="modifications"></modifications-table-component>
    </td>
  </tr>
</template>

<script>
import ModificationsTableComponent from "./modification/ModificationsTableComponent";
export default {
  components: {ModificationsTableComponent},
  props: ['id','name','brand','line','category','vendor', 'price', 'hasModifications', 'modifications', 'n', 'sort', 'quantity', 'availability'],
  methods: {
    removeFromDiscount(product_id, modification_id = null){
      this.$store.dispatch('discount/removeFromDiscount', {product_id: product_id, modification_id: modification_id})
    },
    removeFromShowcase(product_id, modification_id = null){
      this.$store.dispatch('showcase/removeFromShowcase', {product_id: product_id, modification_id: modification_id})
    },
    up(){
      this.$store.dispatch('showcase/up', {item_id: this.id})
    },
    down(){
      this.$store.dispatch('showcase/down', {item_id: this.id})
    }
  }
}
</script>

<style scoped>
.delete-btn{
  height: 22px;
  width: 22px;
  mask-image: url(../../assets/icon/delete_FILL0_wght400_GRAD0_opsz48.svg);
  mask-size: cover;
}
.up{
  height: 22px;
  width: 22px;
  mask-image: url(../../assets/icon/north_FILL0_wght400_GRAD0_opsz48.svg);
  mask-size: cover;
  background-color: black;
}
.down{
  height: 22px;
  width: 22px;
  mask-image: url(../../assets/icon/south_FILL0_wght400_GRAD0_opsz48.svg);
  mask-size: cover;
  background-color: black;
}
</style>