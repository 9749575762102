import axios from "axios";
import * as reviewsApi from "@/api/reviews"

export default {
    namespaced:  true,
    state: {
        items: [],
        item: undefined
    },

    getters: {
        items(state){
            return state.items
        },
        item(state){
            return state.item
        },
    },

    mutations: {
        items(state, items){
            state.items = items
        },
        item(state, item){
           state.item = item
        },
    },

    actions:{
        async items(store){
            let reviews = await reviewsApi.items();
            store.commit('items', reviews)
            store.commit('loaded', false, {root: true})
        },
        async item(store, payload){
            let review = await reviewsApi.item(payload.review_id);
            store.commit('item', review)
            store.commit('loaded', false, {root: true})
        },
        async store(store, payload){
            let review = await reviewsApi.store(payload);
            store.commit('item', review)
            store.commit('loaded', false, {root: true})
        },
        async update(store, payload){
            let review = await reviewsApi.update(payload.id, payload.review)
            store.commit('item', review)
            store.commit('loaded', false, {root: true})
        },
        async approve(store, payload){
            let review = await reviewsApi.approve(payload.review_id)
            store.commit('item', review)
            store.commit('loaded', false, {root: true})
        },
        async delete(store, payload){
            let review = await reviewsApi.destroy(payload.review_id)
            store.commit('loaded', false, {root: true})
        },
        async reply(store, payload){
            let review = await reviewsApi.reply(payload.review_id, payload)
            store.commit('item', review)
            store.commit('loaded', false, {root: true})
        }
    }
}

