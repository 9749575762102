<template>
  <span>{{ formatted }}</span>
</template>

<script>
export default {
  props: ['date'],
  computed: {
    formatted(){
      if (this.date !== null){
        let date = new Date(this.date)
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let hours = date.getHours();
        let minute = date.getMinutes();
        if (month < 10){
          month = '0' + String(month)
        }
        if (day < 10){
          day = '0' + String(day)
        }
        if (hours < 10){
          hours = '0' + String(hours)
        }
        if (minute < 10){
          minute = '0' + String(minute)
        }
        return day +'.' + month+ '.' +date.getFullYear() +' '+ hours +':'+ minute
      }
      return ''
    },
  },
}
</script>

<style scoped>

</style>