<template>
  <tr>
    <td>{{ n + 1 }}</td>
    <td>{{ id }}</td>
    <td>
      {{ image }}
    </td>
    <td class="col-3">
      <router-link :to="params">{{ name }}</router-link>
    </td>
    <td>{{ category }}</td>
    <td>{{ brand }}</td>
    <td>{{ price }}</td>
    <td>{{ vendor }}</td>
    <td style="height: 66px;display: block;overflow-y: hidden" :title="description">{{ description }}</td>
    <td>{{ hasModifications ? 'Да' : 'Нет' }}</td>
  </tr>
</template>

<script>
export default {
  props: ['id','name','brand','category','vendor', 'price', 'hasModifications', 'n', 'image', 'description', 'product_id', 'modification_id'],
  computed: {
    params(){
      let obj = {
        name: 'product',
        params: {
          product_id: this.product_id
        }
      }
      if (this.modification_id !== null){
        obj.name = 'modification';
        obj.params.modification_id = this.modification_id
      }
      return obj;
    }
  },
  methods: {
  }
}
</script>

<style scoped>

</style>