<template>
<loader-component v-if="loaded"></loader-component>
  <div v-else>
    <h1>Отзыв</h1>
    <form>
      <div class="col mt-3 mb-3">
        <label class="form-label">Товар</label>
        <input :value="name" type="text" class="form-control" :placeholder="''" disabled>
      </div>
      <select-component v-model="review.user_id" :name="'Пользователь'" :options="users" :entity="'user'"></select-component>

      <div class="col-2 mt-3 mb-3">
        <label class="form-label">Текст</label>
        <textarea v-model="review.text" cols="50" rows="2"></textarea>
      </div>
      <div style="display:flex;">
        <div v-for="n in 5" class="form-check me-4">
          <input class="form-check-input" type="radio" name="flexRadioDefault" :id="'flexRadioDefault' + n" :value="n" v-model="review.assessment" style="cursor: pointer">
          <label class="form-check-label" :for="'flexRadioDefault' + n" style="cursor: pointer">{{ n }}</label>
        </div>
      </div>
      <div class="form-check mt-3">
        <input class="form-check-input" type="checkbox" v-model="review.approved" id="flexCheckDefault">
        <label class="form-check-label" for="flexCheckDefault">
          Одобрено
        </label>
      </div>
    <div class="col-2 mt-3">
      <button @click="store" type="button" class="btn btn-primary">Создать отзыв</button>
    </div>
    </form>

  </div>
</template>

<script>
import LoaderComponent from "../../components/LoaderComponent";
import SelectComponent from "../../components/form/SelectComponent";
export default {
  components: {SelectComponent, LoaderComponent},
  data(){
    return {
      review: {
        text: '',
        assessment: 0,
        user_id: undefined,
        product_id: undefined,
        approved: true,
      },
      name: ''
    }
  },
  computed: {
    message(){
      return this.$store.getters.message
    },
    loaded() {
      return this.$store.getters.loaded
    },
    users(){
      return this.$store.getters['users/itemsOthers']
    },
    product(){
      return this.$store.getters['products/item']
    }
  },
  methods: {
    async store(){
      if (this.review.assessment === 0){
        delete this.review.assessment
      }
      await this.$store.dispatch('review/store', this.review)
      if (!this.message){
        await this.$router.push({name: 'reviews'})
      }
    }
  },
  async created() {
    await this.$store.dispatch('users/items')
    await this.$store.dispatch('products/item', {product_id: this.$route.params.product_id})
    this.name = this.product.name
    this.review.product_id = this.product.id
  }
}
</script>

<style scoped>

</style>