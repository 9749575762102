<template>
<div>
  <h1>Обращение</h1>
  <div class="row row-cols-auto">
    <button @click="showConfirmation = true" type="button" class="btn btn-danger ms-3">Удалить</button>
    <button @click="toggleSolved" type="button" class="btn ms-3" :class="{'btn-success': appeal.solved, 'btn-secondary': !appeal.solved}">{{ appeal.solved ? 'Решено' : 'Не решено'}}</button>
  </div>
<!--  <preloader-local-component v-if="loaded"></preloader-local-component>-->
  <appeal-component :id="appeal.id"
                    :theme="appeal.theme"
                    :number="appeal.number"
                    :created-at="appeal.created_at"
                    :user="appeal.user"
                    :solved="appeal.solved"
  ></appeal-component>
  <div style="width: 50%;display:flex;flex-direction: column;justify-content: left">
    <message-component v-for="message in appeal.messages"
                       :id="message.id"
                       :text="message.text"
                       :images="message.images"
                       :whom="message.from_whom"
                       :created-at="message.created_at"
    ></message-component>
  </div>

  <create-message-component v-if="!appeal.solved" :id="$route.params.id"></create-message-component>

<!--  <overlay-component2 v-if="showConfirmation">-->
<!--    <div class="" id="exampleModal">-->
<!--      <div class="modal-dialog modal-dialog-centered">-->
<!--        <div class="modal-content">-->
<!--          <div class="modal-header">-->
<!--            <h5 class="modal-title">Удаление обращения</h5>-->
<!--            <button type="button" class="btn-close" @click="showConfirmation = false" aria-label="Close"></button>-->
<!--          </div>-->
<!--          <div class="modal-body">-->
<!--            <p>Вы уверены что хотите удалить ?</p>-->
<!--          </div>-->
<!--          <div class="modal-footer">-->
<!--            <button @click="showConfirmation = false" type="button" class="btn btn-secondary">Нет</button>-->
<!--            <button @click="destroy" type="button" class="btn btn-primary">Да</button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </overlay-component2>-->



</div>
</template>

<script>
import MessageComponent from "../../components/MessageComponent";
import ViewerComponent from "../../components/ViewerComponent";
import DateComponent from "../../components/common/DateComponent";
import ImagePreviewComponent from "../../components/ImagePreviewComponent";
import PreloaderLocalComponent from "../../components/PreloaderLocalComponent";
import CreateMessageComponent from "../../components/CreateMessageComponent";
import AppealComponent from "../../components/AppealComponent";
import OverlayComponent2 from "../../components/common/OverlayComponent2";
import {toggleSolved} from "../../api/appeals";
export default {
  components: {
    MessageComponent,
    OverlayComponent2,
    AppealComponent,
    CreateMessageComponent,
    PreloaderLocalComponent, DateComponent, ImagePreviewComponent, ViewerComponent},
  data(){
    return {
      showConfirmation: false,
    }
  },

  computed: {
    appeal(){
      return this.$store.getters['appeal/item']
    },
    loaded(){
      return this.$store.getters.loaded
    },
    message(){
      return this.$store.getters.message
    },
  },
  methods: {
    async destroy(){
      this.showConfirmation = false
      await this.$store.dispatch('appeal/destroy', {appeal_id: this.appeal.id})
      if (this.message === ''){
        await this.$router.push({name: 'appeals'})
      }
    },

    async toggleSolved(){
      await this.$store.dispatch('appeal/toggleSolved', {appeal_id: this.appeal.id})
    },
  },
  async created() {
    await this.$store.dispatch('appeal/item', {appeal_id: this.$route.params.id})
  }
}
</script>

<style scoped>

</style>