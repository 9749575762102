import axios from "axios";
import router from "../../router";
import modification from "../../views/product/modification/Modification";
import * as productsApi from '@/api/products'
import {removeModification} from "../../api/products";

export default {
    namespaced: true,
    state: {
        urlImg: '',
        example: '',
        offers: [],
        yellow: [],
        items: [],
        item: null,
        selectedSku: '',
        quantity: 1,
        modification: undefined,
    },
    getters: {
        urlimg(state){
            return state.urlImg
        },
        example(state){
            return state.example
        },
        offers(state){
            return state.offers
        },
        yellow(state){
            return state.yellow
        },
        items(state){
            return state.items
        },
        item(state){
            return state.item
        },
        modification(state){
            return state.modification
        },
        selectedSku(state){
            return state.selectedSku
        },
        quantity(state){
            return state.quantity
        },
        priceWithDiscount(state){
            let sku = state.selectedSku;
            if (sku){
                if (sku.discounts.length > 0){
                    return sku.price - (sku.price * sku.discounts[0].percent / 100)
                }
            }
            return 0;
        },
    },
    mutations: {
        urlimg(state, url){
            state.urlImg = url
        },
        example(state, url){
            state.example = url
        },
        offers(state, offers){
            return state.offers = offers
        },
        yellow(state, products){
            state.yellow = products
        },
        items(state, products){
            state.items = products
        },
        item(state, data){
            state.item = data.product
            state.item.purchasable = data.purchasable
        },
        modification(state, modification){
            state.modification = modification
        },
        storeModification(state, modification){
            state.modification = modification
        },
        updateModification(state, modification){
            state.modification = modification
        },
        removeModification(state, modification){
            let index = state.item.modifications.findIndex(i => {
                return i.id === modification
            })
            state.item.modifications.splice(index,1)
        },
        addImage(state, image) {
            state.item.images.push(image)
        },
        removeImage(state, id) {
            let index = state.item.images.findIndex(i => {
                return i.id === id
            })
            state.item.images.splice(index, 1)
        },
        changeMainImage(state, id){
            state.item.main_photo_id = id
        },
        addModificationImage(state, image) {
            state.modification.images.push(image)
        },
        removeModificationImage(state, payload) {
            let index = state.modification.images.findIndex(item => {
                return item.id === payload.image_id
            })
            state.modification.images.splice(index, 1)
        },
        changeMainImageModification(state, payload){
            state.modification.main_photo_id = payload.image_id
        },
        setSample(state, payload){
            state.modification.sample_photo_id = payload.image_id
        },
        setSelectedSku(state, sku){
            state.selectedSku = sku
        },
        increaseQuantity(state){
            state.quantity++
        },
        decreaseQuantity(state){
            state.quantity--
        },
        changePrice(state, payload){
            if (!payload.hasOwnProperty('product_id')){
                state.item = payload
            }else {
                state.modification = payload
            }
        },
        toggleActive(state, value) {
            state.item.active = value
        },
        toggleActiveModification(state, value) {
            state.modification.active = value
        }

    },

    actions: {
        async items(store, payload){
            let products = await productsApi.items(payload);
            store.commit('items', products)
            store.commit('loaded', false, {root: true})
        },
        async itemsYellow(store){
            let products = await productsApi.itemsYellow();
            store.commit('yellow', products)
            store.commit('loaded', false, {root: true})
        },
        async item(store, payload){
            let data = await productsApi.item(payload.product_id)
            await store.commit('item', data)
            store.commit('loaded', false, {root: true})
        },
        async store(store, payload){
            let product = await productsApi.store(payload)
            store.commit('loaded', false, {root: true})
        },
        async update(store, payload){
            let product = await productsApi.update(payload.product_id, payload)
            store.commit('item', product)
            store.commit('loaded', false, {root: true})
        },
        async remove(store, payload){
            await productsApi.remove(payload.product_id)
            //store.commit('product', response.data.product)
            store.commit('loaded', false, {root: true})
        },
        async storeModification(store, payload){
            let modification = await productsApi.storeModification(payload.product_id, payload.modification)
            store.commit('modification', modification)
            store.commit('loaded', false, {root: true})
        },
        async modification(store, payload){
            let modification = await productsApi.modification(payload.product_id, payload.modification_id)
            store.commit('modification', modification)
            store.commit('loaded', false, {root: true})
        },
        async updateModification(store, payload){
            let modification = await productsApi.updateModification(payload.product_id, payload.modification_id, payload)
            store.commit('modification', modification)
            store.commit('loaded', false, {root: true})
        },
        async removeModification(store, payload){
            await productsApi.removeModification(payload.product_id, payload.modification_id)
            //store.commit('removeModification', payload.modification_id)
            store.commit('loaded', false, {root: true})
        },

        async storeImage(store, payload){
            let product = await productsApi.storeImage(payload.product_id, payload.form)
            store.commit('item', product);
            store.commit('loaded', false, {root: true})
        },
        async destroyImage(store, payload){
            let product = await productsApi.destroyImage(payload.product_id, payload)
            store.commit('item', product);
            store.commit('loaded', false, {root: true})
        },
        async changeMainImage(store, payload){
            store.commit('loaded', true, {root: true})
            await axios.put(`/api/admin/products/${payload.product_id}/images/${payload.image_id}/main/change`).then(response => {
                if (response.data.success){
                    store.commit('changeMainImage', payload.image_id)
                }else {
                    store.commit('message', response.data.message, {root: true})
                }
            }).catch(error => {
                store.commit('message', 'Упс ошибка сервера', {root: true})
            }).finally(() => {
                store.commit('loaded', false, {root: true})
            });

        },
        async storeModificationImage(store, payload){
            store.commit('loaded', true, {root: true})
            let url = `/api/admin/products/${payload.product_id}/modifications/${payload.modification_id}/images/store`;
            await axios.post(url, payload.form).then(response => {
                if (response.data.success){
                    store.commit('addModificationImage',response.data.image)
                }else {
                    store.commit('message', response.data.message, {root: true})
                }
            }).catch(error => {
                store.commit('message', 'Упс ошибка серaaaвера', {root: true})
            }).finally(() => {
                store.commit('loaded', false, {root: true})
            });

        },
        async removeModificationImage(store, payload){
            store.commit('loaded', true, {root: true})
            await axios.delete(`/api/admin/products/${payload.product_id}/modifications/${payload.modification_id}/images/${payload.image_id}/remove`).then(response => {
                if (response.data.success){
                    store.commit('removeModificationImage', { modification_id: payload.modification_id, image_id: payload.image_id})
                }else {
                    store.commit('message', response.data.message, {root: true})
                }
            }).catch(error => {
                store.commit('message', 'Упс ошибка сервера', {root: true})
            }).finally(() => {
                store.commit('loaded', false, {root: true})
            });

        },
        async changeMainImageModification(store, payload){
            store.commit('loaded', true, {root: true})
            await axios.put(`/api/admin/products/${payload.product_id}/modifications/${payload.modification_id}/images/${payload.image_id}/main/change`).then(response => {
                if (response.data.success){
                    store.commit('changeMainImageModification', { modification_id: payload.modification_id, image_id: payload.image_id})
                }else {
                    store.commit('message', response.data.message, {root: true})
                }
            }).catch(error => {
                store.commit('message', 'Упс ошибка сервера', {root: true})
            }).finally(() => {
                store.commit('loaded', false, {root: true})
            });

        },
        async setSample(store, payload){
            store.commit('loaded', true, {root: true})
            let url = `/api/admin/products/${payload.product_id}/modifications/${payload.modification_id}/images/sample`;
            let response = await axios.patch(url, {image_id: payload.image_id}).then(response => {
                if (response.data.success){
                    store.commit('setSample', { modification_id: payload.modification_id, image_id: payload.image_id})
                }else {
                    store.commit('message', response.data.message, {root: true})
                }
            }).catch(error => {
                store.commit('message', 'Упс ошибка сервера', {root: true})
            }).finally(() => {
                store.commit('loaded', false, {root: true})
            });
        },
        async changePrice(store, payload){
            store.commit('loaded', true, {root: true})
            let url = `/api/admin/products/${payload.product_id}/price/change`;
            await axios.put(url,{modification_id: payload.modification_id, price: payload.price}).then(response => {
                if (response.data.success){
                    store.commit('changePrice', response.data.item)
                }else {
                    store.commit('message', response.data.message, {root: true})
                }
            }).catch(error => {
                store.commit('message', 'Упс ошибка сервера', {root: true})
            }).finally(() => {
                store.commit('loaded', false, {root: true})
            });

        },
        async toggleActive(store, payload){
            let url = `/api/admin/products/${payload.product_id}/active/toggle`;
            let response = await axios.put(url,{product_id: payload.product_id});
            store.commit('toggleActive', response.data.product.active)
        },


        async offers(store){
            let offers = await productsApi.offers();
            store.commit('offers', offers)
            store.commit('loaded', false, {root: true})
        },

        async generateFeed(store){
            let offers = await productsApi.generateFeed();
            store.commit('loaded', false, {root: true})
        },

        async convert(store, payload){
            let url = await productsApi.convert(payload);
            store.commit('urlimg', url.url)
            store.commit('loaded', false, {root: true})
        },

        async example(store, payload){
            let url = await productsApi.example(payload);
            store.commit('example', url.url)
            store.commit('loaded', false, {root: true})
        },

        async storePurchasable(store, payload){
            let data = await productsApi.storePurchasable(payload.product_id, payload)
            store.commit('item', data)
            store.commit('loaded', false, {root: true})
        },
        async removePurchasable(store, payload){
            const data = await productsApi.removePurchasable(payload.product_id, payload)
            store.commit('item', data)
            store.commit('loaded', false, {root: true})
        },
        async up(store, payload){
            let data = await productsApi.up(payload.product_id, payload)
            store.commit('item', data)
            store.commit('loaded', false, {root: true})
        },
        async down(store, payload){
            let data = await productsApi.down(payload.product_id, payload)
            store.commit('item', data)
            store.commit('loaded', false, {root: true})
        },
    }
}
