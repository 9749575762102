import http from "@/api/http";

export async function items() {
    let response = await http.get('/brands');
    return response.data.brands
}

export async function item(id) {
    let response = await http.get(`/brands/${id}`);
    return response.data.brand
}

export async function store(data) {
    let response = await http.post(`/brands/store`, data);
    return response.data.brand
}

export async function destroy(id) {
    let response = await http.delete(`/brands/${id}/destroy`);
}

export async function update(id, data) {
    let response = await http.put(`/brands/${id}/update`, data);
    return response.data.brand
}

export async function storeImage(id, data) {
    let response = await http.post(`/brands/${id}/image/store`, data);
    return response.data.brand
}

export async function destroyImage(id, data) {
    let response = await http.delete(`/brands/${id}/image/destroy`, {data: data});
    return response.data.brand

}

