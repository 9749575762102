<template>
    <table class="table">
        <thead>
        <tr>
          <th>№</th>
          <th>id</th>
          <th class="col-4">Наименование</th>
          <th class="col-2">Бренд</th>
          <th class="col-3">Линия</th>
          <th class="col-2">Категория</th>
          <th class="col-2">Артикул</th>
          <th class="col-1">Наличие</th>
          <th class="col-1">Остатки</th>
          <th class="col-2">Цена</th>
<!--          <th class="col-2">Сортировка</th>-->
        </tr>
        </thead>
        <tbody>
        <product-row-table-component v-for="(product, i) in products"
                                      :id="product.id"
                                      :name="product.name"
                                      :brand="product.brand"
                                      :line="product.line"
                                      :category="product.category"
                                      :vendor="product.code ?? 'Пусто'"
                                     :availability="product.availability"
                                     :quantity="product.quantity"
                                      :price="product.price"
                                      :has-modifications="product.has_modifications"
                                      :modifications="product.modifications"
                                      :n="i"
        ></product-row-table-component>
        </tbody>
    </table>
</template>

<script>
import ProductRowTableComponent from "./ProductRowTableComponent";
export default {
    components: {ProductRowTableComponent},
    props: ['products']
}
</script>

<style scoped>

</style>
