<template>
  <loader-component v-if="loaded"></loader-component>

  <OrderComponent v-else :id="order.id"
                  :number="order.number"
                  :created-at="order.created_at"
                  :status="order.status"
                  :customer-email="order.customer_email"
                  :customer-name="order.customer_name"
                  :customer-phone="order.customer_phone"
                  :delivery-address="order.delivery_address"
                  :delivery-cost="order.delivery_cost"
                  :delivery-discount="order.delivery_discount"
                  :delivery-method-name="order.delivery_method_name"
                  :delivery-service-name="order.delivery_service_name"
                  :delivery-tariff="order.delivery_tariff"
                  :items="order.order_items"
                  :pick-up-point="order.pick_up_point"
                  :cost="order.cost"
                  :discount="order.discount"
                  :tracker="order.tracker"
                  :url-payment="order.url_payment"
                  :url-expiration="order.url_expiration"
                  :status-history="order.status_history"
                  :comment="order.comment"
                  :user-id="order.user_id"
                  :assembled="order.assembled"
                  @show-new-mail="showEmail = true"
    ></OrderComponent>

  <new-email-popup-component v-if="showEmail" :email="order.customer_email" @close-new-mail="showEmail = false"></new-email-popup-component>

</template>
<script>
import NewEmailPopupComponent from "../../components/NewEmailPopupComponent";
import OrderComponent from "../../components/OrderComponent";
import LoaderComponent from "@/components/LoaderComponent";
export default {
    components: {LoaderComponent, OrderComponent, NewEmailPopupComponent},
  data(){
      return {
        showEmail: false,
      }
  },
  computed: {
    order(){
      return this.$store.getters['orders/item']
    },
    loaded(){
      return this.$store.getters.loaded
    },
  },
  async created() {
      await this.$store.dispatch('orders/item', this.$route.params.id)
  },
}
</script>
