<template>
  <loader-component v-if="loaded"></loader-component>
  <div v-else>
    <h1>Редактирование модификации</h1>

    <form class="">
      <div class="col-4">
        <label class="form-label">Наименование</label>
        <input v-model="modification.name" type="text" class="form-control" :placeholder="''" disabled>
      </div>

      <div class="col-4">
        <label class="form-label">URL</label>
        <input v-model="modification.slug" type="text" class="form-control" :placeholder="''">
      </div>
      <div class="col-2 mt-3 mb-3">
        <label class="form-label">Описание</label>
        <textarea v-model="modification.description" id="" cols="50" rows="3"></textarea>
      </div>

      <div>
        <h4 style="font-size: 18px;color:grey;">Свойства</h4>
        <div>

          <div v-for="(property, index) in modification.properties" class="col-4">
            <label class="form-label">{{ property.name }}</label>
            <input v-model="modification.properties[index].pivot.value" type="text" class="form-control" :placeholder="''">
          </div>
        </div>
      </div>
      <div class="form-check mt-3 mb-3">
        <label class="form-check-label" for="checkbox-a">Наличие</label>
        <input class="form-check-input" type="checkbox" v-model="modification.availability" id="checkbox-a">
      </div>

      <div class="form-check mt-3">
        <label class="form-check-label" for="checkbox-b">Складской учёт</label>
        <input class="form-check-input" type="checkbox" v-model="modification.inventory_control" id="checkbox-b">
      </div>

      <div class="col-4 mt-3">
        <label class="form-label">H1</label>
        <input v-model="modification.h1" type="text" class="form-control" :placeholder="''">
      </div>
      <div class="col-4 mt-3 mb-3">
        <label class="form-label">Мета-Заголовок</label>
        <input v-model="modification.meta_title" type="text" class="form-control" :placeholder="''">
      </div>
      <div class="col-2">
        <label class="form-label">Мета-Описание</label>
        <textarea v-model="modification.meta_description" cols="50" rows="1"></textarea>
      </div>

      <div class="col-4 mt-3 mb-3">
        <label class="form-label">Ключевые слова</label>
        <input v-model="modification.keywords" type="text" class="form-control" :placeholder="''">
      </div>


      <div class="row">
        <div class="col-2 mb-3">
          <label class="form-label">Артикул</label>
          <input v-model="modification.code" class="form-control" type="text" placeholder="">
        </div>
      </div>
      <div class="col-2 mt-3">
        <button @click="update" type="button" class="btn btn-primary">Редактировать</button>
      </div>
    </form>

  </div>

</template>

<script>
import LoaderComponent from "../../../components/LoaderComponent";
export default {
  components: {LoaderComponent},
  data(){
    return {
      modification: {
        name: '',
        description: '',
        code: '',
        h1: '',
        meta_title: '',
        meta_description: '',
        keywords: '',
        availability: false,
        properties: [],
        inventory_control: false,
        slug: ''
      }
    }
  },
  computed:{
    loaded(){
      return this.$store.getters.loaded
    },
    item(){
      return this.$store.getters['products/modification'];
    },
  },
  methods: {
    async update(){
      let obj = this.modification;
      obj.product_id = this.item.product_id
      obj.modification_id = this.item.id
      await this.$store.dispatch('products/updateModification', obj);
      if (!this.message) {
        await this.$router.push({name: 'product', params: {product_id: this.item.product_id}})
      }
    },
  },
  async created(){
    await this.$store.dispatch('products/modification', {id: this.$route.params.id, modification_id: this.$route.params.modification_id});
    this.modification.name = this.item.name
    this.modification.code = this.item.code
    this.modification.description = this.item.description
    this.modification.availability = this.item.availability
    this.modification.inventory_control = this.item.inventory_control
    this.modification.h1 = this.item.h1
    this.modification.meta_title = this.item.meta_title;
    this.modification.meta_description = this.item.meta_description;
    this.modification.keywords = this.item.keywords
    this.modification.properties = this.item.properties
    this.modification.slug = this.item.slug
  }
}
</script>

<style scoped>

</style>
