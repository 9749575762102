<template>
    <div class="col-4">
      <div>
        <table class="table caption-top table-bordered">
          <tbody>
          <tr>
            <th>id</th>
            <td>{{ id }}</td>
          </tr>
          <tr>
            <th>Наименование</th>
            <td>{{ name }}</td>
          </tr>
          <tr>
            <th>Активна</th>
            <td>{{ active ? 'Да' : 'Нет' }}</td>
          </tr>
          </tbody>
        </table>
        <div style="display:flex;">

          <button @click="activeToggle" class="btn btn-success" :class="{'btn-dark': active }" style="margin-right: 16px;" type="button">{{ active ? 'Деактивировать' : 'Активировать'}}</button>

          <router-link :to="{name: 'showcaseEdit', params: {id: id}}" class="btn btn-secondary">
            <a>Редактировать</a>
          </router-link>

          <button @click="remove" class="btn btn-danger" style="margin-left: 16px;" type="button">Удалить</button>

        </div>
      </div>
        

    </div>
</template>

<script>
export default {
  props: ['id','name','active'],
  methods:{
      async remove(){
        await this.$store.dispatch('showcase/remove', {showcase_id: this.id});
        await this.$router.push({name: 'showcases'})
      },
    async activeToggle(){
      await this.$store.dispatch('showcase/activeToggle', {showcase_id: this.id});
    }
  }
}
</script>

<style scoped>

</style>
