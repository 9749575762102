<template>
  <button  @click="$emit('click-edit')" type="button" class="btn-edit"></button>
</template>

<script>
export default {
  name: "EditBtnComponent"
}
</script>

<style scoped>
.btn-edit{
  height: 30px;
  width: 30px;
  mask-size: cover;
  background-color: #718096;
  mask-image: url(../../assets/icon/edit_FILL0_wght400_GRAD0_opsz48.svg);
}
</style>