<template>
  <div class="card" @click="setIdModification">
    <div>

      <div style="position: relative;display: flex;justify-content: center;align-items: center">

        <a href="">
          <div class="wrap-img">
            <img class="img" :src="getImage" :alt="name" :title="name">
          </div>
        </a>

        <p v-if="hasModifications" style="font-size: .86rem;color: #565656;position:absolute;bottom: -14px;">{{ countModifications + ' ' + declinationOfNumber(countModifications, ['предложение', 'предложения', 'предложений']) }}</p>

      </div>

      <div class="price-wrap">
          <span class="price" :class="{'yellowPrice': yellowPrice }">{{ formattedPrice }}<span class="price-icon">₽</span></span>
      </div>

      <div style="position: absolute;right: 10px;top: 10px;display:flex;flex-direction: column;align-items: center;">
        <div @click.stop="handleClickByFavorite" class="not_favorite" :class="{ 'favorite': inWishlist }" style="margin-bottom: 1.3em;cursor: pointer;z-index: 7"></div>
        <div  v-if="rating > 0">
          <rating-display-component :rating="rating" :vertical="true"></rating-display-component>
        </div>
      </div>

      <div class="title-wrap">
        <a class="card-link" :title="name">
          <h3 class="card-title">{{ name.substring(0, 57) }}</h3>
        </a>
      </div>

    </div>

    <div class="buy-btn-wrap">
      <buy-button-component @click-buy="handleClickOnBtnBuy" :in-cart="inCart" :loaded="loaded" :availability="availability"></buy-button-component>
    </div>

  </div>
</template>

<script>
import RatingDisplayComponent from "./RatingDisplayComponent";
import BuyButtonComponent from "./BuyButtonComponent";
export default {
  components: {BuyButtonComponent, RatingDisplayComponent},
  props: [
    'idProduct',
    'idModification',
    'slug',
    'name',
    'price',
    'hasModifications',
    'yellowPrice', 'countModifications', 'image', 'availability', 'rating', 'category', 'subCategory',
  ],
  computed: {
    imagesUrl(){
      return '/'
    },
    getImage(){
      return this.image ? this.image : require('../../assets/img/not_image.jpg')
    },
    cart(){
      return []
    },
    itemsCart(){
      return true
    },
    inCart(){
      return true
    },
    wishlist(){
      return []
    },
    products(){
      return []
    },
    inWishlist(){
      let str = String(this.idProduct);
      let idModification = null;
      if (this.hasModifications){
        idModification = String(this.idModification)
      }
      str += idModification
      return  this.wishlist.includes(str)
    },
    loadedProduct(){
        let loading = this.$store.getters['products/loading'];
        return loading.product.id === this.idProduct ? loading.product.loaded : false
    },
    loadedAddCart(){
    },
    loaded(){
    },
    formattedPrice(){
      return new Intl.NumberFormat('ru-RU').format(this.price)
    }
  },
  methods: {
    declinationOfNumber(number, words) {
      const index = (number % 100 > 4  &&  number % 100 < 20)  ?  2  :  [2, 0, 1, 1, 1, 2][(number % 10 < 5) ? number % 10  :  5];
      return (words && words[index]) ? words[index] : 'ед.';
    },
    setIdModification(){
    },
    handleClickByFavorite(){

    },
    async handleClickOnBtnBuy(){

    },
  },
}
</script>

<style scoped>
.card{
  width: 14em;
  height: 27em;
  position: relative;
  margin: 0 .3em;
}
.card:hover{
  box-shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.28);
  z-index: 10;
}
.price-icon{
  color:grey;
  margin-left: 4px;
  font-size: 1rem;
}
.price-wrap{
  position: absolute;top: 20px;left: 10px;z-index: 5;
}
.wrap-img{
  width: 14rem;
  height: 18.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.img{
  width: 100%;
}
.not_favorite{
  background-image: url(/assets/img/favorite_border_black_24dp.svg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 2em;
  width: 2em;
}
.favorite{
  background-image: url('/assets/img/favorite_black_24dp.svg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 2em;
  width: 2em;
}
.card-link{
  text-decoration: none;
}
.card-link:hover{
  text-decoration: underline;
  color:#df6aaa;
}
.card-title{
  font-weight: 700;
  color:#df6aaa;
  font-size: .9rem;
}
.title-wrap{
  padding: 16px 10px 10px
}
.buy-btn-wrap{
  padding: 0 10px 15px;position: absolute;bottom: 0;width: 100%;
}

.price{
  font-size: 1.1em;
  font-weight: 400;
  padding: .5em .8em;
  color: black;
  border: 1px solid #df6aaa;
  border-radius: .2em;
  background-color:#fff;
}
.yellowPrice{
  background-color: yellow;
}
@media (max-width: 991px) {
  .card{
    width: 10em;
    height: 23em;
    margin: 0 .3em;
  }
  .wrap-img{
    width: 10em;
    height: 14.2em;
  }
  .img{
    width: 100%;
  }
  .title-wrap{
    padding: 16px 6px 0 6px
  }
  .buy-btn-wrap{
    padding: 0 6px 12px;
  }
}
</style>
