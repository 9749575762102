<template>
  <div :class="{'hidden': message !== ''}">

    <component :is="layout"></component>
    <div  style="position: absolute;top: 22px;right: 22px;display: flex">
<!--      <router-link :to="{name: 'main'}" style="margin-right: 16px;">-->
<!--        <a href="" class="btn btn-primary">Главная</a>-->
<!--      </router-link>-->
<!--      <router-link :to="{name: 'login'}">-->
<!--        <a href="" class="btn btn-primary">Вход</a>-->
<!--      </router-link>-->
    </div>

    <!--    <router-view></router-view>-->
    <div v-if="message !== ''" class="overlay" :style="{top: topOverlay + 'px'}">
      <div class="popup-message">
        <p>{{ message }}</p>
        <button @click="handle" type="button" class="btn btn-dark">Закрыть</button>
      </div>
    </div>

    <div v-if="loaded" class="overlay2" :style="{top: topOverlay + 'px'}">
      <div class="popup-message">
        <p>Пожалуйста подождите...</p>
      </div>
    </div>
  </div>
</template>

<script>

import AuthLayout from "./layouts/AuthLayout";
import MainLayout from "./layouts/MainLayout";
import LoaderComponent from "./components/LoaderComponent";
export default {
  components: {LoaderComponent, AuthLayout, MainLayout},
  data(){
    return {
      topOverlay: 0,
    }
  },
  computed:{
    layout(){
      return this.$route.path === '/login' ? 'auth-layout' : 'main-layout'
    },
    message(){
      return this.$store.getters['message']
    },
    loaded(){
      return this.$store.getters.loaded
    },

  },
  methods:{
    handle(){
      this.$store.commit('message', '')
    },
    setTop(){
      this.topOverlay = window.scrollY
    }
  },
  mounted() {
    window.addEventListener('scroll', this.setTop)
  },
  destroy() {
    window.removeEventListener('scroll', this.setTop)
  }
}
</script>
<style>
.overlay{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.5);
}
.overlay2{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.5);
}
.popup-message{
  padding: 1.8rem;
  background-color: #fff;
  border-radius: 5px;
}
.message{
  font-size: 2rem;
}
.hidden {
  height: 100vh;
  overflow: hidden;
}
</style>
