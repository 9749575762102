<template>
  <loader-component v-if="loaded"></loader-component>
  <div class="container-fluid" v-else>
    <h1>Заказы</h1>
    <div class="col-auto">
        <a :href="url + '/api/admin/orders/export/excel' + query" class="btn btn-primary">Выгрузить</a>
    </div>
    <div class="row row-cols-auto mb-3">
      <div class="col">
        <label for="start" class="form-label">С</label>
        <input @change="filter" v-model="filtration.from" type="date" class="form-control" id="start" placeholder="">
      </div>
      <div class="col">
        <label for="finish" class="form-label">По</label>
        <input @change="filter" v-model="filtration.to"  type="date" class="form-control" id="finish" placeholder="">
      </div>

      <div class="col-5">
        <div class="">
          <label class="form-label">Статус:</label>
        </div>
        <div class="" style="display:flex;flex-wrap: wrap;">
          <div v-for="(item, index) in statuses" class="form-check me-3" style="cursor: pointer;">
            <input v-model="filtration.status" @change="filter" :value="index"  class="form-check-input" type="checkbox" :id="'a' + '-' + index">
            <label class="form-check-label" :for="'a' + '-' + index">{{ item }}</label>
          </div>
        </div>
      </div>

      <div class="col-2">
        <label class="form-label">Пользователь:</label>
        <select @change="filter" v-model="filtration.user" class="form-select" aria-label="Default select example">
          <option selected :value="undefined">Выбрать пользователя</option>
          <option v-for="user in users" :value="user.id">{{ user.surname + ' '+ user.name }}</option>
        </select>
      </div>

      <button @click="reset" type="button" class="btn btn-secondary col ms-3">Сбросить</button>

    </div>

      <div class="row">

            <div class="responsive">
                <table class="table table-striped table-bordered align-middle">
                    <thead class="table-dark">
                      <tr>
                        <th>#</th>
                        <th>id</th>
                        <th class="">Номер заказа</th>
                        <th>Создан</th>
                        <th>Пользователь</th>
                        <th>Статус</th>
                        <th>Трекер</th>
                        <th class="col-1">Тариф доставки</th>
                        <th>Стоимость доставки</th>
                        <th>Адрес ПВЗ</th>
                        <th>Адрес доставки</th>
                        <th>Стоимость товаров</th>
                        <th>Итого</th>
                      </tr>
                    </thead>
                    <tbody>
                      <order-row-table-component v-for="(order, i) in data.pages.data"
                                                 :key="order.id"
                                                 :i="i"
                                                 :id="order.id"
                                                 :status="statuses[order.status]"
                                                 :number="order.number"
                                                 :customer-name="order.customer_name"
                                                 :tracker="order.tracker"
                                                 :delivery-tariff="order.delivery_tariff"
                                                 :delivery-address="order.delivery_address"
                                                 :pick-up-point="order.pick_up_point"
                                                 :delivery-cost="Number(order.delivery_cost - order.delivery_discount)"
                                                 :created-at="order.created_at"
                                                 :cost="Number(order.cost - order.discount)"
                                                 :assembled="order.assembled"
                      ></order-row-table-component>
                    </tbody>
                  <tfoot class="table-dark">
                    <tr>
                      <th colspan="8">Итог фильтрации</th>
                      <td>{{ data.totalByFilters.deliveryCost }}</td>

                      <td></td>
                      <td></td>
                      <td>{{ data.totalByFilters.cost }}</td>
                      <td>{{ Number(data.totalByFilters.cost) + Number(data.totalByFilters.deliveryCost) }}</td>
                    </tr>
                    <tr>
                      <th colspan="8">Итог</th>
                      <td>{{ data.total.deliveryCost }}</td>
                      <td></td>
                      <td></td>
                      <td>{{ data.total.cost }}</td>
                      <td>{{  Number(data.total.cost) + Number(data.total.deliveryCost) }}</td>
                    </tr>
                  </tfoot>
                </table>
            </div>
        </div>
      <div class="row">
        <nav aria-label="Page navigation example">
          <ul class="pagination">
              <li class="page-item" :class="{'disabled': data.pages.current_page < 2}">
                <a @click.prevent="filter($event, data.pages.current_page - 1)" class="page-link" href="#">Назад</a></li>
              <li v-for="link in paginate" class="page-item"  :class="{'active': link.active}">
                <a  @click.prevent="filter($event, link.label)" class="page-link" href="#">{{ link.label }}</a>
              </li>
              <li class="page-item" :class="{'disabled': data.pages.current_page >= data.pages.last_page}">
                <a @click.prevent="filter($event, data.pages.current_page + 1)" class="page-link" href="#">Вперед</a>
              </li>
          </ul>
        </nav>
      </div>

    </div>
</template>
<script>
import LoaderComponent from "../../components/LoaderComponent";
import OrderRowTableComponent from "../../components/order/OrderRowTableComponent";
export default {
  components: {OrderRowTableComponent, LoaderComponent},
  data(){
    return {
      filtration: {
        status: [],
        user: undefined,
        from: '',
        to: '',
        currentPage: undefined
      }
    }
  },
  computed: {
    data(){
      return this.$store.getters['orders/data']
    },
    query(){
      let a = this.$route.query;
      delete a.page;
      let str = '?'
      for (let key in a){
        str += key + '=' + a[key] + '&'
      }
      return str;
    },
    url(){
      return this.$store.getters.baseUrl
    },
    paginate(){
      return this.data.pages.links.filter((item, i) => {
        return i !== 0 && i !== this.data.pages.links.length - 1
      })
    },
    users(){
      let arr = this.$store.getters['users/items'];
      let arr2 = arr.filter(i => i.surname !== null)
          arr2.sort(function (a, b) {
        if (String(a.surname).toLowerCase() < String(b.surname).toLowerCase()) {
          return -1;
        }
        if (String(a.surname).toLowerCase() > String(b.surname).toLowerCase()) {
          return 1;
        }
        // a должно быть равным b
        return 0;
      })
      return arr2
    },
    orders(){
        return this.data.pages.data
    },
    statuses(){
      return this.$store.getters['orders/statuses']
    },
    loaded(){
      return this.$store.getters.loaded
    },
  },
  methods: {
    reset(){
      this.filtration.status = []
      this.filtration.user = undefined
      this.filtration.from = ''
      this.filtration.to = ''
      //this.filtration.currentPage = undefined
      this.filter()
    },
    async filter(e, link){
      let filtration = {}
      for (let key in this.filtration){
        let k = '';
        let value = '';
        value = this.filtration[key];
        k = key
        if (this.filtration[key] === ''){
          k = ''
        }
        if(key === 'status'){
          value = this.filtration.status.toString();
          if (this.filtration[key].length < 1){
            k = '';
            value = ''
            delete filtration.status
          }
        }
        if (k !== '') {
          Object.defineProperty(filtration, k, {enumerable: true, writable: true, value: value})
        }
      }

      if (link){
        filtration.page = link
      }else {
        // if (this.$route.query.hasOwnProperty('from')){
        //   //if (this.filtration.from !== ''){
        //     filtration.from = this.$route.query.from
        //   //}
        // }
        //
        // if (this.$route.query.hasOwnProperty('to')){
        //   if (this.filtration.to !== '') {
        //     //filtration.to = this.$route.query.to
        //   }
        // }
        // if (this.$route.query.hasOwnProperty('status')){
        //   if (this.filtration.status.length < 1){
        //   }
        // }
        // if (this.$route.query.hasOwnProperty('user')){
        //   if (this.filtration.user !== undefined) {
        //     filtration.user = this.$route.query.user
        //   }
        // }


        if (this.$route.query.hasOwnProperty('page')){
          filtration.page = this.$route.query.page
          if (this.$route.query.from !== this.filtration.from){
            filtration.page = 1
          }
          if (this.$route.query.to !== this.filtration.to){
            filtration.page = 1
          }
          if (this.$route.query.status !== this.filtration.status.toString()){
            filtration.page = 1
          }
          if (this.$route.query.user !== this.filtration.user){
            filtration.page = 1
          }
        }
      }

      await this.$router.push({path: 'orders', query: filtration })
      await this.$store.dispatch('orders/items', filtration)
    },

    },
    async created() {
      await this.$store.dispatch('users/items')
      if (this.$route.query.hasOwnProperty('from')){
        this.filtration.from = this.$route.query.from
      }
      if (this.$route.query.hasOwnProperty('to')){
        this.filtration.to = this.$route.query.to
      }
      if (this.$route.query.hasOwnProperty('user')){
        this.filtration.user = this.$route.query.user
      }
      if (this.$route.query.hasOwnProperty('status')){
        this.filtration.status = this.$route.query.status.split(',')
      }
      await this.filter()
    }
}
</script>
