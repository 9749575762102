<template>
  <loader-component v-if="loaded"></loader-component>

  <div class="row" v-else>
    <h1>Рассылка писем</h1>
  </div>

  <button @click="updateRecipients" type="button" class="btn btn-primary  mb-3 mt-3">Обновить получателей</button>
  <div style="width: 444px;">
    <div class="mb-3">
      <label class="form-label">Товары из</label>
      <select v-model="showcase" class="form-select" aria-label="Default select example">
        <option selected :value="''">Выбрать вариант</option>
        <option v-for="item in showcases" :value="item.id">{{ item.name }}</option>
      </select>
    </div>



  <div class="mb-3">
    <label for="exampleFormControlInput1" class="form-label">Тема сообщения</label>
    <input v-model="subject" type="text" class="form-control" id="exampleFormControlInput1" placeholder="">
  </div>

  <div class="mb-3">
    <label for="exampleFormControlInput1" class="form-label">ID списка пользователей</label>
    <input v-model="list_id" type="text" class="form-control" placeholder="">
  </div>

  <div class="mb-3">
    <label for="exampleFormControlInput1" class="form-label">Заголовок</label>
    <input v-model="title" type="text" class="form-control" placeholder="">
  </div>

  <div class="mb-3">
    <label for="exampleFormControlInput1" class="form-label">Описание</label>
    <input v-model="description" type="text" class="form-control" placeholder="">
  </div>

  <button @click="sendOut" type="button" class="btn btn-primary">Разослать</button>
  </div>

  <div v-if="message !== ''" class="overlay">
    <div class="popup-message">
      <p>{{ message }}</p>
      <button @click="close" type="button" class="btn btn-dark">Закрыть</button>
    </div>
  </div>

</template>

<script>
import * as mailingApi from '@/api/mailing'
import LoaderComponent from "../../components/LoaderComponent";
export default {
  components: {LoaderComponent},
  data(){
    return{
      showcase: '',
      subject: '',
      title: '',
      description: '',
      list_id: '',
      message: ''
    }
  },
  computed: {
    loaded() {
      return this.$store.getters.loaded
    },
    showcases(){
      return this.$store.getters['showcase/showcases']
    },
  },
  methods: {
    updateRecipients(){
      mailingApi.updateUsers().then(response => {
        if (response.success){
          this.message = 'Выгрузка контактов завершена'
        }
      }).finally(() => {
        this.$store.commit('loaded', false)
      })
    },

    close(){
      this.showcase = ''
      this.message = ''
      this.subject = ''
      this.text = ''
      this.list_id = ''
      this.title = ''
      this.description = ''
    },
    sendOut(){
      let options = {
        showcase_id: this.showcase,
        subject: this.subject,
        title: this.title,
        description: this.description,
        list_id: this.list_id
      }
      mailingApi.sendOut(options).then(response => {
        if (response.success){
          this.message = 'Рассылка успешно запущена'
        }
      }).finally(() => {
        this.$store.commit('loaded', false)
      })
    }
  },
  async created() {
    await this.$store.dispatch('showcase/items')
  }
}
</script>

<style scoped>

.overlay{
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.5);
}
.popup-message{
  padding: 1.8rem;
  background-color: #fff;
}
</style>