import { createApp } from 'vue'
import App from './App.vue';
import store from './store';

import router from "./router";
import axios from "axios";
import VueAxios from "vue-axios";

axios.defaults.baseURL = store.state.baseUrl;
axios.defaults.withCredentials = true;

store.dispatch('user').then(() => {
    let app = createApp(App);
    app.use(store);
    app.use(router);
    app.use(VueAxios, axios);
    app.mount('#app')
});




