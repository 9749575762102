<template>
  <loader-component v-if="loaded"></loader-component>
  <div v-else class="col-10">

    <div class="row">
      <h1>Бренды</h1>
    </div>
    <div class="col-auto">
      <a href="" class="btn btn-primary">Выгрузить</a>
    </div>
    <div class="col-auto mt-3">
      <router-link :to="{name: 'brandCreate'}">
        <a href="" class="btn btn-primary">Создать бренд</a>
      </router-link>
    </div>

    <div class="row">
      <div class="col-4">
        <table class="table caption-top table-bordered">
          <caption>Бренды</caption>
          <thead>
          <tr>
            <th scope="col">№</th>
            <th scope="col">id</th>
            <th scope="col">Наименование</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="brand in brands">
            <td>{{ 1 }}</td>
            <td>{{ brand.id }}</td>
            <td>
              <router-link :to="{ name: 'brand', params: {id: brand.id} }">
                <a>{{ brand.title }}</a>
              </router-link>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import LoaderComponent from "@/components/LoaderComponent";
export default {
  components: {LoaderComponent},
  computed:{
    loaded(){
      return this.$store.getters.loaded
    },
    brands(){
      return this.$store.getters['brands/items']
    }
  },
  async created() {
    await this.$store.dispatch('brands/items')
  }
}
</script>

<style scoped>

</style>