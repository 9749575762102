<template>
    <tr>
        <td>{{ n + 1 }}</td>
        <td>{{ id }}</td>
        <td class="col-3">
            <router-link :to="{name: 'product', params:{product_id: productId}}"><a href="">{{ name }}</a></router-link>
        </td>
        <td>{{ brand.title }}</td>
        <td>{{ line.title }}</td>
        <td>{{ category.name }}</td>
        <td>{{ vendor }}</td>
        <td>{{ price }}</td>
        <td>
          <div style="display:flex;justify-content: space-between">
          {{ sort }}
          <div style="width: 60%;display:flex;justify-content: space-between;">
            <button @click="up" type="button" class="icon up"></button>
            <button @click="down" type="button" class="icon down"></button>
          </div>
          </div>
        </td>
        <td v-if="$route.name === 'showcase'">
            <button @click="removeFromShowcase" type="button" class="icon delete-btn"></button>
        </td>
    </tr>
    <tr class="" v-if="hasModifications">
        <td class="col-5 ml-4" colspan="6">
            <showcase-modifications-table-component :modification="modification"></showcase-modifications-table-component>
        </td>
    </tr>
</template>

<script>
import ShowcaseModificationsTableComponent from "./ShowcaseModificationsTableComponent";
export default {
    components: {ShowcaseModificationsTableComponent},
    props: ['id','name','brand','line','category','vendor', 'price', 'hasModifications', 'modification', 'n', 'sort', 'productId'],
    methods: {
      removeFromShowcase(){
        let options = {
          product_id: this.productId, modification_id: null
        };
        if (this.modification){
          options.modification_id = this.modification.id
        }
        this.$store.dispatch('showcase/removeFromShowcase', options)
      },
      up(){
        if (this.sort > 1){
          this.$store.dispatch('showcase/up', {item_id: this.id})
        }
      },
      async down(){
        await this.$store.dispatch('showcase/down', {item_id: this.id})
      }
    }
}
</script>

<style scoped>
.icon{
  mask-size: cover;
  height: 22px;
  width: 22px;
  background-color: #718096;
}
.delete-btn{
  mask-image: url(../../assets/icon/delete_FILL0_wght400_GRAD0_opsz48.svg);
}
.up{
  mask-image: url(../../assets/icon/north_FILL0_wght400_GRAD0_opsz48.svg);
}
.down{
  mask-image: url(../../assets/icon/south_FILL0_wght400_GRAD0_opsz48.svg);
}
</style>
