<template>
  <loader-component v-if="loaded"></loader-component>

  <div v-else>
    <div class="row">
        <h1 style="font-size: 28px;">Скидка</h1>
    </div>
    <div class="row">
       <discount-component :id="discount.id"
                           :name="discount.name"
                           :type="discount.type"
                           :sub-type="discount.sub_type"
                           :from-date="discount.from_date"
                           :to-date="discount.to_date"
                           :active="discount.active"
                           :options="discount.options"
       ></discount-component>
    </div>

    <div v-if="discount.sub_type !== 'personal' && discount.sub_type !== 'for_delivery'">

      <div class="mt-5">
        <h2 class="">Добавление из файла</h2>

        <form action="" method="post" enctype="multipart/form-data" class="row">
          <div class="col-3">
            <input class="form-control" type="file" name="file">
          </div>
          <div class="col-auto">
            <button type="submit" class="btn btn-primary">Добавить</button>
          </div>
        </form>
      </div>


      <div class="mt-5">
        <h2 class="">Добавление товара в скидку</h2>

        <form class="" action="" method="post">

          <div class="col-3 mt-3 mb-3" style="display:flex;align-items: center;">
            <label for="isMod" style="margin-right: 6px;">Является модификацией</label>
            <input v-model="is_modification" id="isMod" type="checkbox" name="sku" style="width: 22px;height: 22px;">
          </div>

          <div class="col-3 mt-3 mb-3">
            <input v-model="vendor" type="text" class="form-control" id="inlineFormInputGroupManufacturerName" placeholder="Артикул">
          </div>

          <div class="col-2">
            <button @click="addInDiscount" type="button" class="btn btn-primary">Добавить</button>
          </div>
        </form>
      </div>

      <div class="row mt-5">
        <div class="col-10">
<!--          <products-table-component :products="products"></products-table-component>-->
          <discount-products-table-component :products="products"></discount-products-table-component>
        </div>
      </div>
    </div>
</div>
</template>

<script>
import DiscountComponent from "../../components/discount/DiscountComponent";
import DiscountProductsTableComponent from "../../components/discount/DiscountProductsTableComponent";
import LoaderComponent from "../../components/LoaderComponent";
export default {
    components: {LoaderComponent, DiscountProductsTableComponent, DiscountComponent},
    data(){
        return{
            is_modification: false,
            vendor: ''
        }
    },

    computed: {
      loaded(){
        return this.$store.getters.loaded
      },
        discount(){
            return this.$store.getters['discount/discount'];
        },
        products(){
            return this.$store.getters['discount/products'];
        }
    },
    methods: {
        addInDiscount(){
          let obj = {
            id:this.$route.params.id,
            is_modification: this.is_modification,
            vendor:this.vendor
          };
          this.$store.dispatch('discount/addInDiscount', obj)
          this.vendor = '';
          this.is_modification = false
        },
        removeFromDiscount(){
          let obj = {
            id: this.$route.params.id,
            is_modification: this.is_modification,
            vendor:this.vendor
          };
          this.$store.dispatch('discount/', obj)
        }
    },

    async created() {
        await this.$store.dispatch('discount/discount', {discount_id: this.$route.params.id})
    }
}
</script>

<style scoped>
h2{
    font-size: 20px;
}
</style>
