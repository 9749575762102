<template>
  <tr style="height: 55px;overflow-y: hidden;">
    <td>{{ n }}</td>
    <td>{{ id }}</td>
    <td>{{ levelName }}</td>
    <td>{{ category }}</td>
    <td>{{ brand }}</td>
    <td class="">
      <router-link :to="{name: 'seo', params:{id: id}}"><a href="">{{ title }}</a></router-link>
    </td>
    <td style="height: 55px;overflow-y: hidden;" >{{ description }}</td>
    <td style="height: 55px;overflow-y: hidden;">{{ keywords }}</td>
    <td style="height: 44px;overflow-y: hidden;">{{ h1 }}</td>
    <td>{{ updatedAt }}</td>
    <td>{{ withBrand || brand != 'Пусто' ? 'Да' : 'Нет' }}</td>


    <!--    <td v-if="$route.name === 'discount'">-->
<!--      <form v-if="!hasModifications" action="" method="post" style="margin: 0 auto;">-->
<!--        <button @click="removeFromDiscount(id)" type="button" class="btn btn-secondary delete-btn"></button>-->
<!--      </form>-->
<!--    </td>-->
<!--    <td v-if="$route.name === 'showcase'">-->
<!--      <form v-if="!hasModifications" action="" method="post">-->
<!--        <button @click="removeFromShowcase(id)" type="button" class="btn btn-secondary delete-btn"></button>-->
<!--      </form>-->
<!--    </td>-->
  </tr>
</template>

<script>
export default {
  props: ['n', 'id','title', 'level', 'description', 'keywords', 'h1', 'brand','category', 'updatedAt', 'withBrand'],
  computed: {

    levelName(){
      let item = this.$store.getters['seo/levels'].find(item => item.value === this.level)
      return item.name
    },
  },
  methods: {
    removeFromDiscount(product_id, modification_id = null){
      this.$store.dispatch('discount/removeFromDiscount', {product_id: product_id, modification_id: modification_id})
    },
    removeFromShowcase(product_id, modification_id = null){
      this.$store.dispatch('showcase/removeFromShowcase', {product_id: product_id, modification_id: modification_id})
    }
  }
}
</script>

<style scoped>

</style>