<template>

  <tr>
    <td>{{ n + 1 }}</td>
    <td>{{ id }}</td>
    <td class="">{{ service }}</td>
    <td class="">{{ method }}</td>
    <td>{{ region }}</td>
    <td>{{ city }}</td>
    <td style="display: block;height: 55px;overflow-y: hidden;">
      <div>
        {{ pvz }}
      </div>
    </td>
    <td>{{ address !== null ? address : 'Пусто' }}</td>
    <td>{{ active ? 'Да' : 'Нет' }}</td>
    <td>
      <date-component :date="createdAt"></date-component>
    </td>
    <td>
      <delete-btn-component @click-delete="destroy"></delete-btn-component>
    </td>

  </tr>
</template>

<script>
import DateComponent from "../../common/DateComponent";
import DeleteBtnComponent from "../../buttons/DeleteBtnComponent";
export default {
  components: {DateComponent, DeleteBtnComponent},
  props: ['n', 'id', 'service', 'method', 'region', 'city', 'address', 'createdAt', 'point', 'active', 'userId'],
  computed: {
    pvz(){
      if (this.point !== null){
        return this.point.name +', ' + this.point.address + ', '+ this.point.code
      }
      return ''
    }
  },
  methods: {
    destroy(){
      this.$store.dispatch('users/destroyAddress', {address_id: this.id, user_id: this.userId })
    }
  }
}
</script>

<style scoped>

</style>