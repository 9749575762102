<template>
  <tr >
    <td>{{ i + 1 }}</td>
    <td>{{ id }}</td>
    <td><router-link :to="{ name: 'order', params: { id: id }}">{{ number }}</router-link></td>
    <td>{{ formattedCreatedAt }}</td>
    <td>{{ customerName }}</td>
    <td>{{ status }}</td>
    <td :class="{'back-green': assembled}">{{ tracker }}</td>
    <td>{{ deliveryTariff.tariff_name }}</td>
    <td>{{ deliveryCost }}</td>
    <td :title="pickUpPoint !== null ? pickUpPoint.address : 'Пусто'">{{ pickUpPoint !== null ? pickUpPoint.address.substring(0, 30) : 'Пусто' }}</td>
    <td :title="deliveryAddress">{{ deliveryAddress !== null ? deliveryAddress.substring(0, 30) : 'Пусто'}}</td>
    <td>{{ cost }}</td>
    <td>{{ deliveryCost + cost }}</td>
  </tr>
</template>

<script>
export default {
  props: [
    'id',
    'i',
    'createdAt',
    'number',
    'customerName',
    'status', 'deliveryTariff', 'deliveryCost', 'pickUpPoint', 'deliveryAddress', 'cost', 'tracker', 'assembled'
  ],
  computed: {
    formattedCreatedAt(){
      if (this.createdAt !== null){
        let date = new Date(this.createdAt)
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let hours = date.getHours();
        let minute = date.getMinutes();
        if (month < 10){
          month = '0' + String(month)
        }
        if (day < 10){
          day = '0' + String(day)
        }
        if (hours < 10){
          hours = '0' + String(hours)
        }
        if (minute < 10){
          minute = '0' + String(minute)
        }
        return day +'.' + month+ '.' +date.getFullYear() +' '+hours +':'+minute
      }
      return ''
    },
  },
  methods: {

  }
}
</script>

<style scoped>
.back-green{
  color: white;
  background-color: green;
}
</style>