<template>
  <div class="overlay">
    <slot></slot>
  </div>
</template>

<script>
export default {
  emits: ['apply'],
  props: ['id', 'title'],
  methods: {

  }
}
</script>

<style scoped>

.overlay{
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.5);
}
</style>
