<template>
<div style="display:flex;justify-content: center;">
  <div class="spinner" :style="settings"></div>
</div>
</template>

<script>
export default {
  props: ['size', 'color'],
  computed: {
    a(){
      let value = this.size !== undefined ? this.size : 18;
      return  value + 'px';
    },
    b(){
      return this.color !== undefined ? this.color : '#fff';
    },
    settings(){
      return {
        width: this.a,
        height: this.a,
        borderTopColor: this.b,
        borderRightColor: this.b,
      }
    }
  }
}
</script>

<style scoped>
.spinner{
  width: 18px;
  height: 18px;
  border: 3px solid transparent;
  border-top-color:white;
  border-right-color:white;
  border-radius: 50%;
  animation: spin 0.6s infinite linear;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to{
    transform: rotate(360deg);
  }

}
</style>
