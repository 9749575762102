<template>
    <div class="row">
        <h1>Свойства</h1>
    </div>

    <hr>
    <form class="row">
        <h6 class="">Добавление свойства</h6>
        <div class="col-3">
            <label class="visually-hidden" for="inlineFormInputGroupManufacturerName">Наименование</label>
            <div class="input-group">
                <input v-model="name" type="text" class="form-control" id="inlineFormInputGroupManufacturerName" placeholder="Название">
            </div>
        </div>
        <div class="col-2">
            <button @click="store" type="button" class="btn btn-primary">Добавить</button>
        </div>
    </form>
    <hr>

    <div class="row">
        <div class="col-4">
            <table class="table caption-top table-bordered">
                <caption>Свойство</caption>
                <thead>
                <tr>
                    <th scope="col">№</th>
                    <th scope="col">id</th>
                    <th scope="col">Название</th>
                  <th scope="col">Удалить</th>
                </tr>
                </thead>
                <tbody>
                <property-component v-for="(property, i) in properties" :id="property.id" :name="property.name"  :i="i"></property-component>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import PropertyComponent from "@/components/PropertyComponent";
export default {
  components: {PropertyComponent},
  data(){
        return{
            name: ''
        }
    },
    computed:{
        properties(){
            return this.$store.getters['property/properties']
        }
    },
    methods:{
        store(){
            this.$store.dispatch('property/store',{name:this.name})
        },
    },
    async created(){
        this.$store.dispatch('property/properties')
    }
}
</script>

<style scoped>

</style>
