import axios from "axios";
import * as categoriesApi from '@/api/categories'

export default {
    namespaced:  true,
    state: {
        items: [],
        item: null
    },

    getters: {
        items(state){
            return state.items
        },
        item(state){
            return state.item
        },
        parentItems(state){
            return state.items.filter(i => i.parent_id === 1)
        },
        subItems(state){
            return state.items.filter(item => item.parent_id > 1)
        }
    },

    mutations: {
        items(state, categories){
            state.items = categories
        },
        item(state, category){
            state.item = category;
        },
        destroy(state, payload){
            let index = state.items.findIndex(category => category.id === payload.category_id)
            state.items.splice(index, 1)
        },
        add(state, category){
            state.categories.push(category)
        },
        update(state, category){
            let index = state.categories.findIndex(item => item.id === category.id)
            state.categories[index] = category
        },
    },

    actions:{
        async items(store){
            let categories = await categoriesApi.items();
            store.commit('items', categories)
            store.commit('loaded', false, {root: true})
        },
        async item(store, payload){
            let category = await categoriesApi.item(payload);
            store.commit('item', category)
            store.commit('loaded', false, {root: true})
        },
        async store(store, payload){
            await axios.post('/api/admin/categories/store', payload).then(response => {
                if (response.data.success){
                    store.commit('add', response.data.category)
                }else {
                    store.commit('message', response.data.message, {root: true})
                }
            }).catch(() => {

            }).finally(() => {
                store.commit('loaded', false, {root: true})
            })
        },

        async update(store, payload){
            let category = await categoriesApi.update(payload.category_id, payload);
            store.commit('item', category)
            store.commit('loaded', false, {root: true})
        },
        async destroy(store, payload){
            let url = `/api/admin/categories/${payload.category_id}/destroy`;
            let response = await axios.delete(url);
            store.commit('destroy', payload.category_id)
        }
    }
}

