<template>
    <div style="position: fixed;top: 0;left: 0;width: 100%;height: 100vh;background-color: rgba(0, 0, 0, 0.4);display:flex;justify-content: center;align-items: center">
        <div style="background-color: #fff;padding: 22px;">
            <button type="button" class="btn btn-danger" @click="close">Закрыть</button>

            <form class="mb-3" method="post">
                <div class="mb-3">
                    <label for="formFileMultiple" class="form-label">Загрузка изображений</label>
                    <input class="form-control" type="file" id="formFileMultiple" multiple @change="add($event)">
                    <div v-if="array1.length > 0">
                        <img v-for="i in array1" :src="i" alt="" width="100" height="100" style="margin-right: 6px;">
                    </div>
                </div>
                <div class="mt-3">
                    <button @click="handle" type="button" class="btn btn-primary">Добавить изображения</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            array1: [],
            array2: [],
        }
    },
    methods: {
        add(e){
            for (let item of e.target.files){
                let reader = new FileReader();
                reader.readAsDataURL(item);
                reader.onload = () => {
                    this.array1.push(reader.result);
                }
                this.array2.push(item)
            }
        },
        async handle(){
          this.$emit('upload', this.array2)
        },

        close(){
            this.$emit('close-add-image')
            this.array1 = []
            this.array2 = []
        }
    }
}
</script>

<style scoped>

</style>
