<template>
  <loader-component v-if="loaded"></loader-component>
  <div v-else class="col">
    <h1>Редактировать сео</h1>
    <hr>
    <div>
      <form class="" action="" method="post" enctype="multipart/form-data">

        <div class="col-2 mb-3">
          <label class="form-label">Уровень каталога</label>
          <select class="form-select" v-model="currentLevel">
            <option v-for="level in levels" :value="level.value">{{ level.name }}</option>
          </select>
        </div>
        <div class="">
          <div class="col-2">
            <label class="form-label">Категории</label>
            <select class="form-select" v-model="category_id">
              <option v-bind:value="''" selected>Выбрать категорию</option>
              <option v-for="category in categories" v-bind:value="category.id">{{ category.name }}</option>
            </select>
          </div>
          <div class="col-3">
            <label class="form-label">Бренд</label>
            <select class="form-select" v-model="brand_id">
              <option v-bind:value="''" selected>Выбрать бренд</option>
              <option v-for="brand in brands" :value="brand.id">{{ brand.title }}</option>
            </select>
          </div>

        </div>


        <div class="mt-3 mb-3">
          <div class="col-4">
            <label class="form-label">Заголовок</label>
            <input v-model="title" type="text" class="form-control" :placeholder="''">
          </div>
          <div class="col-2">
            <label class="form-label">Описание</label>
            <textarea v-model="description" cols="50" rows="5"></textarea>
          </div>

          <div class="col-4">
            <label class="form-label">Ключевые слова</label>
            <input v-model="keywords" type="text" class="form-control" :placeholder="''">
          </div>
          <div class="col-4">
            <label class="form-label">H1</label>
            <input v-model="h1" type="text" class="form-control" :placeholder="''">
          </div>

          <div class="form-check mt-3" v-if="brand_id === '' && category_id === ''">
            <input v-model="for_brand" class="form-check-input" type="checkbox" id="flexCheckIndeterminate">
            <label class="form-check-label" for="flexCheckIndeterminate">
              Для бренда
            </label>
          </div>


        </div>
        <div class="col-2 mt-3">
          <button @click="update" type="button" class="btn btn-primary">Редактировать</button>
        </div>
      </form>
    </div>

    <div class="">
    </div>

  </div>
</template>

<script>
import LoaderComponent from "@/components/LoaderComponent";
export default {
  components: {LoaderComponent},
  data(){
    return {
      levels: [
        {
          name: 'Корень',
          value: 1
        },
        {
          name: 'Каталог категорий',
          value: 2
        },
        {
          name: 'Каталог подкатегорий',
          value: 3
        }
      ],
      currentLevel: undefined,
      brand_id: '',
      category_id: '',
      title: '',
      description: '',
      keywords: '',
      h1: '',
      for_brand: false
    }
  },
  computed:{
    loaded(){
      return this.$store.getters.loaded
    },
    categories(){
      return this.$store.getters['category/allCategories'];
    },
    brands(){
      return this.$store.getters['brand/items'];
    },
    message(){
      return this.$store.getters.message
    },
    item(){
      return this.$store.getters['seo/item']
    },
  },
  methods: {
    async update(){
      let options = {
        id: this.item.id,
        brand_id: this.brand_id,
        category_id: this.category_id,
        description: this.description,
        title: this.title,
        h1: this.h1,
        keywords: this.keywords,
        level: this.currentLevel,
      }

      if (this.brand_id === '' && this.category_id === ''){
        options.for_brand = this.for_brand
      }
      await this.$store.dispatch('seo/update', options);
      if (!this.message){
        await this.$router.push({name: 'seoList'})
      }
    },
  },

  async created(){
    await this.$store.dispatch('category/categories');
    await this.$store.dispatch('brand/items');
    await this.$store.dispatch('seo/item', {id: this.$route.params.id});

    this.brand_id = this.item.brand_id === null ? '' : this.item.brand_id
    this.category_id = this.item.category_id === null ? '' : this.item.category_id
    this.title = this.item.title
    this.description = this.item.description
    this.keywords = this.item.keywords
    this.h1 = this.item.h1

    this.currentLevel = this.item.level
    if (this.item.for_brand !== null){
      this.for_brand = this.item.for_brand
    }
  }
}
</script>

<style scoped>

</style>

<!--<template>-->
<!--  <div>-->

<!--    <div class="">-->
<!--      <div class="col-2">-->
<!--        <label class="form-label">Категории</label>-->
<!--        <select class="form-select" v-model="category_id" v-on:change="uni(categories, category)">-->
<!--          <option v-bind:value="''" selected>Выбрать категорию</option>-->
<!--          <option v-for="category in categories" v-bind:value="category.id">{{ category.title }}</option>-->
<!--        </select>-->
<!--      </div>-->
<!--      <div class="col-3">-->
<!--        <label class="form-label">Бренд</label>-->
<!--        <select class="form-select" v-model="brand_id" v-on:change="uni(brands, brand)">-->
<!--          <option v-bind:value="''" selected>Выбрать бренд</option>-->
<!--          <option v-for="brand in brands" v-bind:value="brand.id">{{ brand.title }}</option>-->
<!--        </select>-->
<!--      </div>-->
<!--      <div class="col-3">-->
<!--        <label class="form-label">Линия</label>-->
<!--        <select class="form-select" v-model="line_id" v-on:change="uni(lines, line)">-->
<!--          <option v-bind:value="''" selected>Выбрать линию</option>-->
<!--          <option v-for="lineD in lines" v-bind:value="lineD.id">{{ lineD.title }}</option>-->
<!--        </select>-->
<!--      </div>-->

<!--    </div>-->
<!--    <div class="mt-3 mb-3">-->
<!--      <div class="col-4">-->
<!--        <label class="form-label">Наименование</label>-->
<!--        <input v-model="name" type="text" class="form-control" v-bind:placeholder="'Наименование'">-->
<!--      </div>-->
<!--&lt;!&ndash;      <div class="col-4">&ndash;&gt;-->
<!--&lt;!&ndash;        <label class="form-label">Дополнительное поле</label>&ndash;&gt;-->
<!--&lt;!&ndash;        <input v-model="additionalField" type="text" class="form-control" placeholder="">&ndash;&gt;-->
<!--&lt;!&ndash;      </div>&ndash;&gt;-->
<!--      <div class="col-2">-->
<!--        <label class="form-label">Описание</label>-->
<!--        <textarea v-model="description" id="" cols="50" rows="5"></textarea>-->
<!--      </div>-->


<!--      <div class="form-check mt-3">-->
<!--        <label class="form-check-label" for="checkbox-a">Наличие</label>-->
<!--        <input class="form-check-input" type="checkbox" v-model="availability" id="checkbox-a">-->
<!--      </div>-->


<!--    </div>-->

<!--      <div class="col-4">-->
<!--        <label class="form-label">Мета-Заголовок</label>-->
<!--        <input v-model="meta_title" type="text" class="form-control" :placeholder="'Наименование'">-->
<!--      </div>-->
<!--      <div class="col-2 mt-3 mb-3">-->
<!--        <label class="form-label">Мета-Описание</label>-->
<!--        <textarea v-model="meta_description" cols="50" rows="5"></textarea>-->
<!--      </div>-->
<!--      <div class="col-4">-->
<!--        <label class="form-label">Ключевые слова</label>-->
<!--        <input v-model="keywords" type="text" class="form-control" :placeholder="'Наименование'">-->
<!--      </div>-->



<!--    <div class="mt-4" v-if="!item.has_sku">-->
<!--      <div class="row">-->
<!--        <div class="col-2 mb-3">-->
<!--          <input v-model="vendor" class="form-control" type="text" placeholder="Код">-->
<!--        </div>-->
<!--        <div class="col-2 mb-3">-->
<!--          <input v-model="price" class="form-control" type="number" placeholder="Цена">-->
<!--        </div>-->
<!--&lt;!&ndash;        <div class="col-2 mb-3">&ndash;&gt;-->
<!--&lt;!&ndash;          <input v-model="quantity" class="form-control" type="number" placeholder="Количество">&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--      </div>-->
<!--    </div>-->
<!--    <div v-else >-->
<!--      <h4 style="font-size: 18px;color:grey;">Свойства</h4>-->

<!--      <div>-->
<!--        <div class="form-check" v-for="property in properties">-->
<!--          <input class="form-check-input" type="checkbox" v-model="selectedProperties" :value="property.id" :id="property.id">-->
<!--          <label class="form-check-label" :for="property.id">-->
<!--            {{ property.name }}-->
<!--          </label>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="col-2 mt-3">-->
<!--      <button @click="update" type="button" class="btn btn-primary">Редактировать</button>-->
<!--    </div>-->
<!--  </div>-->

<!--</template>-->

<!--<script>-->
<!--export default {-->
<!--  data(){-->
<!--    return {-->
<!--      name: '',-->
<!--      brand_id: '',-->
<!--      category_id: '',-->
<!--      line_id: '',-->
<!--      description: '',-->
<!--      vendor: '',-->
<!--      quantity: '',-->
<!--      price: '',-->
<!--      selectedProperties: [],-->
<!--      meta_title: '',-->
<!--      meta_description: '',-->
<!--      keywords: '',-->
<!--      availability: false-->
<!--    }-->
<!--  },-->
<!--  computed:{-->
<!--    categories(){-->
<!--      return this.$store.getters['category/categories'];-->
<!--    },-->
<!--    category(){-->
<!--      return this.categories.find(item => item.id === this.category_id).title-->
<!--    },-->
<!--    brand(){-->
<!--      return this.brands.find(item => item.id === this.brand_id).title-->
<!--    },-->
<!--    line(){-->
<!--      return this.lines.find(item => item.id === this.line_id).title-->
<!--    },-->
<!--    brands(){-->
<!--      return this.$store.getters['brand/brands'];-->
<!--    },-->
<!--    lines(){-->
<!--      return this.$store.getters['line/lines'];-->
<!--    },-->
<!--    properties(){-->
<!--      return this.$store.getters['property/properties'];-->
<!--    },-->
<!--    item(){-->
<!--      return this.$store.getters['item/item'];-->
<!--    },-->
<!--    name(){-->
<!--      return this.category + ' ' + this.line + ' ' + this.brand-->
<!--    }-->
<!--  },-->
<!--  methods: {-->
<!--    uni(values, obj){-->
<!--      for (let value of values){-->
<!--        if (Number(value.id) === obj.id){-->
<!--          obj.title = value.title-->
<!--        }-->
<!--      }-->
<!--    },-->
<!--    update(){-->
<!--      let obj = {-->
<!--        item_id: this.item.id,-->
<!--        data: {-->
<!--          title: this.name,-->
<!--          brand_id: this.brand_id,-->
<!--          category_id: this.category_id,-->
<!--          line_id: this.line_id,-->
<!--          description: this.description,-->
<!--          code: this.vendor,-->
<!--          price: this.price,-->
<!--          properties: this.selectedProperties,-->

<!--          meta_title: this.meta_title,-->
<!--          meta_description: this.meta_description,-->
<!--          keywords: this.keywords,-->
<!--          availability: this.availability-->
<!--        }-->
<!--      }-->
<!--      this.$store.dispatch('item/update', obj);-->
<!--      this.$router.push({name: 'items'})-->
<!--    },-->
<!--  },-->
<!--  async created(){-->
<!--    this.$store.dispatch('category/categories');-->
<!--    this.$store.dispatch('brand/brands');-->
<!--    this.$store.dispatch('line/lines');-->
<!--    this.$store.dispatch('property/properties');-->
<!--    await this.$store.dispatch('item/item', {id: this.$route.params.id});-->

<!--        this.name = this.item.title-->
<!--        this.brand_id = this.item.brand_id-->
<!--        this.category_id = this.item.category_id-->
<!--        this.line_id = this.item.line_id-->
<!--        this.description = this.item.description-->
<!--        this.availability = this.item.availability-->
<!--        //this.additionalField: this.item.additionalField-->

<!--        let meta = this.item.meta ?? {title: '', description: '', keywords: ''};-->

<!--        this.meta_title = meta.title;-->
<!--        this.meta_description = meta.description;-->
<!--        this.keywords = meta.keywords-->

<!--        this.vendor = this.item.code-->
<!--        this.price = this.item.price-->
<!--        this.item.properties.forEach(item => {-->
<!--          this.selectedProperties.push(item.id)-->
<!--        })-->

<!--  }-->
<!--}-->
<!--</script>-->

<!--<style scoped>-->

<!--</style>-->
