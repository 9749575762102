<template>
  <div class="col-2">
    <label class="form-label">{{ name }}</label>
    <select class="form-select" :value="modelValue" @change="$emit('update:modelValue', $event.target.value)">
      <option :value="''" selected>Не выбрана</option>
      <option v-for="option in options" :value="option.id">{{ getName(option) }}</option>
    </select>
  </div>
</template>

<script>
export default {
  props: ['name', 'options', 'entity', 'modelValue'],
  data(){
    return {
      id: ''
    }
  },
  computed: {

  },
  methods: {
    getName(option){
      let name = option.name
      if (this.entity === 'user'){
        name += ' ' + option.surname
      }
      return name;
    },
    handle(){
      this.$emit('', {value: this.id})
    }
  }
}
</script>

<style scoped>

</style>