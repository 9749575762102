import axios from "axios";

export default {
    namespaced:  true,
    state: {
        items: [],
        item: undefined,
        levels: [
            {
                name: 'Корень',
                value: 1
            },
            {
                name: 'Каталог категорий',
                value: 2
            },
            {
                name: 'Каталог подкатегорий',
                value: 3
            }
        ],
    },

    getters: {
        item(state){
            return state.item
        },
        items(state){
            return state.items
        },
        levels(state){
            return state.levels
        }
    },

    mutations: {
        items(state, items){
            state.items = items
        },
        item(state, item){
            state.item = item;
        },
        destroy(state, payload){
            let index = state.items.findIndex(item => item.id === payload.item_id)
            state.items.splice(index, 1)
        },
        update(state, item){
            let index = state.items.findIndex(item => item.id === item.id)
            state.items[index] = item
        },
        add(state, item){
            state.items.push(item)
        },
        toggleActive(state) {
            state.item.active = !state.item.active
        },
    },

    actions:{
        async items(store){
            store.commit('loaded', true, {root: true})
            await axios.get('/api/admin/seo_list', {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json'
                },
                withCredentials: true,
            }).then(response => {
                if (response.data.success){
                    store.commit('items', response.data.items)
                }else {
                    store.commit('message', response.data.message, {root: true})
                }
            }).catch(() => {

            }).finally(() => {
                store.commit('loaded', false, {root: true})
            })

        },
        async item(store, payload){
            store.commit('loaded', true, {root: true})
            await axios.get('/api/admin/seo_list/'+ payload.id).then(response => {
                if (response.data.success){
                    store.commit('item', response.data.item)
                }else {
                    store.commit('message', response.data.message, {root: true})
                }
            }).catch(() => {

            }).finally(() => {
                store.commit('loaded', false, {root: true})
            })

        },
        async store(store, payload){
            store.commit('loaded', true, {root: true})
            await axios.post('/api/admin/seo_list/store', payload).then(response => {
                if (response.data.success){
                    store.commit('item', response.data.item)
                }else {
                    store.commit('message', response.data.message, {root: true})
                }
            }).catch(() => {

            }).finally(() => {
                store.commit('loaded', false, {root: true})
            })
        },
        async update(store, payload){
            store.commit('loaded', true, {root: true})
            await axios.put(`/api/admin/seo_list/${payload.id}/update`, payload).then(response => {
                if (response.data.success){
                    store.commit('update', response.data.item)
                }else {
                    store.commit('message', response.data.message, {root: true})
                }
            }).catch(() => {

            }).finally(() => {
                store.commit('loaded', false, {root: true})
            })

        },
        async destroy(store, payload){
            let url = `/api/admin/seo_list/${payload.id}/destroy`;
            let response = await axios.delete(url).then(response => {
                if (response.data.success){
                    //store.commit('destroy', payload.id)
                }else {
                    store.commit('message', response.data.message, {root: true})
                }
            }).catch(() => {

            }).finally(() => {
                store.commit('loaded', false, {root: true})
            });

        },

        async toggleActive(store, payload){
            store.commit('loaded', true, {root: true})
            let url = `/api/admin/seo_list/${payload.seo_id}/active/toggle`;
            await axios.put(url).then(response => {
                if (response.data.success){
                    store.commit('toggleActive')

                }else {
                    store.commit('message', response.data.message, {root: true})
                }
            }).catch(() => {

            }).finally(() => {
                store.commit('loaded', false, {root: true})
            });
        },
    }
}

