<template>
  <div class="viewer" style="background-color: #fff;">
    <button @click="$emit('close-viewer')" class="close-viewer" type="button"></button>
    <div style="display:flex;flex-direction: column;align-items: center;justify-content:center;height: 100%;width: 100%;">
      <div class="selected" style="">
        <img :src="path" alt="" style="object-fit: contain;width: 100%;height: 100%;">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['path'],
  data(){
    return {
      d: 0
    }
  },
  computed: {
  }
}
</script>

<style scoped>
.viewer{
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: center;
}
.close-viewer{
  width: 3rem;
  height: 3rem;
  mask-image: url("../assets/icon/close.svg");
  mask-repeat: no-repeat;
  mask-size: cover;
  background-color: grey;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}
.selected{
  height: 90%;width: 90%;background-color:#fff; box-shadow: 0 0 10px rgba(0,0,0,0.5)
}
@media (max-width: 992px) {
.selected{
  width: 95%;
  height: 80%;
}
}
</style>
