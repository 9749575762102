<template>
    <table class="table">
        <thead>
        <tr>
          <th>№</th>
          <th>id</th>
          <th class="col-4">Наименование</th>
          <th class="col-2">Бренд</th>
          <th class="col-3">Линия</th>
          <th class="col-2">Категория</th>
          <th class="col-2">Артикул</th>
          <th class="col-2">Цена</th>
          <th class="col-2">Сортировка</th>
          <th class="col-2">Удалить</th>
        </tr>
        </thead>
        <tbody>
        <showcase-product-row-table-component v-for="(item , i) in items"
                                              :id="item.id"
                                              :product-id="item.product.id"
                                              :name="item.product.name"
                                              :brand="item.product.brand"
                                              :line="item.product.line"
                                              :category="item.product.category"
                                              :vendor="item.product.code ?? 'Пусто'"
                                              :price="item.product.price"
                                              :has-modifications="item.product.has_modifications"
                                              :modification="item.modification"
                                              :sort="item.sort"
                                              :n="i"
        ></showcase-product-row-table-component>
        </tbody>
    </table>
</template>

<script>
import ShowcaseProductRowTableComponent from "./ShowcaseProductRowTableComponent";
export default {
    components: {ShowcaseProductRowTableComponent},
    props: ['items']
}
</script>

<style scoped>

</style>
